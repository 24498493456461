import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteDocument, getDocument, getDocuments } from '../../api/api';
import PatientsAdd from './PatientsAdd';
import LabsAdd from '../Labs/LabsAdd';
import { getAge } from '../../Helpers/UserHelpers';

function PatientsDetail({ currentUser, setCurrentUser }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const toast = useToast();
  const [needsParent, setNeedsParent] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOrderOpen, setIsOrderOpen] = useState(false);
  const [patient, setPatient] = useState(null);
  const [labs, setLabs] = useState(null);
  const [filteredLabs, setFilteredLabs] = useState(null);
  const [isResending, setIsResending] = useState(false);
  const [selectedLabId, setSelectedLabId] = useState(null);
  const [selectedLab, setSelectedLab] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const handleResend = lab => {
    setIsResending(true);
    setSelectedLabId(lab?.id);
    setSelectedLab(lab);
    setTimeout(() => {
      //submit google form to trigger email
      document.querySelector('#submitconsentformtwo').click();
    }, 1000);

    setTimeout(() => {
      setSelectedLabId(null);
      setSelectedLab(null);
      setIsResending(false);
      toast({
        title: `Consent Email sent to ${lab?.patient?.email}`,
        description: `Disclosure forms were included in the email.`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    }, 3000);
  };

  const copyConsentFormLink = () => {
    let cLink;
    const patientAge = getAge(new Date(patient?.dob));
    if (patientAge > 0)
      cLink =
        'https://eform.pandadoc.com/?eform=f44ee451-0e82-4227-bc0c-dd1ba3460e88';
    if (patientAge > 6)
      cLink =
        'https://eform.pandadoc.com/?eform=12340aeb-df9e-4019-939f-340ba3cd2456';
    if (patientAge > 11)
      cLink =
        'https://eform.pandadoc.com/?eform=3a0e08de-7522-4ef4-9bad-6e248376fd97';
    if (patientAge > 17)
      cLink =
        'https://eform.pandadoc.com/?eform=da357015-4461-493b-98c2-d38ed144bbb9';
    navigator.clipboard.writeText(cLink);
  };

  const copyGuardianConsentFormLink = () => {
    let cLink =
      'https://eform.pandadoc.com/?eform=da357015-4461-493b-98c2-d38ed144bbb9';
    navigator.clipboard.writeText(cLink);
  };

  const handleDelete = () => {
    deleteDocument(currentUser?.id, 'patients', id, '/patients', navigate);
  };

  useEffect(() => {
    getDocument(currentUser?.id, 'patients', id, setPatient);
    getDocuments(currentUser?.id, 'labs', setLabs);
  }, [id]);

  useEffect(() => {
    if (
      getAge(new Date(patient?.dob)) > 11 &&
      getAge(new Date(patient?.dob)) < 18
    ) {
      setNeedsParent(true);
    } else {
      setNeedsParent(false);
    }
  }, [patient]);

  useEffect(() => {
    if (labs) {
      const fLabs = labs.filter(l => l.patientId === id);
      setFilteredLabs(fLabs);
    }
  }, [labs]);

  return (
    <div id="patients" className="page-padding patients-detail">
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Patient
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <PatientsAdd
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        patient={patient}
      />
      <LabsAdd
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        isOpen={isOrderOpen}
        setIsOpen={setIsOrderOpen}
        patient={patient}
      />
      <Heading
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        fontSize={{
          base: '4xl',
          md: '5xl',
        }}
      >
        {patient?.firstName} {patient?.lastName}
        <ButtonGroup>
          <Button colorScheme="gray" onClick={() => onOpen()}>
            Delete
          </Button>
          <Button colorScheme="blue" onClick={() => setIsModalOpen(true)}>
            Edit
          </Button>
          <Button colorScheme="blue" onClick={() => setIsOrderOpen(true)}>
            Order Lab
          </Button>
        </ButtonGroup>
      </Heading>
      <TableContainer>
        <Table variant="simple">
          {patient && (
            <Tbody>
              {/* <Tr>
                <Td>Id</Td>
                <Td className="id">{patient.id}</Td>
              </Tr> */}

              <Tr>
                <Td>Date of Birth</Td>
                <Td className="dob">
                  {new Date(patient?.dob).toLocaleDateString()}
                </Td>
              </Tr>
              <Tr>
                <Td>Age</Td>
                <Td className="dob">{getAge(patient?.dob)}</Td>
              </Tr>
              <Tr>
                <Td>Contact Info</Td>
                <Td className="email">
                  <a
                    style={{ marginLeft: -15 }}
                    href={`mailto:${patient?.email}`}
                  >
                    <Button colorScheme="blue" variant="ghost">
                      {patient.email}
                    </Button>
                  </a>
                  <a href={`mailto:${patient?.mobileWithCarrier}`}>
                    <Button colorScheme="blue" variant="ghost">
                      {patient?.mobile}{' '}
                      {patient.mobileCarrier
                        ? `with ${patient.mobileCarrier}`
                        : ''}
                    </Button>
                  </a>
                </Td>
              </Tr>
              {needsParent && (
                <Tr>
                  <Td>Parent Email</Td>
                  <Td className="email">{patient?.parentsEmail}</Td>
                </Tr>
              )}

              <Tr>
                <Td>Address</Td>
                <Td className="address">{patient?.address}</Td>
              </Tr>
              <Tr>
                <Td>Labs</Td>
                <Td>
                  <TableContainer>
                    <Table size="sm">
                      <Thead>
                        <Tr>
                          <Th>Id</Th>
                          <Th>Date</Th>
                          <Th>Status</Th>
                          <Th>Actions</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {filteredLabs?.map(l => (
                          <Tr key={l.id}>
                            <Td>
                              <Button
                                size="sm"
                                colorScheme="blue"
                                variant="ghost"
                                onClick={() => navigate(`/lab/${l.id}`)}
                                style={{ marginLeft: -12 }}
                              >
                                {l.id}
                              </Button>
                            </Td>
                            <Td>{new Date(l.date).toLocaleDateString()}</Td>
                            <Td>{l.status}</Td>
                            <Td>
                              <Flex gap={2}>
                                <Button
                                  key={l.id}
                                  colorScheme="gray"
                                  size="sm"
                                  isLoading={
                                    isResending && selectedLabId === l.id
                                  }
                                  loadingText="Resending Patient Consent Form"
                                  onClick={() => handleResend(l)}
                                >
                                  Resend Patient Consent Form
                                </Button>
                                <Button
                                  key={`${l.id}-consent`}
                                  colorScheme="gray"
                                  size="sm"
                                  onClick={() => copyConsentFormLink()}
                                >
                                  Copy Consent Link
                                </Button>
                                <Button
                                  key={`${l.id}-consent`}
                                  colorScheme="gray"
                                  size="sm"
                                  onClick={() => copyGuardianConsentFormLink()}
                                >
                                  Copy Guardian Consent Link
                                </Button>
                              </Flex>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Td>
              </Tr>
              <Tr>
                <Td>Notes</Td>
                <Td
                  className="note"
                  dangerouslySetInnerHTML={{ __html: patient?.note }}
                ></Td>
              </Tr>
            </Tbody>
          )}
        </Table>
      </TableContainer>
      <br />

      <iframe
        title="secret-frame-two"
        name="secret-frame-two"
        width="0"
        height="0"
        border="0"
        style={{ display: 'none' }}
      ></iframe>
      <form
        className="form"
        action="https://docs.google.com/forms/d/e/1FAIpQLSd0siGl6pf4z1J7kEdxk6mzAqZo0YTd_pULjJMNBa9gk38B6Q/formResponse"
        id="ss-form2"
        method="POST"
        target="secret-frame-two"
        style={{ display: 'none' }}
      >
        <div className="form-group">
          <label htmlFor="dateValue">Date</label>
          <input
            name="entry.1726393305"
            readOnly
            type="text"
            value={selectedLab?.date}
          />
        </div>
        <div className="form-group">
          <label htmlFor="labId">LabId</label>
          <input
            name="entry.1252597670"
            readOnly
            type="text"
            value={selectedLab?.id}
          />
        </div>
        <div className="form-group">
          <label htmlFor="patientId">PatientId</label>
          <input
            name="entry.2107253374"
            readOnly
            type="text"
            value={patient?.id}
          />
        </div>
        <div className="form-group">
          <label htmlFor="patientEmail">PatientEmail</label>
          <input
            name="entry.235882463"
            readOnly
            type="email"
            value={patient?.email}
          />
        </div>
        <div className="form-group">
          <label htmlFor="patientFirstName">PatientFirstName</label>
          <input
            name="entry.1702512101"
            readOnly
            type="text"
            value={patient?.firstName}
          />
        </div>
        <div className="form-group">
          <label htmlFor="doctorName">DoctorName</label>
          <input
            name="entry.850389264"
            readOnly
            type="text"
            value={currentUser?.displayName}
          />
        </div>
        <div className="form-group">
          <label htmlFor="type">Type</label>
          <input name="entry.351738770" readOnly type="text" value={''} />
        </div>
        <div className="form-group">
          <label htmlFor="type">Age</label>
          <input
            name="entry.874767956"
            readOnly
            type="text"
            value={getAge(new Date(patient?.dob))}
          />
        </div>
        {needsParent && (
          <div className="form-group">
            <label htmlFor="parent">Parent Email</label>
            <input
              name="entry.1220017171"
              readOnly
              type="text"
              value={patient?.parentsEmail}
            />
          </div>
        )}

        <input
          id="submitconsentformtwo"
          type="submit"
          value="Submit Consent Form Two"
        />
      </form>
    </div>
  );
}

export default PatientsDetail;
