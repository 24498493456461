import { Box, Container, Flex, Heading } from '@chakra-ui/react';
import React from 'react';

function LandingHazan() {
  return (
    <div id="landing-hazan">
      <Box bg={'blue.900'} position={'relative'}>
        <Container
          style={{ padding: '8em 1em 10em' }}
          className="container-hazan"
          maxW={'4xl'}
          zIndex={10}
          position={'relative'}
          textAlign={'center'}
        >
          <Heading
            lineHeight={1.1}
            fontWeight={800}
            color="white"
            fontSize={{ base: '2xl', sm: '3xl', md: '5xl' }}
          >
            Learn more about{' '}
            <Heading
              fontSize="1em"
              mt=".25em"
              color="white"
              fontFamily="Contrail One"
            >
              BIOME SQUAD
              <span className="trademark">™</span>
            </Heading>{' '}
            from its founder Dr. Sabine Hazan
          </Heading>
          <p
            style={{ color: 'rgba(255,255,255,.75)', margin: '1em auto .5em' }}
          >
            Dr. Hazan is a leading gastroenterologist and the CEO of{' '}
            <a
              href="https://progenabiome.com/"
              target="_blank"
              rel="noreferrer"
              style={{ fontWeight: 'bold', textDecoration: 'underline' }}
            >
              Progenabiome
            </a>
            . She has over 30 years of experience in understanding the
            microbiome and its health implications - and is at the forefront of
            medical research in this area.
          </p>
          <iframe
            style={{ marginTop: '2em' }}
            width="100%"
            height="400px"
            src="https://www.youtube.com/embed/2rNSQXoVm5k?si=lsApw20CTwIZ7r-n"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </Container>
      </Box>
    </div>
  );
}

export default LandingHazan;
