import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
} from '@chakra-ui/react';
import DiscussionsAdd from './DiscussionsAdd';
import { getRootDocuments } from '../../api/api';
import { useNavigate } from 'react-router-dom';
import './Discussions.scss';
import Search from '../../Components/Search/Search';

import {
  FaCommentAlt,
  FaFlag,
  FaReply,
  FaShare,
  FaShareAlt,
} from 'react-icons/fa';

const labHeaders = ['title', 'blurb', 'author', 'replies'];

function Discussions({ currentUser, setCurrentUser, dashboard }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [discussions, setDiscussions] = useState([]);

  useEffect(() => {
    getRootDocuments(currentUser, 'discussions', setDiscussions);
  }, [currentUser]);

  return (
    <div id="discussions" className="page-padding">
      <DiscussionsAdd
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <Heading
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        fontSize={{
          base: '4xl',
          md: '5xl',
        }}
      >
        <Search
          size={{ base: 'md', md: 'lg' }}
          colorScheme="blue"
          placeholder="Search Biome Squad Chats..."
        />
        <Button colorScheme="blue" onClick={() => setIsOpen(true)}>
          New Topic
        </Button>
      </Heading>
      <TableContainer>
        <Table variant="simple">
          <Tbody>
            {discussions?.map(d => (
              <Tr>
                <Td>
                  <Heading
                    onClick={() => navigate(`/biome-chat/${d.id}`)}
                    size="md"
                  >
                    {d.title}
                  </Heading>
                  <div
                    className="author"
                    onClick={() => navigate(`/profile/${d.authorId}`)}
                  >
                    <Avatar
                      backgroundColor={'gray.400'}
                      size="2xl"
                      className="avatar"
                      src={d?.authorPhotoURL}
                    ></Avatar>
                    {d?.author} on {d?.date}
                  </div>
                  <div
                    className="preview"
                    onClick={() => navigate(`/biome-chat/${d.id}`)}
                    dangerouslySetInnerHTML={{ __html: d?.content }}
                  />
                  {/* <div className="actions">
                    <ButtonGroup mt={4}>
                      <Button size="sm" gap={2} title="Comments">
                        <FaCommentAlt color="gray.300" /> {d?.count}
                      </Button>
                      <Button size="sm" title="Report">
                        <FaFlag color="gray.300" />
                      </Button>
                    </ButtonGroup>
                  </div> */}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Discussions;
