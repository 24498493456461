import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
  Button,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import RegisterBC from './RegisterBC';
import './BecomeCertified.scss';
import { useEffect } from 'react';

const Feature = ({ text, icon, iconBg }) => {
  return (
    <Stack direction={'row'} align={'center'}>
      <Flex
        w={4}
        h={4}
        align={'center'}
        justify={'flex-start'}
        rounded={'full'}
        bg={iconBg}
        minW={4}
        maxW={4}
      >
        {/* {icon} */}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

export default function BecomeCertified({
  currentUser,
  setCurrentUser,
  isCertified,
}) {
  const navigate = useNavigate();

  return (
    <div id="become-certified">
      <Container maxW={'6xl'} py={12}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Stack spacing={4}>
            <Text
              textTransform={'uppercase'}
              color={'blue.400'}
              fontWeight={600}
              fontSize={'sm'}
              bg={useColorModeValue('blue.50', 'blue.900')}
              p={2}
              alignSelf={'flex-start'}
              rounded={'md'}
            >
              {currentUser && isCertified
                ? 'Go with your gut'
                : 'Become Certified'}
            </Text>
            <Heading fontFamily="Contrail One">
              BIOME SQUAD<span className="trademark">™</span> Certified Program
              <span className="trademark">™</span>
            </Heading>
            <Text color={'gray.500'} fontSize={'lg'}>
              The Biome Squad<span className="trademark">™</span> Certified
              Program is a group of physicians and scientists who have come
              together to look at the trillions of bacteria that encompass our
              bodies with a vision to understand the truth about the microbiome.
            </Text>
            <Text fontWeight={800}>
              Consider becoming a Certified Member if:
            </Text>
            <Stack
              spacing={4}
              divider={
                <StackDivider
                  borderColor={useColorModeValue('gray.100', 'gray.700')}
                />
              }
            >
              <Feature
                //icon={<Icon as={FcAutomotive} color={'yellow.500'} w={5} h={5} />}
                iconBg={useColorModeValue('orange.100', 'orange.900')}
                text={
                  'You are a doctor, practictioner of medicine, or scientist'
                }
              />
              <Feature
                //icon={<Icon as={FcAutomotive} color={'yellow.500'} w={5} h={5} />}
                iconBg={useColorModeValue('yellow.100', 'yellow.900')}
                text={'You want to understand the microbiome'}
              />
              <Feature
                //icon={<Icon as={FcAutomotive} color={'green.500'} w={5} h={5} />}
                iconBg={useColorModeValue('green.100', 'green.900')}
                text={
                  'You may be interested in fecal microbiota transplant (FMT)'
                }
              />
              <Feature
                //icon={<Icon as={FcAutomotive} color={'purple.500'} w={5} h={5} />}
                iconBg={useColorModeValue('purple.100', 'purple.900')}
                text={
                  'You would like to offer genetic sequencing services to your patients'
                }
              />
              <Feature
                //icon={<Icon as={FcAutomotive} color={'green.500'} w={5} h={5} />}
                iconBg={useColorModeValue('pink.100', 'pink.900')}
                text={
                  'You want to collaborate on studies investigating the gut flora in disease'
                }
              />
              <Feature
                //icon={<Icon as={FcAutomotive} color={'purple.500'} w={5} h={5} />}
                iconBg={useColorModeValue('blue.100', 'blue.900')}
                text={
                  'You want to be among the first to learn about completed studies, findings, new microbiome standards, research, diagnostics, therapies, treatments, technologies, and more'
                }
              />
            </Stack>
            {!currentUser && (
              <Button
                marginTop="5"
                size={'lg'}
                fontWeight={800}
                px={6}
                colorScheme={'blue'}
                onClick={() => navigate('/sign-up')}
              >
                Sign Up then Apply for Certification
              </Button>
            )}
          </Stack>
          <Flex>
            {/* <Image
            rounded={'md'}
            alt={'feature image'}
            src={
              'https://images.unsplash.com/photo-1554200876-56c2f25224fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
            }
            objectFit={'cover'}
          /> */}

            {currentUser &&
            (currentUser?.membership === 'Premium' ||
              currentUser?.membership === 'Certified' ||
              currentUser?.membership === 'Applied') ? (
              <Stat
                style={{
                  backgroundColor: '#4299e1',
                  height: 'fit-content',
                  borderRadius: '8px',
                  color: 'white',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                p={12}
              >
                <StatLabel
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {isCertified && (
                    <>
                      <Image
                        borderRadius="full"
                        boxSize="150px"
                        src={currentUser?.photoURL}
                        alt={currentUser?.displayName}
                        mb={4}
                      />

                      <h2 style={{ fontSize: '2em' }}>
                        {currentUser?.displayName || currentUser?.email}
                      </h2>
                    </>
                  )}
                  {currentUser?.membership === 'Applied' && (
                    <h2 style={{ fontSize: '2em' }}>Application submitted!</h2>
                  )}
                </StatLabel>
                <StatNumber m={3} style={{ fontSize: '1em' }}>
                  {currentUser?.membership === 'Certified' && (
                    <>
                      <img src="/shield-half.svg" alt="Premium" />
                      Certified Member
                    </>
                  )}
                  {currentUser?.membership === 'Premium' && (
                    <span>
                      <img src="/shield-checkmark.svg" alt="Premium" />
                      Premium Certified Member
                    </span>
                  )}
                  {currentUser?.membership === 'Applied' && (
                    <span>Processing time is 1 to 5 business days.</span>
                  )}
                </StatNumber>
                <StatHelpText style={{ opacity: 0.5, textAlign: 'center' }}>
                  {currentUser.applicationDate &&
                    `Submitted on: ${new Date(
                      currentUser.applicationDate
                    ).toLocaleDateString()}`}
                </StatHelpText>
              </Stat>
            ) : (
              currentUser && (
                <RegisterBC
                  currentUser={currentUser}
                  setCurrentUser={setCurrentUser}
                />
              )
            )}

            {!currentUser && (
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/2rNSQXoVm5k?si=8dPd-b-5-K92rMuH"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            )}
          </Flex>
        </SimpleGrid>
      </Container>
    </div>
  );
}
