export const videos = [
  {
    id: 0,
    heading: 'Microbiome 101',
    speaker: 'Neil Stollman, MD (UCSF)',
    description:
      'Become familiar with the human gut microbiome and its role in health.',
    videoUrl: 'https://heyheygorgeous.com/biomesquadVideos/DrNeilStollman.mp4',
    type: 'free',
  },
  {
    id: 1,
    heading:
      'Evidence of efficacy and safety of FMT for C. difficile infection',
    speaker: 'Colleen Kelly, MD (Brown)',
    description:
      'Review evidence from randomized controlled trials of FMT in C. difficile.',
    videoUrl: 'https://heyheygorgeous.com/biomesquadVideos/DrColleenKelly.mp4',
    type: 'paid',
  },
  {
    id: 3,
    heading: 'Unique Challenges Around Clinical Trials in C. difficile',
    speaker: 'Paul Feuerstadt, MD (Yale)',
    description:
      'Describe unique challenges to conducting clinical trials of microbiome-based therapies in C. difficile.',
    videoUrl:
      'https://heyheygorgeous.com/biomesquadVideos/DrPaulFeuerstadt.mp4',
    type: 'paid',
  },
  {
    id: 4,
    heading:
      'Taming the Wild West: Developing Standards for Microbiome Research and Product Development',
    speaker: 'Scott Jackson, PhD (NIST)',
    description:
      'Describe the importance of standards for microbiome measurements to support research investigations and clinical/commercial translation of microbiome science.',
    videoUrl: 'https://heyheygorgeous.com/biomesquadVideos/DrScottJackson.mp4',
    type: 'free',
  },
  {
    id: 5,
    heading: 'Detection of SARS-CoV-2 from patient fecal samples by WGS',
    speaker: 'Andreas Papoutsis, PhD (ProgenaBiome)',
    description:
      'Understand methods used to study the composition and function of the gut microbiome and identification of SARS-CoV-2.',
    videoUrl:
      'https://heyheygorgeous.com/biomesquadVideos/DrAndreasPapoutsis.mp4',
    type: 'paid',
  },
  {
    id: 6,
    heading: 'Microbiome and Disease associations (cause or consequence)?',
    speaker: 'Sabine Hazan, MD (ProgenaBiome)',
    description:
      'Understand the value of genetic sequencing in fecal transplant and in SARS-CoV-2 therapies.',
    videoUrl: 'https://heyheygorgeous.com/biomesquadVideos/DrSabineHazan.mp4',
    type: 'paid',
  },
  {
    id: 7,
    heading:
      'Microbiome based Therapies for Recurrent Urinary Tract Infections',
    speaker: 'Sahil Khanna, MD (Mayo Clinic)',
    description: 'Examine the role of microbiome based therapy in chronic UTI.',
    videoUrl: 'https://heyheygorgeous.com/biomesquadVideos/DrSahilKhanna.mp4',
    type: 'paid',
  },
  {
    id: 8,
    heading: 'Microbiota Transplant for Autism',
    speaker: 'James B. Adams, PhD (Arizona State University)',
    description:
      'Review a Phase 1 clinical trial of microbiota transplant for children with autism.',
    videoUrl: 'https://heyheygorgeous.com/biomesquadVideos/DrJamesBAdams.mp4',
    type: 'paid',
  },
  {
    id: 9,
    heading: 'Management of Immunotherapy Induced Colitis',
    speaker: 'Yinghong Wang, MD, PhD (MD Anderson)',
    description:
      'Describe how gut microorganisms impact the metabolism of cancer therapies and how microbial manipulation may transform patient care.',
    videoUrl: 'https://heyheygorgeous.com/biomesquadVideos/DrYinghongWang.mp4',
    type: 'paid',
  },
  {
    id: 10,
    heading: 'Serendipity and Fecal Transplant',
    speaker: 'Thomas Borody, MD (CDD Australia)',
    description:
      'Explore Fecal transplant for other conditions beyond C.diff focusing on Crohn’s and neurological conditions.',
    videoUrl: 'https://heyheygorgeous.com/biomesquadVideos/DrThomasBorody.mp4',
    type: 'paid',
  },
  {
    id: 11,
    heading:
      'Washed microbiota transplantation: Treatment beyond C.diff infection',
    speaker:
      'Faming Zhang MD, PhD (Nanjing Medical University, Nanjing, China)',
    description:
      'Describe the latest progress on methodology, safety and benefits related to FMT in conditions beyond C.diff infection.',
    videoUrl: 'https://heyheygorgeous.com/biomesquadVideos/DrFamingZhang.mp4',
    type: 'paid',
  },
  {
    id: 12,
    heading: 'How do we move forward?',
    speaker: 'Alexander Khoruts, MD (University of Minnesota)',
    description:
      'Understand the challenges in doing research to develop and make available the next-generation microbiota therapeutics for different indications?',
    videoUrl: 'https://heyheygorgeous.com/biomesquadVideos/DrAlexKhoruts.mp4',
    type: 'paid',
  },
];
