// NDAContent.js
import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

// Assuming you have the styles defined in DownloadPDF.js and imported them here
// import { styles } from './DownloadPDF';

// If not, define the styles here:
const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  content: {
    fontSize: 10,
  },
  heading: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  paragraph: {
    marginBottom: 10,
  },
  bold: {
    fontWeight: 'bold',
  },
});

function NDAContent({ displayName, NDADate }) {
  return (
    <View>
      <Text style={styles.heading}>Nondisclosure Agreement</Text>

      <View style={styles.paragraph}>
        <Text>
          CONFIDENTIALITY AGREEMENT This Confidentiality Agreement (the{' '}
          <Text style={styles.bold}>"Agreement”</Text> is entered into and is
          elective as of {NDADate} (the{' '}
          <Text style={styles.bold}>"Effective Date"</Text>) by and between
          ProgenaBiome, LLC, located at 1845 Knoll Drive, Ventura, California
          93003 (<Text style={styles.bold}>“DISCLOSING PARTY"</Text>), and{' '}
          {displayName} (<Text style={styles.bold}>“RECEIVING PARTY”</Text>)
          Definition of Confidential Information: RECEIVING PARTY agrees that
          information disclosed by DISCLOSING PARTY to RECEIVING PARTY regarding
          DISCLOSING PARTY's Intellectual Property relating to the development,
          creation, management, and financing of facilities for stool
          transplantation (the{' '}
          <Text style={styles.bold}>"Microbiome Protocol"</Text>), and related
          business, medical, and technical information, including Protocols,
          assays, studies, tests and results developed, or engaged in, by
          DISCLOSING PARTY, and other information, including but not limited to,
          information learned by RECEIVING PARTY from DISCLOSING PARTY'S
          employees or agents, or through inspection of DISCLOSING PARTY's
          property, that relates to DISCLOSING PARTY's products, designs,
          business plans, business opportunities, finances, research,
          development, know- how, personnel, or third-party confidential
          information disclosed to RECEIVING PARTY by DISCLOSING PARTY, the
          terms and conditions of this Agreement, and the existence of the
          discussions between RECEIVING PARTY and DISCLOSING PARTY will be
          considered and referred to collectively in this Agreement as{' '}
          <Text style={styles.bold}>"Confidential Information.”</Text>
          Confidential Information, however, does not include information that:
          1) Is now or subsequently becomes generally available to the public
          through no fault or breach on the part of RECEIVING PARTY; 2)
          RECEIVING PARTY can demonstrate to have had rightfully in its
          possession prior to disclosure to RECEIVING PARTY by DISCLOSING PARTY;
          3) RECEIVING PARTY can demonstrate was independently developed by
          RECEIVING PARTY without the use of DISCLOSING PARTY's Confidential
          Information; or 4) RECEIVING PARTY rightfully obtains from a third
          party who has the right to transfer or disclose it. Nondisclosure and
          Nonuse of Confidential Information: RECEIVING PARTY will not disclose,
          publish, or disseminate Confidential Information to anyone other than
          those of its employees with a need to know, and RECEIVING PARTY agrees
          to take reasonable precautions to prevent any unauthorized use,
          disclosure, publication, or dissemination of Confidential Information.
          RECEIVING PARTY agrees not to use Confidential Information otherwise
          for its own or any third party's benefit without the prior written
          approval of an authorized representative of DISCLOSING PARTY in each
          instance. RECEIVING PARTY Information: It is understood that
          DISCLOSING PARTY does not desire to receive any confidential
          information from RECEIVING PARTY and, accordingly, with respect to any
          information provided by RECEIVING PARTY to DISCLOSING PARTY,
          DISCLOSING PARTY shall not have any confidential obligation unless
          DISCLOSING PARTY specifically agrees in writing, by an Amendment to
          this Agreement, or by a separate agreement executed by DISCLOSING
          PARTY, to receive and treat such information as confidential at the
          time of receipt. Term: The term of this Agreement during which
          DISCLOSING PARTY may disclose Confidential Information to RECEIVING
          PARTY shall continue for five (5) years from the Effective Date set
          forth above. DISCLOSING PARTY may terminate this Agreement at any
          time. The obligations of confidentiality contained in Paragraph 2
          above shall survive termination of this Agreement, and shall remain in
          force for five (5) years from the Effective Date set forth above.
          Entire Agreement and Governing Law: This Agreement constitutes the
          entire agreement with respect to the Confidential Information
          disclosed hereunder and supersedes all prior or contemporaneous oral
          or written agreements concerning such Confidential Information. This
          Agreement may not be amended except by a written agreement signed by
          authorized representatives of both parties. This Agreement will be
          governed by and construed in accordance with the laws of the State of
          California, excluding that body of California law concerning conflicts
          of law. Miscellaneous: All notices shall be in writing and bear the
          addresses of the parties to this agreement and shall be dispatched by
          certified or registered mail, return receipt requested, or by e-mail
          or facsimile. This Agreement shall not be assignable by RECEIVING
          PARTY without the prior written consent of DISCLOSING PARTY and any
          such attempted assignment shall be void. In the event that any one or
          more of the provisions of this Agreement is unenforceable, the
          enforceability of the remaining provisions shall be unimpaired.
          Understood and Agreed to by the duly authorized representatives of the
          parties.
        </Text>
      </View>

      <View style={styles.paragraph}>
        <Text>
          <Text style={styles.bold}>
            Electronically Signed By: {displayName} on {NDADate}
          </Text>
        </Text>
      </View>
    </View>
  );
}

export default NDAContent;
