import {
  Alert,
  AlertIcon,
  Button,
  Container,
  FormControl,
  Heading,
  Input,
  Stack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { signNDA } from '../../api/api';
import { useNavigate } from 'react-router-dom';

function NDA({ currentUser, setCurrentUser }) {
  const navigate = useNavigate();
  const [todaysDate, setTodaysDate] = useState(new Date().toLocaleDateString());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [formName, setFormName] = useState('');

  const submitNDA = () => {
    console.log(
      'submit NDA',
      currentUser,
      setCurrentUser,
      todaysDate,
      navigate
    );
    signNDA(currentUser, setCurrentUser, todaysDate, navigate);
  };

  useEffect(() => {
    if (currentUser?.signedNDA) navigate('/');
  }, []);

  return (
    <div className="nda">
      <Alert status="warning" fontWeight={800}>
        <AlertIcon />
        You must sign the NDA to continue using The Biome Squad website.
      </Alert>
      <Container maxW={'5xl'}>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 18, md: 20 }}
        >
          <Heading
            color={'gray.800'}
            lineHeight={1.1}
            display="flex"
            fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
          >
            Nondisclosure Agreement
          </Heading>
          <div>
            CONFIDENTIALITY AGREEMENT This Confidentiality Agreement (the
            "Agreement”) is entered into and is elective as of {todaysDate},
            {currentYear} (the "Effective Date") by and between ProgenaBiome,
            LLC, located at 1845 Knoll Drive, Ventura, California 93003
            (“DISCLOSING PARTY"), and {currentUser?.displayName || 'you'}
            (“RECEIVING PARTY”) Definition of Confidential Information:
            RECEIVING PARTY agrees that information disclosed by DISCLOSING
            PARTY to RECEIVING PARTY regarding DISCLOSING PARTY's Intellectual
            Property relating to the development, creation, management, and
            financing of facilities for stool transplantation (the "Microbiome
            Protocol"), and related business, medical, and technical
            information, including Protocols, assays, studies, tests and results
            developed, or engaged in, by DISCLOSING PARTY, and other
            information, including but not limited to, information learned by
            RECEIVING PARTY from DISCLOSING PARTY'S employees or agents, or
            through inspection of DISCLOSING PARTY's property, that relates to
            DISCLOSING PARTY's products, designs, business plans, business
            opportunities, finances, research, development, know- how,
            personnel, or third-party confidential information disclosed to
            RECEIVING PARTY by DISCLOSING PARTY, the terms and conditions of
            this Agreement, and the existence of the discussions between
            RECEIVING PARTY and DISCLOSING PARTY will be considered and referred
            to collectively in this Agreement as "Confidential Information.”
            Confidential Information, however, does not include information
            that: 1) Is now or subsequently becomes generally available to the
            public through no fault or breach on the part of RECEIVING PARTY; 2)
            RECEIVING PARTY can demonstrate to have had rightfully in its
            possession prior to disclosure to RECEIVING PARTY by DISCLOSING
            PARTY; 3) RECEIVING PARTY can demonstrate was independently
            developed by RECEIVING PARTY without the use of DISCLOSING PARTY's
            Confidential Information; or 4) RECEIVING PARTY rightfully obtains
            from a third party who has the right to transfer or disclose it.
            Nondisclosure and Nonuse of Confidential Information: RECEIVING
            PARTY will not disclose, publish, or disseminate Confidential
            Information to anyone other than those of its employees with a need
            to know, and RECEIVING PARTY agrees to take reasonable precautions
            to prevent any unauthorized use, disclosure, publication, or
            dissemination of Confidential Information. RECEIVING PARTY agrees
            not to use Confidential Information otherwise for its own or any
            third party's benefit without the prior written approval of an
            authorized representative of DISCLOSING PARTY in each instance.
            RECEIVING PARTY Information: It is understood that DISCLOSING PARTY
            does not desire to receive any confidential information from
            RECEIVING PARTY and, accordingly, with respect to any information
            provided by RECEIVING PARTY to DISCLOSING PARTY, DISCLOSING PARTY
            shall not have any confidential obligation unless DISCLOSING PARTY
            specifically agrees in writing, by an Amendment to this Agreement,
            or by a separate agreement executed by DISCLOSING PARTY, to receive
            and treat such information as confidential at the time of receipt.
            Term: The term of this Agreement during which DISCLOSING PARTY may
            disclose Confidential Information to RECEIVING PARTY shall continue
            for five (5) years from the Effective Date set forth above.
            DISCLOSING PARTY may terminate this Agreement at any time. The
            obligations of confidentiality contained in Paragraph 2 above shall
            survive termination of this Agreement, and shall remain in force for
            five (5) years from the Effective Date set forth above. Entire
            Agreement and Governing Law: This Agreement constitutes the entire
            agreement with respect to the Confidential Information disclosed
            hereunder and supersedes all prior or contemporaneous oral or
            written agreements concerning such Confidential Information. This
            Agreement may not be amended except by a written agreement signed by
            authorized representatives of both parties. This Agreement will be
            governed by and construed in accordance with the laws of the State
            of California, excluding that body of California law concerning
            conflicts of law. Miscellaneous: All notices shall be in writing and
            bear the addresses of the parties to this agreement and shall be
            dispatched by certified or registered mail, return receipt
            requested, or by e-mail or facsimile. This Agreement shall not be
            assignable by RECEIVING PARTY without the prior written consent of
            DISCLOSING PARTY and any such attempted assignment shall be void. In
            the event that any one or more of the provisions of this Agreement
            is unenforceable, the enforceability of the remaining provisions
            shall be unimpaired. Understood and Agreed to by the duly authorized
            representatives of the parties.
          </div>

          <FormControl isRequired>
            <Input
              placeholder={`Type your name ${
                currentUser?.displayName ? `(${currentUser?.displayName})` : ''
              } to acknowledge the NDA.`}
              bg={'gray.100'}
              border={0}
              color={'gray.500'}
              _placeholder={{
                color: 'gray.500',
              }}
              className={formName?.length > 0 ? 'filled' : ''}
              value={formName}
              onChange={e => setFormName(e.target.value)}
            />
          </FormControl>
          <Button
            mt={-4}
            fontFamily={'heading'}
            mb={8}
            w={'full'}
            colorScheme="blue"
            onClick={() => submitNDA()}
          >
            Submit NDA
          </Button>
        </Stack>
      </Container>
    </div>
  );
}

export default NDA;
