import { Avatar, Box, Stack, Text, useColorModeValue } from '@chakra-ui/react';

export default function LargeQuote({ avatar, quote, authorTitle, authorName }) {
  return (
    <Stack
      bg={useColorModeValue('gray.50', 'gray.800')}
      py={16}
      px={8}
      spacing={{ base: 8, md: 10 }}
      align={'center'}
      direction={'column'}
    >
      <Text
        fontSize={{ base: 'xl', md: '2xl' }}
        textAlign={'center'}
        maxW={'3xl'}
      >
        {quote}
      </Text>
      <Box textAlign={'center'}>
        <Avatar src={avatar} mb={2} />

        <Text fontWeight={600}>{authorName}</Text>
        <Text fontSize={'sm'} color={useColorModeValue('gray.400', 'gray.400')}>
          {authorTitle}
        </Text>
      </Box>
    </Stack>
  );
}
