import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deleteDocument, getDocument, getRootDocument } from '../../api/api';
import LabsAdd from '../Labs/LabsAdd';

function AdminUserLab({ currentUser, setCurrentUser }) {
  const toast = useToast();
  const navigate = useNavigate();
  const { id, labId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lab, setLab] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isResending, setIsResending] = useState(false);
  const [isNotifyingDoctor, setIsNotifyingDoctor] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const cancelRef = useRef();

  const handleDelete = () => {
    deleteDocument(currentUser?.id, 'labs', id, '/labs', navigate);
  };

  const handleResend = () => {
    setIsResending(true);
    setTimeout(() => {
      setIsResending(false);
      toast({
        title: `Email sent to ${lab?.patient?.email}`,
        description: `Disclosure forms were included in the email.`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    }, 3000);
  };

  const handleNotifyDoctor = () => {
    document
      .querySelector('#submitLabCompleteForm')
      .click(e => e.preventDefault());
    setIsNotifyingDoctor(true);
    setTimeout(() => {
      setIsNotifyingDoctor(false);
      toast({
        title: `Email sent to ${selectedUser?.email}`,
        description: `Notified ${selectedUser?.displayName} that labs are ready.`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    }, 3000);
  };

  useEffect(() => {
    getDocument(id, 'labs', labId, setLab);
    getRootDocument(currentUser, 'users', id, setSelectedUser);
  }, [id]);

  return (
    <div id="labs" className="page-padding labs-detail">
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Lab
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <LabsAdd
        currentUser={currentUser}
        adminSelectedUserId={id}
        patient={lab?.patient}
        setCurrentUser={setCurrentUser}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        callback={handleNotifyDoctor}
        lab={lab}
      />
      <Heading
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        fontSize={{
          base: '4xl',
          md: '5xl',
        }}
      >
        Lab
        <ButtonGroup>
          <Button colorScheme="gray" onClick={() => onOpen()}>
            Delete
          </Button>
          <Button colorScheme="blue" onClick={() => setIsModalOpen(true)}>
            Edit
          </Button>
          <Button
            colorScheme="blue"
            isLoading={isResending}
            loadingText="Sending Forms"
            onClick={() => handleResend()}
          >
            Resend Patient Forms
          </Button>
        </ButtonGroup>
      </Heading>
      <TableContainer>
        <Table variant="simple">
          {lab && (
            <Tbody>
              <Tr>
                <Td>Id</Td>
                <Td className="id">{lab?.id}</Td>
              </Tr>
              <Tr>
                <Td>Status</Td>
                <Td className="status">{lab?.status}</Td>
              </Tr>
              <Tr>
                <Td>Date</Td>
                <Td className="date">{lab?.date}</Td>
              </Tr>

              <Tr>
                <Td>Patient Name</Td>
                <Td className="firstName">
                  <Link to={`/patient/${lab?.patientId}`}>
                    <Button
                      colorScheme="blue"
                      variant="ghost"
                      style={{ marginLeft: -15 }}
                    >
                      {lab?.patient?.firstName} {lab?.patient?.lastName}
                    </Button>
                  </Link>
                </Td>
              </Tr>
              <Tr>
                <Td>Date of Birth</Td>
                <Td className="dob">{lab?.patient?.dob}</Td>
              </Tr>
              <Tr>
                <Td>Contact Info</Td>
                <Td className="email">
                  <a
                    style={{ marginLeft: -15 }}
                    href={`mailto:${lab?.patient?.email}`}
                  >
                    <Button colorScheme="blue" variant="ghost">
                      {lab?.patient?.email}
                    </Button>
                  </a>
                  <a href={`tel:${lab?.mobileWithCarrier}`}>
                    <Button colorScheme="blue" variant="ghost">
                      {lab?.patient?.mobile}
                    </Button>
                  </a>
                </Td>
              </Tr>
              <Tr>
                <Td>Address</Td>
                <Td className="address">{lab?.patient?.address}</Td>
              </Tr>
              <Tr>
                <Td>Flora Number</Td>
                <Td className="address">{lab?.floraNumber}</Td>
              </Tr>

              <Tr>
                <Td>Note</Td>
                <Td className="note">
                  <div dangerouslySetInnerHTML={{ __html: lab?.note }} />
                </Td>
              </Tr>
            </Tbody>
          )}
        </Table>
      </TableContainer>

      <iframe
        title="secret-frame-nine"
        name="secret-frame-nine"
        width="0"
        height="0"
        border="0"
        style={{ display: 'none' }}
      ></iframe>
      <form
        className="form"
        action="https://docs.google.com/forms/d/e/1FAIpQLSf9CaM886kRZR1fpdJOlpgPHaUsrFVIlUIdmP4Pm-98lOc7Ng/formResponse"
        id="ss-form9"
        method="POST"
        target="secret-frame-nine"
        style={{ display: 'none' }}
      >
        <div className="form-group">
          <input
            name="entry.292519704"
            readOnly
            value={new Date().toLocaleDateString}
          />
          <input name="entry.1914334397" readOnly value={selectedUser?.id} />
          <input
            name="entry.367112929"
            readOnly
            type="email"
            value={selectedUser?.email}
          />
          <input
            name="entry.158102897"
            readOnly
            value={selectedUser?.displayName}
          />
          <input name="entry.222830709" readOnly value={lab?.patient?.id} />
          <input
            name="entry.1255680602"
            readOnly
            value={`${lab?.patient?.firstName} ${lab?.patient?.lastName}`}
          />
          <input name="entry.411418526" readOnly value={lab?.patient?.email} />
        </div>

        <input
          id="submitLabCompleteForm"
          type="submit"
          value="Submit Lab Complete"
        />
      </form>
    </div>
  );
}

export default AdminUserLab;
