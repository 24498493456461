import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';

function VideoPlayer({ heading, description, videoUrl, isOpen, onClose }) {
  return (
    <div className="video-player-modal">
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{heading}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <h3>{description}</h3>
            {description}
            <iframe
              width="100%"
              height="100%"
              src={videoUrl}
              title="Video player"
              frameborder="0"
              allow={
                'accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              }
              allowfullscreen
            ></iframe>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default VideoPlayer;
