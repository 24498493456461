import React, { useEffect, useState } from 'react';
import LabsSidebar from './LabsSidebar';
import {
  Button,
  Container,
  Heading,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import LabsAdd from './LabsAdd';
import { getDocuments, getLabs } from '../../api/api';
import { useNavigate } from 'react-router-dom';
import LabsPlaceholder from './LabsPlaceholder';
import Loader from '../../Components/Loader/Loader';

const labHeaders = [
  'order id',
  'order date',
  'patient email',
  'patient',
  'patient dob',
  'status',
];

function LabsCertified({ currentUser, setCurrentUser }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [labs, setLabs] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    getDocuments(currentUser?.id, 'labs', setLabs);
    setTimeout(() => {
      setIsLoaded(true);
    }, 500);
  }, [currentUser]);

  if (!isLoaded) return <Loader hideLogo={true} />;

  return (
    <div id="labs" className="page-padding">
      <LabsAdd
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      {!labs && isLoaded && (
        <LabsPlaceholder currentUser={currentUser} callback={setIsOpen} />
      )}
      {labs && (
        <>
          <Heading
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={4}
            fontSize={{
              base: '4xl',
              md: '5xl',
            }}
          >
            Labs
            <Button colorScheme="blue" onClick={() => setIsOpen(true)}>
              Order Lab
            </Button>
          </Heading>

          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  {labHeaders.map(l => (
                    <Th>{l}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {labs &&
                  labs.map(l => (
                    <Tr onClick={() => navigate(`/lab/${l.id}`)}>
                      <Td className="id">{l.id}</Td>
                      <Td className="date">
                        {new Date(l.date).toLocaleDateString()}
                      </Td>
                      <Td className="email">{l.patient?.email}</Td>
                      <Td className="firstName">
                        {l.patient?.firstName} {l.patient?.lastName}
                      </Td>
                      <Td className="dob">
                        {new Date(l.patient?.dob).toLocaleDateString()}
                      </Td>
                      <Td className="status">{l.status}</Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
}

export default LabsCertified;
