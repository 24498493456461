import React from 'react';
import './Conference.scss';
import {
  Box,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import ConferenceForm from './ConferenceForm';

function ConferenceEmail() {
  return (
    <Box id="sign-up" position={'relative'}>
      <Container as={SimpleGrid} justifyContent="center" height="100vh">
        <Stack rounded={'xl'} height="100vh" justifyContent="center">
          <Stack spacing={4}>
            <Flex alignItems="center" gap={4}>
              <Heading
                color={'gray.800'}
                lineHeight={1.1}
                display="flex"
                fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
              >
                Sign Up
              </Heading>
              {/* <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
                Step 2/2
              </Text> */}
            </Flex>
          </Stack>
          <ConferenceForm conference={true} />
        </Stack>
      </Container>
    </Box>
  );
}

export default ConferenceEmail;
