import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Stack,
  Heading,
  Text,
  Container,
  Input,
  Button,
  SimpleGrid,
  Avatar,
  AvatarGroup,
  useBreakpointValue,
  IconProps,
  Icon,
  FormControl,
  FormLabel,
  Center,
  AvatarBadge,
  IconButton,
  FormHelperText,
  AlertIcon,
  Alert,
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { FaGoogle } from 'react-icons/fa';
import { authFire } from '../../api/fire';
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import { validateEmail } from '../../Helpers/Validators';
import './SignUp.scss';

const provider = new GoogleAuthProvider();

const Blur = props => {
  return (
    <Icon
      width={useBreakpointValue({ base: '100%', md: '40vw', lg: '30vw' })}
      zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="71" cy="61" r="111" fill="#F56565" />
      <circle cx="244" cy="106" r="139" fill="#ED64A6" />
      <circle cy="291" r="139" fill="#ED64A6" />
      <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936" />
      <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B" />
      <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78" />
      <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" />
    </Icon>
  );
};

export default function SignUp({ setCurrentUser, conference }) {
  const navigate = useNavigate();
  const auth = getAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    if (password === passwordAgain) {
      setIsEnabled(true);
      return;
    } else {
      setIsEnabled(false);
    }
  }, [email, password, passwordAgain]);

  const handleSignUp = () => {
    createUserWithEmailAndPassword(authFire, email, password)
      .then(userCredential => {
        const user = userCredential.user;

        navigate('/onboarding');
        setCurrentUser(user);
      })
      .catch(error => {
        console.log('error', error);
        const errorCode = error.code;
        const errorMessage = error.message;
        if (error.message === 'Firebase: Error (auth/email-already-in-use)') {
          setErrorMessage(
            'Email already exists. Please sign in with your email or register with another email.'
          );
        } else {
          setErrorMessage(error.message);
        }
      });
  };

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then(result => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        navigate('/onboarding');
        setCurrentUser(user);
      })
      .catch(error => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  return (
    <Box id="sign-up" position={'relative'}>
      {errorMessage && (
        <Alert status="error" fontWeight={800}>
          <AlertIcon />
          {errorMessage}
        </Alert>
      )}
      <Blur
        position={'absolute'}
        top={-10}
        left="0"
        width="100vw"
        height="100vh"
        style={{ filter: 'blur(70px)' }}
        zIndex={-1}
      />
      <Container
        as={SimpleGrid}
        justifyContent="center"
        maxW={'7xl'}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack
          bg={'gray.50'}
          rounded={'xl'}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: 'lg' }}
        >
          <Stack spacing={4}>
            <Heading
              color={'gray.800'}
              lineHeight={1.1}
              display="flex"
              fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
            >
              Sign Up
            </Heading>
            <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
              Join a community of people passionate about gut health and get
              access to studies, guides, doctors/practitioners who specialize in
              the microbiome.
            </Text>
            <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
              <b>Doctors/Practitioners:</b> You can apply for certification to
              get access to ordering labs after signing up below.
            </Text>
          </Stack>
          <Box as={'form'}>
            <Stack spacing={4}>
              <FormControl id="email" isRequired>
                {email.length > 5 && <FormLabel>Email</FormLabel>}
                <Input
                  placeholder="Email"
                  _placeholder={{ color: 'gray.500' }}
                  type="email"
                  value={email}
                  className={email?.length > 0 ? 'filled' : ''}
                  onChange={e => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl id="password" isRequired>
                {password.length > 5 && <FormLabel>Password</FormLabel>}
                <Input
                  placeholder="Create Password"
                  _placeholder={{ color: 'gray.500' }}
                  value={password}
                  className={password?.length > 0 ? 'filled' : ''}
                  onChange={e => setPassword(e.target.value)}
                />
              </FormControl>
              <FormControl id="passwordagain" isRequired>
                {passwordAgain.length > 5 && (
                  <FormLabel>Password Again</FormLabel>
                )}
                <Input
                  placeholder="Password Again"
                  _placeholder={{ color: 'gray.500' }}
                  value={passwordAgain}
                  className={password?.length > 0 ? 'filled' : ''}
                  onChange={e => setPasswordAgain(e.target.value)}
                />
              </FormControl>
            </Stack>
            <Button
              isDisabled={!isEnabled}
              fontFamily={'heading'}
              mt={8}
              w={'full'}
              color={'white'}
              colorScheme="blue"
              onClick={() => handleSignUp()}
            >
              Sign Up
            </Button>
          </Box>

          <Box as={'form'}>
            <div>
              <div className="or">----- OR -----</div>
              <Button
                fontFamily={'heading'}
                mt={8}
                w={'full'}
                color={'white'}
                leftIcon={<FaGoogle h={4} w={4} color={'gray.300'} />}
                onClick={() => signInWithGoogle()}
                colorScheme="blue"
              >
                Sign Up with Google
              </Button>
              <Text
                color={'gray.500'}
                mt={4}
                fontSize={{ base: 'xs', sm: 'sm' }}
              >
                By signing in and creating an account you agree to our{' '}
                <Link to="/terms">Terms of Service</Link> and{' '}
                <Link to="/privacy">Policies</Link>.
              </Text>
            </div>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
