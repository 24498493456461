import { Table, TableContainer, Tbody } from '@chakra-ui/react';
import Comment from './Comment';

function Comments({ currentUser, setCurrentUser, discussionId, comments }) {
  return (
    <div id="comments">
      <TableContainer>
        <Table variant="simple">
          <Tbody>
            {comments?.map(d => (
              <Comment
                currentUser={currentUser}
                setCurrentUser={setCurrentUser}
                data={d}
                discussionId={discussionId}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Comments;
