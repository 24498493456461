import {
  Box,
  SimpleGrid,
  Text,
  Stack,
  Heading,
  ButtonGroup,
  Button,
} from '@chakra-ui/react';

const Feature = ({ title, text, link, logo }) => {
  return (
    <Stack align="center">
      <div
        style={{
          backgroundImage: `url(${logo})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          height: '100px',
          width: '100%',
          backgroundPosition: 'center',
          padding: '10px',
        }}
        className="feature-image"
      />

      <Text fontWeight={600}>{title}</Text>
      <Text color={'gray.600'}>{text}</Text>
      <ButtonGroup>
        <Button onClick={() => window.open(link, '_blank')}>
          View Article
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

const data = [
  {
    title: 'Can Fecal Microbiota Transplant (FMT) Aid in Cancer Treatment?',
    text: 'November 21, 2019 - BioSpace',
    logo: 'https://www.biospace.com/assets/dist/images/logo.png;v=3d4a2d9c30152a980c1e355d5990da83',
    link: 'https://www.biospace.com/article/releases/can-fecal-microbiota-transplant-fmt-aid-in-cancer-treatment-/',
  },
  {
    title:
      'Doctors Come Together at FDA During C.diff Awareness Month, Advocate for Treatments',
    text: 'Nov. 13, 2019 - Business Insider',
    logo: 'https://www.insider.com/public/assets/INSIDER/US/og-image-logo-social.png',
    link: 'https://markets.businessinsider.com/news/stocks/doctors-come-together-at-fda-during-c-diff-awareness-month-advocate-for-treatments-1028686472#:~:text=Among%20those%20in%20attendance%20were%20gastroenterologists%20Paul%20Feuerstadt,continue%20to%20collaborate%20in%20hopes%20of%20finding%20answers.',
  },
  {
    title: 'Can Fecal Microbiota Transplant (FMT) Aid in Cancer Treatment?',
    text: 'Nov 21, 2019 - Biospace',
    logo: 'https://www.biospace.com/assets/dist/images/logo.png;v=3d4a2d9c30152a980c1e355d5990da83',
    link: 'https://www.biospace.com/article/releases/can-fecal-microbiota-transplant-fmt-aid-in-cancer-treatment-/',
  },
];

export default function AsSeenIn() {
  return (
    <Box p={{ base: 0, md: 16 }} m={16}>
      <Heading align="center" size="4xl" mb={16}>
        Press
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
        {data.map(d => (
          <Feature title={d.title} text={d.text} link={d.link} logo={d.logo} />
        ))}
      </SimpleGrid>
    </Box>
  );
}
