import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Collapse,
  Icon,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const MobileNavItem = ({ label, link, onClose }) => {
  const { isOpen, onToggle } = useDisclosure();
  const navigate = useNavigate();

  return (
    <Stack spacing={4}>
      <Box
        py={2}
        as="a"
        justifyContent="space-between"
        alignItems="center"
        _hover={{
          textDecoration: 'none',
        }}
        onClick={() => {
          navigate(link);
          onClose();
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue('gray.600', 'gray.200')}
        >
          {label}
        </Text>
      </Box>
    </Stack>
  );
};

function NavigationMobile({ currentUser, navItems, onClose }) {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{ md: 'none' }}
    >
      {currentUser?.type !== 'admin' &&
        navItems?.map(navItem => (
          <MobileNavItem
            key={navItem.label}
            label={navItem.label}
            link={navItem.link}
            onClose={onClose}
          />
        ))}
      <MobileNavItem key={'Home'} label={'Home'} link={'/'} onClose={onClose} />
      <MobileNavItem
        key={'Profile'}
        label={'Profile'}
        link={'/profile'}
        onClose={onClose}
      />
      <MobileNavItem
        key={'Certified Members'}
        label={'Certified Members'}
        link={'/certified-members'}
        onClose={onClose}
      />
      <MobileNavItem
        key={'Become Certified'}
        label={'Become Certified'}
        link={'/become-certified'}
        onClose={onClose}
      />
      <MobileNavItem
        key={'Meetings'}
        label={'Meetings'}
        link={'/meetings'}
        onClose={onClose}
      />
      <MobileNavItem
        key={'Press'}
        label={'Press'}
        link={'/press'}
        onClose={onClose}
      />
      <MobileNavItem
        key={'Logout'}
        label={'Logout'}
        link={'/logout'}
        onClose={onClose}
      />
    </Stack>
  );
}

export default NavigationMobile;
