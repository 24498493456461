import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

function Logout({ setCurrentUser }) {
  const auth = getAuth();
  const navigate = useNavigate();

  const signOutUser = async () => {
    signOut(auth);
    localStorage.removeItem('user');
    setCurrentUser(null);
    setTimeout(() => {
      navigate('/');
      window.location.reload();
    }, 500);
  };

  signOutUser();

  return <div></div>;
}

export default Logout;
