import React from 'react';
import LargeQuote from '../../Layouts/LargeQuote/LargeQuote';
import Hero from '../../Layouts/Hero/Hero';
import HeroVideo from '../../Layouts/HeroVideo/HeroVideo';
import AsSeenIn from '../../Layouts/AsSeenIn/AsSeenIn';
import HeroImage from '../../Layouts/HeroImage/HeroImage';
import BecomeCertified from '../BecomeCertified/BecomeCertified';
import MembershipTypes from '../../Layouts/MembershipTypes/MembershipTypes';
import LandingHazan from './LandingHazan';
import './Conference.scss';

function ConferenceWatchVideo() {
  return (
    <div className="conference">
      <LandingHazan
        actions={[
          { title: 'Back', link: '/conference' },
          { title: 'Sign Up For Free', link: '/conference/signup' },
        ]}
      />
    </div>
  );
}

export default ConferenceWatchVideo;
