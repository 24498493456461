import React, { useState } from 'react';
import { Button } from '@chakra-ui/react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  pdf,
} from '@react-pdf/renderer';

// Create styles (you can customize these)
const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  content: {
    fontSize: 10,
  },
  heading: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  paragraph: {
    marginBottom: 10,
  },
  bold: {
    fontWeight: 'bold',
  },
});

const DownloadPDF = ({
  htmlString, // This should be a React component now
  buttonLabel,
  fileName,
  leftIcon,
  size = 'md',
}) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = async () => {
    setIsDownloading(true);

    try {
      const blob = await pdf(
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.content}>
              {htmlString} {/* Render the React component */}
            </View>
          </Page>
        </Document>
      ).toBlob();

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName || 'download.pdf';
      link.click();
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Button
      leftIcon={leftIcon}
      size={size}
      colorScheme="blue"
      onClick={handleDownload}
      isLoading={isDownloading}
    >
      {isDownloading ? 'Downloading...' : buttonLabel}
    </Button>
  );
};

export default DownloadPDF;
