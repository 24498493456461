import React from 'react';
import './Conference.scss';
import {
  Box,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import ConferenceRegister from './ConferenceRegister';

function ConferenceSignUpTwo({ currentUser, setCurrentUser, isCertified }) {
  return (
    <Box id="sign-up" position={'relative'}>
      <Container as={SimpleGrid} justifyContent="center" height="100vh">
        <Stack
          rounded={'xl'}
          maxW={{ lg: 'lg' }}
          height="100vh"
          justifyContent="center"
        >
          <Stack spacing={4}>
            <Flex alignItems="center" gap={4}>
              <Heading
                color={'gray.800'}
                lineHeight={1.1}
                display="flex"
                fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
              >
                Sign Up
              </Heading>
              <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
                Step 2/2
              </Text>
            </Flex>
          </Stack>
          <ConferenceRegister
            currentUser={currentUser}
            setCurrentUser={setCurrentUser}
            conference={true}
          />
        </Stack>
      </Container>
    </Box>
  );
}

export default ConferenceSignUpTwo;
