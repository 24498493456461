import React, { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { authFire } from '../../api/fire';
import { uploadPdf } from '../../api/adminApi';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import ReactQuill from 'react-quill';

function AdminArticlesAdd({
  currentUser,
  isOpen,
  setIsOpen,
  fbUser,
  handleUploadComplete,
}) {
  const [pdfFile, setPdfFile] = useState(null);
  const [title, setTitle] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState(null);
  const [isEnabled, setIsEnabled] = useState(false);
  const [userLoaded, setUserLoaded] = useState(false); // Track user data loading
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (title?.length > 0 && pdfFile) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false); // Disable if title or file is missing
    }
  }, [title, pdfFile]);

  const onClose = () => {
    setIsOpen(false);
  };

  const handleFileChange = e => {
    setPdfFile(e.target.files[0]);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setUploadError(null);

    if (pdfFile && currentUser) {
      const combinedUser = { ...currentUser, ...fbUser };
      console.log('combinedUser', combinedUser);
      // Only upload if user is authenticated and data is loaded
      uploadPdf(
        combinedUser,
        title,
        description,
        pdfFile,
        progress => {
          setUploadProgress(progress);
        },
        error => {
          setUploadError(error);
        },
        handleUploadComplete
      );
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{'Add'} Article</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <>
            <form onSubmit={e => handleSubmit(e)}>
              <Stack spacing={4}>
                <Flex gap={4}>
                  <FormControl isRequired>
                    {title?.length > 0 && <FormLabel>Title</FormLabel>}
                    <Input
                      placeholder="Title"
                      size="md"
                      type="text"
                      value={title}
                      onChange={e => setTitle(e.target.value)}
                    />
                  </FormControl>
                </Flex>
                <FormControl>
                  <FormLabel>Description</FormLabel>
                  <ReactQuill
                    theme="snow"
                    value={description}
                    onChange={setDescription}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>PDF File</FormLabel>
                  <Input
                    placeholder="PDF File"
                    type="file"
                    id="pdfFile"
                    accept=".pdf"
                    onChange={handleFileChange}
                    required
                    bg={'gray.100'}
                    border={0}
                    color={'gray.500'}
                    _placeholder={{
                      color: 'gray.500',
                    }}
                  />
                </FormControl>
                <FormControl>
                  {uploadProgress > 0 && (
                    <div>
                      <progress value={uploadProgress} max="100" />
                      <span>{Math.round(uploadProgress)}%</span>
                    </div>
                  )}

                  {uploadError && <div className="error">{uploadError}</div>}
                </FormControl>
              </Stack>
              <Button
                isDisabled={!isEnabled}
                fontFamily={'heading'}
                mt={4}
                w={'full'}
                bgGradient="linear(to-r, red.400,pink.400)"
                color={'white'}
                _hover={{
                  bgGradient: 'linear(to-r, red.400,pink.400)',
                  boxShadow: 'xl',
                }}
                onClick={e => handleSubmit(e)}
              >
                Submit
              </Button>
            </form>
            <br />
          </>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default AdminArticlesAdd;
