import React, { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  Heading,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from '@uidotdev/usehooks';
import { AddIcon } from '@chakra-ui/icons';
import { fetchPdfs } from '../../api/api';
import AdminArticlesAdd from './AdminArticlesAdd';

const userHeaders = [/* 'id', */ 'title', 'description'];

function AdminArticles({ currentUser, applicationsOnly, fbUser }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState(null);
  const [listData, setListData] = useState([]);
  const debouncedSearchTerm = useDebounce(searchQuery, 300);

  useEffect(() => {
    const searchHN = async () => {
      let results = [];
      if (debouncedSearchTerm) {
        const array = listData.map(d => ({
          ...d,
          dataString: Object.values(d).join(),
        }));
        const fArray = array.filter(a =>
          a.dataString.toLowerCase().includes(searchQuery?.toLowerCase())
        );
        setFilteredData(fArray);
      } else {
        setFilteredData(listData);
      }
    };
    searchHN();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    fetchPdfs(setListData, setFilteredData);
  }, []);

  useEffect(() => {
    console.log('lsitData', listData);
  }, [listData]);

  const handleUploadComplete = () => {
    fetchPdfs(setListData, setFilteredData);
    setIsOpen(false);
  };

  return (
    <div id="patients" className="page-padding">
      <AdminArticlesAdd
        currentUser={currentUser}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        fbUser={fbUser}
        handleUploadComplete={handleUploadComplete}
      />
      <Heading
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        fontSize={{
          base: '4xl',
          md: '5xl',
        }}
      >
        <Flex gap={3} alignItems="center">
          Articles
        </Flex>

        <Input
          width={400}
          borderRadius={20}
          focusBorderColor="blue.400"
          placeholder="Search..."
          className={searchQuery?.length > 0 ? 'filled' : ''}
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
        <Button
          colorScheme="blue"
          onClick={() => setIsOpen(!isOpen)}
          leftIcon={<AddIcon h={4} w={4} color={'white.300'} />}
        >
          Add New
        </Button>
      </Heading>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              {userHeaders.map(l => (
                <Th>{l}</Th>
              ))}
            </Tr>
          </Thead>

          <Tbody>
            {filteredData?.map(l => (
              <Tr
                cursor="pointer"
                onClick={() => {
                  let selection = window.getSelection().toString();
                  if (selection.length <= 0) {
                    navigate(`/admin/articles/${l.id}`);
                  }
                }}
              >
                {/* <Td>{l?.id}</Td> */}
                <Td>{l?.title}</Td>
                <Td dangerouslySetInnerHTML={{ __html: l?.description }}></Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default AdminArticles;
