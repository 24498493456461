import {
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { addDocument, getDocuments } from '../../api/api';
import ReactQuill from 'react-quill';
import { serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { updateAdminUser } from '../../api/adminApi';
import { certifiedMembersType } from '../../data/certifiedmembers';

const memberships = [
  {
    id: 0,
    title: 'Basic',
  },
  {
    id: 1,
    title: 'Applied',
  },
  { id: 2, title: 'Certified' },
  { id: 3, title: 'Premium' },
];

function AdminUserEdit({ currentUser, isOpen, setIsOpen, user, setUser }) {
  const navigate = useNavigate();
  const toast = useToast();
  const [adminNote, setAdminNote] = useState(user?.adminNote || '');
  const [membership, setMembership] = useState(user?.membership || 'basic');
  const [bio, setBio] = useState(user?.bio || '');
  const [address, setAddress] = useState(user?.address || '');
  const [city, setCity] = useState(user?.city || '');
  const [country, setCountry] = useState(user?.country || '');
  const [email, setEmail] = useState(user?.email || '');
  const [displayName, setDisplayName] = useState(user?.displayName || '');
  const [employer, setEmployer] = useState(user?.employer || '');
  const [mobile, setMobile] = useState(user?.mobile || '');
  const [photoURL, setPhotoURL] = useState(user?.photoURL || '');
  const [type, setType] = useState(user?.type || '');
  const [specialty, setSpecialty] = useState(user?.specialty || '');
  const [state, setState] = useState(user?.state || '');
  const [zip, setZip] = useState(user?.zip || '');

  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setAdminNote(user?.adminNote || '');
    setMembership(user?.membership || null);
    setAddress(user?.address || '');
    setCity(user?.city || '');
    setCountry(user?.country || '');
    setDisplayName(user?.displayName || '');
    setEmployer(user?.employer || '');
    setMobile(user?.mobile || '');
    setPhotoURL(user?.photoURL || '');
    setType(user?.type || '');
    setSpecialty(user?.specialty || '');
    setState(user?.state || '');
    setZip(user?.zip || '');
    setEmail(user?.email || '');
    setBio(user?.bio || '');
  }, [user]);

  const handleFormSubmit = async () => {
    const userObject = {
      ...user,
      adminNote,
      membership,
      address,
      city,
      country,
      displayName,
      employer,
      mobile,
      photoURL,
      type,
      specialty,
      state,
      zip,
      bio,
    };

    toast({
      title: `Successfully saved changes.`,
      description: ``,
      status: 'success',
      duration: 9000,
      isClosable: true,
    });

    const newUser = await updateAdminUser(
      currentUser,
      user?.id || user?.uid,
      userObject,
      setUser
    );

    setIsOpen(false);
    window.location.reload();
  };

  return (
    <div id="labs-add">
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <>
              <form onSubmit={() => handleFormSubmit}>
                <Stack spacing={4}>
                  <Flex gap={4}>
                    <FormControl isRequired>
                      <FormLabel>Membership {membership}</FormLabel>
                      <Select
                        placeholder="Select Membership"
                        value={membership}
                        onChange={e => {
                          setMembership(e.target.value);
                        }}
                      >
                        {memberships.map(c => (
                          <option value={c.title}>{c.title}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Flex>
                  <Flex gap={3}>
                    <FormControl id="displayName" isRequired>
                      <FormLabel>Username</FormLabel>
                      <Input
                        value={displayName}
                        onChange={e => setDisplayName(e.target.value)}
                        placeholder="UserName"
                        _placeholder={{ color: 'gray.500' }}
                        type="text"
                        className={displayName?.length > 0 ? 'filled' : ''}
                      />
                    </FormControl>
                    <FormControl id="email" isDisabled>
                      <FormLabel>Email</FormLabel>
                      <Input value={email} type="text" className={'filled'} />
                    </FormControl>
                  </Flex>
                  <Flex gap={3}>
                    <FormControl id="type">
                      <FormLabel>Type</FormLabel>
                      <Select
                        placeholder="Select Type"
                        value={type}
                        onChange={e => {
                          setType(e.target.value);
                        }}
                      >
                        {certifiedMembersType.map(c => (
                          <option value={c.value} key={c.id}>
                            {c.value}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl id="specialty">
                      <FormLabel>Specialty</FormLabel>
                      <Input
                        value={specialty}
                        onChange={e => setSpecialty(e.target.value)}
                        placeholder="Specialty"
                        _placeholder={{ color: 'gray.500' }}
                        type="text"
                        className={specialty?.length > 0 ? 'filled' : ''}
                      />
                    </FormControl>
                  </Flex>
                  <Flex gap={3}>
                    <FormControl id="employer">
                      <FormLabel>Employer</FormLabel>
                      <Input
                        value={employer}
                        onChange={e => setEmployer(e.target.value)}
                        placeholder="Private Practice is Acceptable"
                        _placeholder={{ color: 'gray.500' }}
                        type="text"
                        className={employer?.length > 0 ? 'filled' : ''}
                      />
                    </FormControl>
                    <FormControl id="mobile" isRequired>
                      <FormLabel>Mobile</FormLabel>
                      <Input
                        value={mobile}
                        onChange={e => setMobile(e.target.value)}
                        placeholder="Mobile Phone Number"
                        _placeholder={{ color: 'gray.500' }}
                        type="text"
                        className={mobile?.length > 0 ? 'filled' : ''}
                      />
                    </FormControl>
                  </Flex>
                  <FormControl id="photoURL">
                    <FormLabel>Photo Url</FormLabel>
                    <Input
                      value={photoURL}
                      onChange={e => setPhotoURL(e.target.value)}
                      placeholder="Add a URL to the photo"
                      _placeholder={{ color: 'gray.500' }}
                      type="text"
                      className={photoURL?.length > 0 ? 'filled' : ''}
                    />
                  </FormControl>
                  <FormControl id="address">
                    <FormLabel>Address</FormLabel>
                    <Input
                      value={address}
                      onChange={e => setAddress(e.target.value)}
                      placeholder="Address"
                      _placeholder={{ color: 'gray.500' }}
                      type="text"
                      className={address?.length > 0 ? 'filled' : ''}
                    />
                  </FormControl>
                  <Flex gap={3}>
                    <FormControl id="city">
                      <FormLabel>City</FormLabel>
                      <Input
                        value={city}
                        onChange={e => setCity(e.target.value)}
                        placeholder="City"
                        _placeholder={{ color: 'gray.500' }}
                        type="text"
                        className={city?.length > 0 ? 'filled' : ''}
                      />
                    </FormControl>

                    <FormControl id="state">
                      <FormLabel>State</FormLabel>
                      <Input
                        value={state}
                        onChange={e => setState(e.target.value)}
                        placeholder="State"
                        _placeholder={{ color: 'gray.500' }}
                        type="text"
                        className={state?.length > 0 ? 'filled' : ''}
                      />
                    </FormControl>
                  </Flex>
                  <Flex gap={3}>
                    <FormControl id="zip">
                      <FormLabel>Zip</FormLabel>
                      <Input
                        value={zip}
                        onChange={e => setZip(e.target.value)}
                        placeholder="Zip"
                        _placeholder={{ color: 'gray.500' }}
                        type="text"
                        className={zip?.length > 0 ? 'filled' : ''}
                      />
                    </FormControl>
                    <FormControl id="country">
                      <FormLabel>Country</FormLabel>
                      <Input
                        value={country}
                        onChange={e => setCountry(e.target.value)}
                        placeholder="Country"
                        _placeholder={{ color: 'gray.500' }}
                        type="text"
                        className={country?.length > 0 ? 'filled' : ''}
                      />
                    </FormControl>
                  </Flex>
                  <FormControl>
                    <FormLabel>Bio</FormLabel>
                    <ReactQuill theme="snow" value={bio} onChange={setBio} />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Admin Notes</FormLabel>
                    <ReactQuill
                      theme="snow"
                      value={adminNote}
                      onChange={setAdminNote}
                    />
                  </FormControl>
                </Stack>
                <Button
                  fontFamily={'heading'}
                  mt={4}
                  w={'full'}
                  bgGradient="linear(to-r, red.400,pink.400)"
                  color={'white'}
                  _hover={{
                    bgGradient: 'linear(to-r, red.400,pink.400)',
                    boxShadow: 'xl',
                  }}
                  onClick={() => handleFormSubmit()}
                >
                  Submit
                </Button>
              </form>
              <br />
            </>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default AdminUserEdit;
