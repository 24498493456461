import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import {
  getDocuments,
  getRootDocument,
  getRootDocuments,
  updateRootDocument,
  addDocumentToRoot,
} from './api';
import { fireStorage, fireStore } from './fire';
import { getAuth } from 'firebase/auth';
import { arrayUnion, doc, setDoc, updateDoc } from 'firebase/firestore';

export const getAllUsers = async (currentUser, setAdminData) => {
  if (currentUser?.type !== 'admin') return;
  const data = await getRootDocuments(currentUser, 'users');

  const responses = await Promise.all(
    data.map(async d => ({
      ...d,
      id: d.uid || d.id,
      labs: (await getLabs(d.id)) || [],
      patients: (await getPatients(d.id)) || [],
    }))
  );

  setAdminData(responses);

  /* data.forEach(async (d, index) => {
    const dd = {
      ...d,
      id: d.uid || d.id,
      labs: (await getLabs(d.id)) || [],
      patients: (await getPatients(d.id)) || [],
    };
    await completedData.push(dd);
    if (index === data.length - 1) {
      setAdminData(completedData);
    }
  }); */
};

export const uploadPdf = async (
  currentUser,
  title,
  description,
  pdfFile,
  onProgress,
  onError,
  handleUploadComplete
) => {
  if (currentUser?.type !== 'admin') {
    console.error('User is not authorized to upload PDFs.');
    onError('You are not authorized to upload PDFs.');
    return;
  }

  const studyId = uuidv4();

  try {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      throw new Error('User not authenticated');
    }

    const idToken = await user.getIdToken(true);

    const storageRef = ref(fireStorage, `pdfs/${pdfFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, pdfFile, {
      customMetadata: {
        firebaseIdToken: idToken,
      },
    });

    uploadTask.on(
      'state_changed',
      snapshot => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress(progress);
      },
      error => {
        console.error('Upload  error:', error);
        onError(error.message);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(storageRef);

          // 1. Validate studyId (if needed)
          if (typeof studyId !== 'string' || studyId.trim() === '') {
            throw new Error('Invalid studyId provided.');
          }

          // 2. Get the Firestore document reference
          const studyRef = doc(fireStore, 'studies', studyId);

          // 3. Create a new document with the PDF URL
          await setDoc(studyRef, {
            // Use setDoc to create a new document
            pdfs: arrayUnion({
              title: title,
              description,
              url: downloadURL,
              id: studyId,
            }),
          });

          // 4. Callback to update the list with the new data and dismiss the add modal
          handleUploadComplete();
        } catch (error) {
          console.error('Error saving URL to Firestore:', error);
          onError('Error saving PDF data.');
        }
      }
    );
  } catch (error) {
    console.error('Error uploading PDF:', error);
    onError('Error uploading PDF.');
  }
};

export const getCertifiedMemberProfiles = async (currentUser, setter) => {
  if (!currentUser) return;
  const res = await getRootDocuments(currentUser, 'users');
  const certifiedOnly = res.filter(
    d => d.membership === 'Certified' && !d.ignore
  );
  const data = certifiedOnly.map(d => ({
    cv: d.cv,
    address: d.address,
    city: d.city,
    state: d.state,
    type: d.type,
    specialty: d.specialty,
    id: d.id,
    photoURL: d.photoURL || d.photoURL,
    employer: d.employer,
    displayName: d.displayName,
  }));

  setter(data);
};

export const getLabs = async id => {
  const labs = await getDocuments(id, 'labs');
  return labs;
};

export const getPatients = async id => {
  const patients = await getDocuments(id, 'patients');
  return patients;
};

export const getAllLabs = async (currentUser, setter) => {
  if (currentUser?.type !== 'admin') return;
  const data = await getRootDocuments(currentUser, 'users', setter);
};

export const getUser = async (currentUser, id, setAdminUser) => {
  if (currentUser?.type !== 'admin') return;
  const data = await getRootDocument(currentUser, 'users', id, setAdminUser);
};

export const updateAdminUser = async (currentUser, id, docObject, setter) => {
  if (currentUser?.type !== 'admin') return;
  const data = await updateRootDocument(
    currentUser,
    'users',
    docObject,
    id,
    setter
  );
};
