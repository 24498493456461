import React, { useEffect, useState } from 'react';
import {
  Button,
  Heading,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from '@uidotdev/usehooks';

const headers = ['id', 'dob', 'last name', 'first name', 'email', 'provider'];

function AdminPatients({ data }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState(null);
  const debouncedSearchTerm = useDebounce(searchQuery, 300);
  const [flatPatients, setFlatPatients] = useState([]);

  useEffect(() => {
    const searchHN = async () => {
      let results = [];
      if (debouncedSearchTerm) {
        const array = flatPatients.map(d => ({
          ...d,
          dataString: Object.values(d).join(),
        }));
        const fArray = array.filter(a =>
          a.dataString.toLowerCase().includes(searchQuery?.toLowerCase())
        );
        setFilteredData(fArray);
      } else {
        setFilteredData(flatPatients);
      }
    };
    searchHN();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (!data) return;
    const fPatients = data
      .map(d => {
        if (d.patients) {
          console.log(d.patients);
          return d.patients.map(dd => ({
            ...dd,
            providerId: d.id,
            providerName: `${d.displayName}`,
          }));
        }
      })
      .flat();
    setFlatPatients(fPatients.filter(f => f));
    setFilteredData(fPatients.filter(f => f));
  }, [data]);

  return (
    <div id="patients" className="page-padding">
      <Heading
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        fontSize={{
          base: '4xl',
          md: '5xl',
        }}
      >
        Patients
        {/* <Button colorScheme="blue" onClick={() => setIsOpen(true)}>
          Add Patient
        </Button> */}
        <Input
          width={400}
          borderRadius={20}
          focusBorderColor="blue.400"
          placeholder="Search..."
          className={searchQuery?.length > 0 ? 'filled' : ''}
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
      </Heading>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              {headers.map(l => (
                <Th>{l}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {filteredData?.map(l => (
              <Tr>
                <Td
                  cursor="pointer"
                  onClick={() => {
                    let selection = window.getSelection().toString();
                    if (selection.length <= 0) {
                      navigate(`/admin/user/${l?.providerId}/patient/${l?.id}`);
                    }
                  }}
                  className="id"
                >
                  <b>{l?.id}</b>
                </Td>
                <Td className="dob">{new Date(l?.dob).toLocaleDateString()}</Td>
                <Td className="firstName">{l?.lastName}</Td>
                <Td className="firstName">{l?.firstName}</Td>
                <Td className="email">{l?.email}</Td>
                <Td
                  cursor="pointer"
                  className="email"
                  onClick={() => {
                    let selection = window.getSelection().toString();
                    if (selection.length <= 0) {
                      navigate(`/admin/user/${l?.providerId}`);
                    }
                  }}
                >
                  <b>{l?.providerName}</b>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default AdminPatients;
