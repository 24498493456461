import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import AsSeenIn from '../../Layouts/AsSeenIn/AsSeenIn';
import { useNavigate } from 'react-router-dom';

function Press() {
  const navigate = useNavigate();
  return (
    <div>
      <Container
        className="legal"
        justifyContent="center"
        maxW={'7xl'}
        mt={50}
        mb={50}
      >
        <AsSeenIn />
        <Box p={{ base: 0, md: 16 }} mb={16}>
          <Heading align="center" size="2xl" mb={8}>
            Press Inquries
          </Heading>
          <Flex
            direction="column"
            gap={8}
            align={'center'}
            textAlign={'center'}
          >
            For press inquiries, please reach out to us. We welcome your
            questions and requests, and we're here to provide you with the
            information you need to tell our story accurately and
            comprehensively. We are readily available to assist you in gaining
            deeper insights into our work, our mission, and the exciting
            developments within our field. Contact us today to access the latest
            news, interviews, and expert commentary. We look forward to
            collaborating with you and sharing our journey of discovery with the
            world.
            <Button width={300} onClick={() => navigate('/contact')}>
              Contact Us
            </Button>
          </Flex>
        </Box>
      </Container>
    </div>
  );
}

export default Press;
