export const carriers = [
  { name: 'AT&T', extension: '@txt.att.net' },
  { name: 'T-Mobile', extension: '@tmomail.net' },
  { name: 'Verizon', extension: '@vtext.com' },
  { name: 'Sprint', extension: '@messaging.sprintpcs.com' },
  { name: 'Xfinity Mobile', extension: '@vtext.com' },
  { name: 'Virgin Mobile', extension: '@vmobl.com' },
  { name: 'Tracfone', extension: '@mmst5.tracfone.com' },
  { name: 'Simple Mobile', extension: '@smtext.com' },
  { name: 'Mint Mobile', extension: '@mailmymobile.net' },
  { name: 'Red Pocket', extension: '@vtext.com' },
  { name: 'Metro PCS', extension: '@mymetropcs.com' },
  { name: 'Boost Mobile', extension: '@sms.myboostmobile.com' },
  { name: 'Cricket', extension: '@sms.cricketwireless.net' },
  { name: 'Republic Wireless', extension: '@text.republicwireless.com' },
  { name: 'Google Fi (Project Fi)', extension: '@msg.fi.google.com' },
  { name: 'U.S. Cellular', extension: '@email.uscc.net' },
  { name: 'Ting', extension: '@message.ting.com' },
  { name: 'Consumer Cellular', extension: '@mailmymobile.net' },
  { name: 'C-Spire', extension: '@cspire1.com' },
  { name: 'Page Plus', extension: '@vtext.com' },
];
