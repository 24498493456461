import { Container, Heading } from '@chakra-ui/react';
import React from 'react';

function About() {
  return (
    <div>
      <Container
        className="legal"
        justifyContent="center"
        maxW={'7xl'}
        mt={50}
        mb={50}
        py={{ base: 5, sm: 10, lg: 15 }}
      >
        <Heading
          color={'gray.800'}
          lineHeight={1.1}
          display="flex"
          fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
        >
          About Us
        </Heading>
        <p>
          Biome Squad™ is an exceptional consortium of brilliant minds, a
          harmonious fusion of physicians and scientists driven by a shared
          purpose: to unravel the mysteries of the vast and intricate microbial
          world that resides within us. With an unwavering commitment to
          understanding the profound significance of the microbiome, they embark
          on a captivating journey, exploring the hidden intricacies that define
          our health and well-being.
        </p>
        <p>
          At the heart of Biome Squad™ is a steadfast vision to unearth the
          unvarnished truth about the microbiome, a universe of trillions of
          bacteria that calls the human body home. These passionate individuals
          recognize that within this microscopic realm, an immense network of
          symbiotic relationships, genetic diversity, and untold potential
          thrives, waiting to be deciphered.
        </p>
        <p>
          This dedicated team comprises esteemed physicians and scientists who
          bring a wealth of experience, knowledge, and expertise to the table.
          They share a common belief that the microbiome is a key player in
          shaping our health, influencing everything from digestion and
          metabolism to immune system function and mental well-being. Their
          collaboration is characterized by a deep respect for the intricate
          balance between human hosts and their microbial companions.
        </p>
        <p>
          Biome Squad™ is not merely an assembly of professionals; it is a
          close-knit community of visionaries who see the microbiome as an
          untapped reservoir of answers. They are united in their commitment to
          uncovering how the microbiome impacts not only the individual but also
          the broader spectrum of human health. Through rigorous research,
          cutting-edge technology, and a tireless quest for knowledge, they seek
          to illuminate the connections between these invisible microorganisms
          and the very essence of our existence.
        </p>
        <p>
          With unyielding determination and boundless curiosity, Biome Squad™
          stands at the forefront of microbiome research. Their collective
          passion is the catalyst for groundbreaking discoveries and innovative
          insights into the complexities of this unseen world. As they delve
          deeper into the intricacies of the microbiome, Biome Squad™ is poised
          to redefine our understanding of health and, in doing so, rewrite the
          future of medicine and wellness.
        </p>
      </Container>
    </div>
  );
}

export default About;
