import { Container, Heading } from '@chakra-ui/react';
import React from 'react';
import './Legal.scss';

function Privacy() {
  return (
    <Container
      className="legal"
      justifyContent="center"
      maxW={'7xl'}
      mt={50}
      mb={50}
      py={{ base: 5, sm: 10, lg: 15 }}
    >
      <Heading
        color={'gray.800'}
        lineHeight={1.1}
        display="flex"
        fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
      >
        Privacy Policy
      </Heading>
      (Effective: October 1, 2023) ‍
      <p>
        We Value and Respect Your Privacy. The Biome Squad acknowledges and
        appreciates that you’ve decided to share important data and information
        with us. We take the stewardship of your data and information very
        seriously. To that end, The Biome Squad strives to maintain a Privacy
        Policy that is open, clear, and fair. This Policy governs how The Biome
        Squad collects, stores, and uses your PII and Protected Health
        Information (as defined below), as well as other data and information
        arising out of and/or relating to you and/or your use of our Services –
        which include your use of the website www.thebiomesquad.com (the “Site”)
        and any other technologies, features, and content we offer. Please note
        that The Biome Squad may also provide you with “just-in-time”
        disclosures, supplemental terms and/or clarifications, further options,
        and additional information pertaining to our collection, storage, and
        usage of PII, Protected Health Information (as defined below), and other
        data and information. Please review this Privacy Policy very carefully.
        Our Terms, linked here, incorporate this Privacy Policy by reference in
        its entirety. Capitalized terms set forth in the Terms, and their
        respective definitions, apply equally to this Privacy Policy. By
        agreeing to our Terms, you accept all terms and conditions of this
        Privacy Policy, to the fullest extent permitted by applicable law. If
        you do not accept this Privacy Policy, in its entirety, you must stop
        using our Services, terminate your acceptance of our Terms, and cease
        all further use, effective immediately. Please contact
        legal@thebiomesquad.com if anything in here does not make sense or seem
        right to you; we are always open to feedback around our privacy policies
        and practices. 1. Protected Health Information When you set up an
        Account with The Biome Squad, you are creating a direct customer
        relationship with us that enables you to access and/or utilize our
        Services. As part of that relationship, you provide information to The
        Biome Squad, including but not limited to, first and last name, email
        address, date of birth, mailing address and zip code, and certain
        transactional information that we do not consider to be Protected Health
        Information, as defined below. That said, there may be situations when
        you use certain components of The Biome Squad’s Services, and the health
        and/or medical information that we receive about you is protected under
        applicable laws. While The Biome Squad is not a health care provider,
        and is not a covered entity under the Health Insurance Portability and
        Accountability Act of 1996, Public Law 104-191, and its related
        regulations and amendments from time to time (collectively, “HIPAA”), in
        some cases it may be a “business associate” to covered entities under
        HIPAA, and as such we may have certain federal, state and contractual
        restrictions on how we can use your health or medical information
        (collectively, your “Protected Health Information” or “PHI”). With
        respect to any PHI that we receive from covered entities, The Biome
        Squad will comply with its obligations under HIPAA as a business
        associate. Indeed, when The Biome Squad acts as a business associate of
        a covered entity that requires us to maintain the privacy and security
        of PHI that we create, receive or maintain on behalf of said covered
        entity, we may only use or disclose such PHI as permitted by law and
        specified by a Business Associate Agreement with the covered entity (a
        “BAA”) that restricts the way we can use and disclose PHI; such
        restrictions may, for example, limit the usage and disclosure of PHI to
        the provision of services to the covered entity, the management and
        administration of our Services and company, and the fulfillment of legal
        obligations. Notwithstanding anything herein to the contrary, you
        acknowledge and agree that:
      </p>
      <p>
        · To the extent authorized by a covered entity, The Biome Squad may
        de-identify and/or pseudonymize your PHI, in compliance with applicable
        law, so that it does not identify you personally. The Biome Squad may
        also create compilations and aggregated data sets that exclude PII and
        pool your PHI (including without limitation Lab Results) with that of
        other individuals and/or present your de-identified PHI alongside that
        of other individuals.
      </p>
      <p>
        · The Biome Squad reserves the right to use de-identified PHI,,
        pseudonymized PHI and/or compilations and aggregated data sets
        containing PHI without PII for its own analysis and/or publication and
        any other lawful purpose it deems fit, including without limitation for
        sale to market research firms, advertisers, healthcare systems and
        providers, medical practices governmental institutions, universities,
        and research firms, as well as for research and development pertinent to
        healthcare, wellness, medical technology, and related services,
        products, and offerings.
      </p>
      <p>
        · To de-identify any PHI, The Biome Squad will follow either the HIPAA
        expert determination method or the HIPAA safe harbor method. Please
        note: While we will comply with HIPAA as to any PHI that we receive from
        a covered entity when The Biome Squad is acting as their business
        associate pursuant to a BAA, we are NOT required to comply with HIPAA
        for any information that we receive from our customers like you
        directly, as it does not constitute PHI under applicable laws. We may
        therefore protect, use, and disclose PII that you provide us as
        specified in this Privacy Policy. 2. Information That The Biome Squad
        Collects and Stores The Biome Squad may collect and store PII that
        identifies, relates to, describes, references, is reasonably capable of
        being associated with, or could reasonably be linked, directly or
        indirectly, with you as a particular consumer, or your household or
        device. Such PII does not include: • Publicly available information from
        government records; • De-identified or aggregated consumer information;
        or • PHI or medical information covered by the California
        Confidentiality of Medical Information Act. We collect and store PII
        when you visit our Site, use our Services, create an Account with us,
        and submit PII to us. In particular, we have collected within the last
        twelve (12) months, and may continue to collect, the following
        categories of PII:
      </p>
      <p>
        · Information you provide to us. This includes identifiers such as your
        name, address, birth date, email address, phone number, and other
        similar information. o For example, when you use our Services, we may
        collect and store your name, location, email, language, IP address (the
        internet address of your computer), entry page, and who referred you. We
        may also automatically collect and store information sent by your mobile
        device or computer when you use our Services, such as device
        information, such as your hardware model, platform, IP address, unique
        device identifiers, and other information like your browser type,
        operating system, and your website and mobile app usage. o Additionally,
        when you sign up for an Account with The Biome Squad, we may collect and
        store your name, address, phone number, title, birth date, sex, and
        gender, as well as other information, such as your occupation, industry,
        and personal interests. o From time-to-time, we may also invite you to
        participate in surveys or promotional activities. When you participate
        in these sorts of activities, we may request, collect, and store such
        PII as your name, email address, phone number, and workplace, as well as
        information regarding what Services you are interested in receiving.
        Depending on the nature of the activity, we use this information to
        follow-up with the participants, or, if applicable, to notify winners
        and award prizes. o Many of our Services may require you to remit
        Payment Information, which we collect, such as your bank account number,
        credit card number, debit card number, and other financial information.
      </p>
      <p>
        · Communications from you. When you use our Services (for example, when
        you make use of our Site), complete electronic forms, or contact us by
        online chat, email, phone, or text, we may automatically collect and
        store certain information about you and the activity in which you
        engaged, for example: your name, address, the nature of your
        communication, the purpose of the interaction, a record of any
        correspondence we have with you, and the action we took in response to
        your inquiry or request.
      </p>
      <p>
        · Information from our Affiliates and partners. We may also receive and
        store your PII from other The Biome Squad Affiliates, our business
        partners, service providers and other third parties in connection with
        one or more business purposes, including making our Services or their
        services available to you. We may also obtain PII from the following
        categories of sources:
      </p>
      <p>
        · Cookies. Cookies are small files that a website uses to identify your
        computer, remember information about you, and track you as you browse.
        The Biome Squad uses first-party and third-party cookies to ensure the
        proper working order of our digital technologies, remember your
        information, track your online interactions and experiences with The
        Biome Squad and across third-party digital properties, deliver
        personalized content, and generally facilitate and enhance your
        experience of our Services. The Biome Squad also reserves the right to
        use Flash cookies (AKA local shared objects or “LSOs”) – which are
        similar to browser cookies but capable of storing larger chunks of data
        and necessary to facilitate the functionality of Adobe Flash – to
        collect and store PII about you, your preferences, and your use and
        experience of our Services. Our Services may use cookies and other
        technologies to collect and store information such as your name,
        language preference, mailing address, email address, phone number, login
        credentials, IP address, and Site settings and preferences.
      </p>
      <p>
        · Tracking and/or Analytics Services. To gauge the effectiveness of our
        Site, we may collect and store PII and other information about our Site
        users using tracking and/or analytics services. This information may
        include, among other things, data regarding your browsing history
        (before, during, and after visiting our Site), your activity on the Site
        and/or a mobile application, your browser cookies. For example, we may
        make use of web beacons (AKA pixel tags, clear GIFs). Beacons are small
        objects that are embedded in an image on a website; they can transmit
        information directly to The Biome Squad, or to another person or entity
        of our designation. The Biome Squad may use one or more external
        providers to monitor and analyze our digital properties and online
        technologies, including without limitation the Site. We currently use
        Google Analytics to monitor and report on our Site traffic. You can
        review Google’s privacy policy at https://policies.google.com/privacy.
        Please note that Google offers a browser add-on that enables website
        visitors to prevent their data from being used by Google Analytics; you
        can learn more at http://www.tools.google.com/dlpage/gaoptout.
      </p>
      <p>
        · Third Party Credentials. If you use login credentials from a non-The
        Biome Squad service (e.g., Facebook, Google) to login or provide other
        data and/or information in connection with our Services, The Biome Squad
        reserves all rights to collect data and information from that service –
        but only to the extent you have granted permission to share such data
        and information, and only in compliance with such permission. This
        paragraph embraces, without limitation the collection of your social
        media account ID, contact/friend list, online profile, and profile
        picture and photos, as well as the collection of data information
        concerning your online groups, user history information, and application
        settings and preferences.
      </p>
      <p>
        · Other. The Biome Squad reserves all rights to collect any other PII or
        data you input, create, upload, export, share, generate and/or otherwise
        provide in connection with our Services. You should expect, without
        limitation, that The Biome Squad will collect your PII and your User
        Content to the extent you input, create, upload, export, share, generate
        and/or otherwise provide it. Please note that many phones and browsers
        enable you to restrict tracking technologies, deactivate location
        sharing, and limit your exposure to interest-based advertisements. Your
        browser may also offer you the option of refusing and/or removing some
        or all browser cookies. For more information on cookies – and how to
        utilize your browser to block cookies, please visit
        http://www.allaboutcookies.org. For more information on how to opt-out
        of cookies with participating ad networks, please visit
        http://www.aboutads.info/choices. The Biome Squad does not support “Do
        Not Track” requests. To determine if any of providers or services honor
        “Do Not Track,” please carefully review their privacy policies. You can
        find many technology options to help you set boundaries around the use
        of your PII for targeted ads purposes. For example, AppChoices
        (http://youradchoices.com/appchoices) enables you to opt-out of
        interest-based mobile advertisements, while the Digital Advertising
        Alliance (optout.aboutads.info) created a tool to opt-out of targeted
        ads on websites. Platforms like Google (https://adssettings.google.com)
        and Facebook (https://www.facebook.com/about/ads) also contain feature
        that help you opt-out of unwanted interest-based ads. Browser and mobile
        settings can often be utilized to limit the transmission of Device ID
        information and/or third-party cookies in connection with interest-based
        advertisements. 3. Use of Information. We Do Not Sell Your PII. We are
        not in the business of selling your PII and have not sold any PII in the
        preceding twelve (12) months. We will only use the PII we collect for
        one or more of the following purposes:
      </p>
      <p>
        · To provide you with our Services. We may use your PII to fulfill or
        meet the reason you provided us with the PII. For example, we may use
        general location information to improve and personalize our Services to
        you, such as using your PII to respond to an inquiry from you or
        providing location-relevant information and Services to you.
      </p>
      <p>
        · For our operations and administrative purposes. We may use your PII
        for the purposes of furthering The Biome Squad’s business, including
        without limitation creating, operating, delivering, maintaining, and
        improving our content, products, and other Services. We may monitor how
        you and others use our Services including, without limitation,
        monitoring time spent using our Services, Site pages visited, and
        content viewed. We may use your PII to help maintain the safety,
        security, and integrity of our Products, Services, assets, operations,
        and business. We may also use your PII in connection with testing,
        research, and analysis, including to develop and improve our Services.
        For example, The Biome Squad intends to keep track of how users connect
        with and use our digital offerings – it helps us learn, grow, and
        provide the best possible experience for our customers – and we may
        therefore use your PII to see what you view, when and for how long you
        view it, what you click and when you click it, which features you
        utilize and to what extent, which products or services interest or
        disinterest you, which products or services you decide to add to a cart,
        abandon, or purchase, and what you do before and after you use our
        Services. Additionally, The Biome Squad may use your PII from
        communications it has with you (including, without limitation, any phone
        calls and/or voice messages, text/SMS messages, form submissions, survey
        responses, and emails from you) for the purposes of offering and
        improving customer service, assuring quality control, establishing
        evidence in a dispute, and/or enforcing our Terms. We may further use
        your PII to contact you regarding purchase confirmations, matters
        concerning the security of your PII, and other important service
        announcements; for these types of “essential communications,” we do not
        offer the option opt-out.
      </p>
      <p>
        · For marketing communication purposes. We may use your PII to send you
        updates regarding existing products and services, information about new
        products and services, upcoming events, surveys, and other announcements
        and inquiries. If you do not wish to receive such communications via
        email can choose to opt-out. You may unsubscribe from our marketing and
        promotional emails at any time by clicking “Unsubscribe” at the bottom
        of the email. You may email legal@thebiomesquad.com with the subject
        line “Interest Based Ads” if you want us to stop using information that
        we collect about you to deliver targeted advertisements, or if you want
        to stop receiving marketing and promotional emails altogether. You
        acknowledge and agree that any request to limit or stop marketing and
        promotional communications may require a reasonable length of time to
        process before taking effect; you furthermore acknowledge and agree that
        you may continue to receive marketing and promotional emails from The
        Biome Squad while a request is processing. Please note that The Biome
        Squad may send you marketing and advertising messages on behalf a third
        party (including subject to a paid arrangement); provided, under such a
        circumstance, The Biome Squad will not disclose your PII to said third
        party advertiser or marketer.
      </p>
      <p>
        · To comply with a legal or regulatory requirement or to protect
        ourselves. Our Services are subject to certain laws and regulations
        which may require us to process your PII. For example, we may process
        your PII to comply with applicable laws or regulations, or as necessary
        to manage risk as required under applicable law.
      </p>
      <p>
        · To aggregate and/or de-identify your Lab Results and PII. The Biome
        Squad may de-identify and/or pseudonymize any information and data
        provided and/or generated in connection with your use of our Services
        (including without limitation your Lab Results and other PII), in
        compliance with applicable law. The Biome Squad may also create
        compilations and aggregated data sets that exclude PII and pool your
        other data and information (including without limitation Lab Results)
        with that of other members and/or present your de-identified data and
        information alongside that of other members. In accordance with
        applicable law, The Biome Squad reserves the right to use such
        de-identified, pseudonymized information and/or compilations and
        aggregated data for its own analysis and/or publication and any other
        lawful purpose it deems fit.
      </p>
      <p>
        · Other purposes, including but not limited to: o For conducting
        research, studying and inferring factual information about you and other
        members of the The Biome Squad community; o As described to you when
        collecting your PII; or o As otherwise permitted under applicable law.
        4. Sharing Information. We may disclose your PII to a third party in the
        following situations:
      </p>
      <p>
        · As Your Agent to Third-Party Providers. In order to act as your agent
        and thereby help facilitate your receipt of diagnostic testing, results
        reporting, and/or medical care from a third-party provider, The Biome
        Squad will need to share and disclose your PII to such third parties.
        These third parties are only permitted to share, store and/or use PII
        for contracted business purposes.
      </p>
      <p>
        · With our Affiliates, business partners, and service providers. We may
        share PII with The Biome Squad Affiliates, our business partners,
        service providers and other third parties to enable them to perform
        functions on our behalf. These third parties are only permitted to
        share, store and/or use PII for contracted business purposes. For
        example, we may share your PII with email and marketing outreach
        services, in order to provide you with personalized experiences,
        targeted offerings, and relevant opportunities. As another example, The
        Biome Squad may share your PII with third-party payment vendors in order
        to process Payment Methods in connection with the purchase of Services
        and/or third-party services facilitated by The Biome Squad. (Please
        Note: excepting details of your transaction (e.g., time of transaction,
        amount) and the name on the card, our third-party payment vendors
        typically do not collect or store Payment Information.)
      </p>
      <p>
        · Subject to de-identification or aggregation. The Biome Squad reserves
        the right to sell, share, and/or otherwise disclose de-identified,
        pseudonymized information and/or compilations and aggregated data not
        bearing PII for any lawful purpose it deems fit, including, without
        limitation: (a) for publication, (b) for sale to market research firms,
        advertisers, healthcare systems and providers, medical practices
        governmental institutions, universities, and research firms, and (c) for
        research and development pertinent to healthcare, wellness, medical
        technology, and related services, products, and offerings.
      </p>
      <p>
        · In the event of a merger, sale, or other asset transfer.
        Notwithstanding anything herein to the contrary, we may disclose or
        transfer your PII to assist with the evaluation of, or in connection
        with, a merger, divestiture, restructuring, reorganization, dissolution,
        or other sale or transfer of some or all our assets, whether as a going
        concern or as part of bankruptcy, liquidation, or similar proceeding, in
        which PII held by us about you may be among the assets transferred.
      </p>
      <p>
        · To comply with a legal or regulatory requirement, and to protect,
        defend, and enforce rights, property, and operations. We may also share
        your PII when we believe that such action is necessary to: o Fulfill an
        enforceable government request; o Conform with the requirements of the
        law or legal process; o Protect, defend, and/or enforce our legal
        rights, operations, and/or property (including without limitation as
        they relate to our Services and Products, such as our Site), as well as
        the rights, operations, and/or property of our Affiliates, business
        partners, service providers and other third parties; o Protect, defend,
        and/or enforce your legal rights and/or the legal rights of our Members,
        other persons using our Services, and/or any other third-party; and/or o
        Protect your health and safety and/or the health and safety of our
        Members, other persons using our Services, and/or the general public.
      </p>
      <p>
        · Other purposes. o As otherwise permitted under applicable law; or o As
        you request to persons and/or entities of your designation who are
        relevant to your care. For example, you may wish to share your Lab
        Results with your general practitioner, your specialist, and/or your
        provider’s health system. To the extent The Biome Squad helps facilitate
        such a request, you agree that The Biome Squad has no liability,
        responsibility, or obligation with respect to such third-party policies
        and practices relating to your PII and/or other data and information. 5.
        Storage, Retention & Continued Use of Your Information. The Biome Squad
        retains your PII for as long as necessary to provide you with the
        Services, as required by law, or to ensure that we can perform
        legitimate business functions, such as accounting for tax obligations or
        audits for security purposes, as well as monitoring and understanding
        our users and their interactions with our technologies. To the fullest
        extent permitted by applicable law, The Biome Squad reserves all rights
        to delete, modify, remove, and/or destroy any data and/or information we
        collect – at any time, for any reason or none, without notice or cause;
        we maintain no obligation to preserve your data or information,
        including without limitation PII. 6. Third Parties. Our Services may
        contain links to other sites, applications, and media properties that
        are owned and/or operated by third parties. You may also see our logo on
        external websites where we have partnered with third parties to offer
        you additional information and services. We are not liable or
        responsible for the content, privacy policies and practices, security
        policies and practices, or actions or omissions of those any third-party
        with respect to your PII. Therefore, we strongly encourage you to look
        for and carefully review the privacy policy and terms of service (or
        equivalent terms) for each website, application, and media property that
        you visit via a link from our Services.; your decision to consent to
        such is strictly between you and said third party. You acknowledge and
        agree that neither The Biome Squad nor any of our Affiliates is
        responsible for the acts and/or omissions of any third-party with
        respect to the collection, storage, and/or usage your PII To the maximum
        extent legally permitted, you agree to release and hold The Biome Squad
        and our Affiliates, harmless in connection with any losses, liabilities,
        claims, controversies, lawsuits, disputes, or matters arising out of
        and/or relating to the unwanted access, collection, storage, and/or
        usage of your PII, regardless of prior notice or warning. Furthermore,
        to the maximum extent legally permitted, you agree to defend and
        indemnify The Biome Squad and our Affiliates and hold The Biome Squad
        and our Affiliates harmless in connection with any third-party claims,
        controversies, lawsuits, liabilities, decisions, damages, awards,
        amounts, costs, fees, and/or penalties arising out of and/or relating to
        your actions and/or omissions in connection with your PII arising out,
        relating to, transmitted and/or communicated in connection with, and/or
        otherwise concerning our Services. To the fullest extent allowed by law,
        you agree that the terms specified in this paragraph apply regardless of
        whether the matter at issue originated from The Biome Squad’s sole
        negligence, and regardless of prior notice or warning; however, this
        paragraph shall not apply to matters directly resulting from any
        recklessness and willful misconduct of The Biome Squad. 7. Data
        Security. The Biome Squad uses certain safeguards to reasonably protect
        the security and integrity of your PII. In order to enhance the
        protection of your PII, The Biome Squad will collect PII via a secure
        processing server (SSL-protected, with a valid SSL-certificate or other
        commercially reasonable encryption technology) and/or a third-party
        payment provider with appropriate security and confidentiality
        procedures. If you transact with us via direct payment gateway, your
        Payment Information will be encrypted through Payment Card Industry Data
        Security Standard (PCI-DSS). However, even with these
        commercially-reasonable efforts, no data transmission over the internet,
        mobile networks, wireless transmission or electronic storage of
        information is completely secure. We cannot ensure the security of any
        PII you transmit to us, and you use our Services and provide us with
        your PII, inclusive of your Payment Information, at your own risk. The
        Biome Squad and its Affiliates are not responsible for circumvention of
        any privacy settings or security measures contained on the Site or any
        of our other properties. If you have not already done so, please
        carefully review all disclaimers, limitations, and indemnification
        obligations set forth in our Terms; these terms apply with equal force
        and effect to this Privacy Policy, to the maximum extent permitted by
        applicable law. 8. Changes to Our Privacy Policy. The Biome Squad
        reserves the right – in our sole discretion, at any time, for any reason
        – to make changes to this Privacy Policy and the manner in which we
        collect, store, and use your data and information. The Biome Squad
        promises to make a reasonable effort to notify you of any changes to
        this Privacy Policy, including without limitation by banner
        notification, pop-up, email, and/or text/SMS message, insofar as you
        furnish such information and maintain it as current and accurate. You
        acknowledge and agree that your continued use of our Services
        constitutes your full acceptance of any changes to this Privacy Policy.
        9. Children. The Biome Squad and its Services are not intended for
        children under the age of eighteen (18) years and we do not knowingly
        collect information from such persons. By using our Services, you
        represent that you are at least eighteen (18) years of age. You
        represent that you are of sound mind to understand and accept our Terms,
        including without limitation this Privacy Policy. If you think a minor
        has accessed our Services or otherwise provided data or information, you
        agree that you will inform us immediately at legal@thebiomesquad.com,
        with the subject line “Minor Access”. 10. California Residents. This
        section supplements the information contained in this Privacy Policy and
        is intended to comply with applicable California law, including but not
        limited to Civil Code §§ 1798.100 to 1798.199, as amended, and any
        related regulations or guidance provided by the California Attorney
        General. This section applies solely to all visitors, users, and others
        who reside in the State of California. To exercise any of these rights,
        please follow the instructions listed in this section. Right to Know.
        You have the right to know and see what PII we have collected about you
        over the past 12 months, including:
      </p>
      <p>· The categories of PII we collected about you;</p>
      <p>· The categories of sources for the PII we collected about you;</p>
      <p>· Our business or commercial purpose for collecting that PII;</p>
      <p>· The categories of third parties with whom we share that PII;</p>
      <p>· The specific pieces of PII we collected about you; or</p>
      <p>
        · If we disclosed your PII for a business purpose, a list of the
        disclosures made for a business purpose, identifying the PII categories
        that each category of recipient obtained. We do not provide a right to
        know or data portability disclosure for business to business (“B2B”)
        PII. Right to Delete. You have the right to request that we delete any
        of your PII that we collected from you and retained, subject to certain
        exceptions. Once we receive and verify you and your request, we will
        delete (and direct our service providers to delete) your PII from our
        records, unless an exception applies. We may deny your deletion request
        if retaining the PII is necessary for us or our service provider(s) to:
      </p>
      <p>· Complete your transaction;</p>
      <p>· Provide you with the Services;</p>
      <p>· Perform a contract between us and you;</p>
      <p>
        · Detect security incidents, protect against malicious, deceptive,
        fraudulent or illegal activity, and prosecute those responsible for such
        activities;
      </p>
      <p>· Fix our system in the case of a bug;</p>
      <p>
        · Protect the free speech rights, including the free speech rights of
        you or other users, or exercise another right provided by law;
      </p>
      <p>
        · Comply with the California Electronic Communications Privacy Act (Cal.
        Penal Code § 1546 et seq.);
      </p>
      <p>
        · Engage in public or peer-reviewed scientific, historical, or
        statistical research in the public interests that adheres to all other
        applicable ethics and privacy laws;
      </p>
      <p>· Comply with a legal obligation; or</p>
      <p>
        · Make other internal and lawful uses of the PII that are compatible
        with the context in which you provided it. We will delete or de-identify
        PII not subject to one of these exceptions from our records and will
        direct our service providers to take similar action. However, we do not
        provide these deletion rights for B2B PII. Other Rights. Under
        California’s “Shine the Light” law, users of our Services that are
        California residents may request certain information regarding our
        disclosure of their PII to third parties for their direct marketing
        purposes for the immediately preceding calendar year. To do so, you may
        email legal@thebiomesquad.com, with the subject line “California Privacy
        Request.” We will provide in response the requested information to you
        at your e-mail address. Please be aware that not all information sharing
        is covered by the “Shine the Light” requirements and only required
        information will be included in our response. Exercising these Rights.
        To exercise any of the rights described above, please submit a
        verifiable consumer request to us by either:
      </p>
      <p>· Calling us at (737) 259-6190.</p>
      <p>· Emailing us at legal@thebiomesquad.com.</p>
      <p>
        · Visiting the contact page at our Site at
        https://www.thebiomesquad.com/contact. Only you, or someone legally
        authorized to act on your behalf, may make a verifiable consumer request
        related to your PII. In order to verify your identity, we will ask you
        for certain information. We cannot respond to your request or provide
        you with PII if we cannot verify your identity or authority to make the
        request and confirm the PII relates to you. Any new PII collected for
        the purposes of verification will be deleted as soon as practical after
        processing your request, subject to legal retention requirements. Making
        a verifiable consumer request does not require you to create an Account
        with us. However, we do consider requests made through your
        password-protected account sufficiently verified when the request
        relates to PII associated with that specific account. We will only use
        PII provided in a verifiable consumer request to verify the requestor’s
        identity or authority to make the request. We are not obligated to
        respond to a “right to know” request from you more than twice within a
        twelve- (12) month period. Response Time and Format. We will confirm
        receipt of your request within ten (10) business days. If you do not
        receive confirmation within the 10-day timeframe, please contact
        legal@thebiomesquad.com. We endeavor to respond to a verifiable consumer
        request within forty-five (45) days of its receipt. If we require more
        time, we will inform you of the reason and extension period in writing.
        If you have an Account with us, we will deliver our written response to
        that Account. If you do not have an Account with us, we will deliver our
        written response by mail or electronically, at your option. Any
        disclosures we provide will only cover the 12-month period preceding the
        verifiable consumer request’s receipt. The response we provide will also
        explain the reasons we cannot comply with a request, if applicable. For
        “right to know” requests, we will select a format to provide your PII
        that is readily useable and should allow you to transmit the PII from
        one entity to another entity without hindrance, specifically through a
        .csv file or similarly formatted file. We do not charge a fee to process
        or respond to your verifiable consumer request unless it is excessive,
        repetitive, or manifestly unfounded. If we determine that the request
        warrants a fee, we will tell you why we made that decision and provide
        you with a cost estimate before completing your request.
        Non-Discrimination. We will not discriminate against you for exercising
        any of your CCPA rights. For example, as a result of you exercising your
        CCPA rights, we will not:
      </p>
      <p>· Deny you Services;</p>
      <p>
        · Charge you different prices or rates for Services, including through
        granting discounts or other benefits, or imposing penalties;
      </p>
      <p>· Provide you a different level or quality of Services; or</p>
      <p>
        · Suggest that you may receive a different price or rate for Services or
        a different level or quality of Services. However, we may offer you
        certain financial incentives as permitted by applicable law that can
        result in different prices, rates, or quality levels. Any financial
        incentive we offer will reasonably relate to the value of your PII and
        contain written terms that describe the program’s material aspects.
        Participation in a financial incentive program requires your prior
        opt-in consent, which you may revoke at any time. 11. More Information.
        Thank you for taking the time to learn more about your privacy right in
        connection with our Services! We encourage you reach out if you have any
        further questions or feedback. Because e-mail communications are not
        always secure, please do not include any sensitive information in your
        e-mail to us.
      </p>
    </Container>
  );
}

export default Privacy;
