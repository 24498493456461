import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PatientsAdd from '../Patients/PatientsAdd';
import LabsAdd from '../Labs/LabsAdd';
import {
  Avatar,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import './Admin.scss';
import AdminUserEdit from './AdminUserEdit';
import { getLabs, getPatients, getUser } from '../../api/adminApi';
import DownloadPDF from '../../Components/DownloadPDF/DownloadPDF';
import { DownloadIcon } from '@chakra-ui/icons';
import NDAContent from '../NDA/NDAContent';
import { renderToString } from 'react-dom/server';

const patientsHeaders = [
  'first name',
  'last name',
  'date of birth',
  'email',
  'mobile',
  'address',
  'notes',
  'id',
];

function AdminUser({ currentUser, setCurrentUser }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const toast = useToast();
  const [data, setData] = useState(null);
  const [isResending, setIsResending] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOrderOpen, setIsOrderOpen] = useState(false);
  const [selectedLabId, setSelectedLabId] = useState(null);
  const [selectedLab, setSelectedLab] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTab, setSelectedTab] = useState('Detail');
  const [selectedUser, setSelectedUser] = useState(null);
  const [labs, setLabs] = useState([]);
  const [patients, setPatients] = useState([]);

  const tabs = ['Detail', 'Patients', 'Labs'];

  const handleResend = lab => {
    setIsResending(true);
    setSelectedLabId(lab?.id);
    setSelectedLab(lab);
    setTimeout(() => {
      //submit google form to trigger email
      document.querySelector('#submitconsentformtwo').click();
    }, 1000);

    setTimeout(() => {
      setSelectedLabId(null);
      setSelectedLab(null);
      setIsResending(false);
      toast({
        title: `Consent Email sent to ${lab?.patient?.email}`,
        description: `Disclosure forms were included in the email.`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    }, 3000);
  };

  const getData = async id => {
    if (selectedUser) return;
    const u = await getUser(currentUser, id, setSelectedUser);
  };

  const handleGetPatients = async id => {
    const p = await getPatients(selectedUser?.id);
    setPatients(p);
  };

  const handleGetLabs = async id => {
    const l = await getLabs(selectedUser?.id);
    setLabs(l);
  };

  useEffect(() => {
    if (selectedTab === 'Patients') {
      handleGetPatients();
    }
    if (selectedTab === 'Labs') {
      handleGetLabs();
    }
  }, [selectedTab]);

  useEffect(() => {
    getData(id);
  }, [id]);

  return (
    <div id="admin" className="page-padding">
      <AdminUserEdit
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        user={selectedUser}
        setUser={setSelectedUser}
      />
      <LabsAdd
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        isOpen={isOrderOpen}
        setIsOpen={setIsOrderOpen}
        //patient={patient}
      />
      <Heading
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        fontSize={{
          base: '2xl',
          md: '3xl',
        }}
      >
        {selectedUser?.displayName}
      </Heading>
      <Stack direction="column" borderTop={'1px solid #ddd'}>
        <Flex borderBottom={'1px solid #ddd'}>
          {tabs.map(t => (
            <Button
              onClick={() => setSelectedTab(t)}
              borderRadius={0}
              colorScheme="blue"
              variant={selectedTab === t ? 'solid' : 'ghost'}
            >
              {t}
            </Button>
          ))}
        </Flex>
        {selectedTab === 'Detail' && (
          <Flex direction="column">
            <Flex justifyContent="space-between" marginTop={6}>
              <Avatar
                backgroundColor={'gray.400'}
                size="2xl"
                src={selectedUser?.photoURL}
              ></Avatar>
              <Heading
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={4}
                fontSize={{
                  base: '4xl',
                  md: '5xl',
                }}
              >
                <ButtonGroup>
                  <Button colorScheme="gray" onClick={() => onOpen()}>
                    Delete
                  </Button>
                  <Button
                    colorScheme="blue"
                    onClick={() => setIsModalOpen(true)}
                  >
                    Edit
                  </Button>
                </ButtonGroup>
              </Heading>
            </Flex>
            <TableContainer>
              <Table variant="simple">
                {currentUser && (
                  <Tbody>
                    {/* <Tr>
            <Td>Id</Td>
            <Td className="id">{patient.id}</Td>
          </Tr> */}

                    <Tr>
                      <Td>Contact Info</Td>
                      <Td className="email">
                        <a
                          style={{ marginLeft: -15 }}
                          href={`mailto:${selectedUser?.email}`}
                        >
                          <Button colorScheme="blue" variant="ghost">
                            {selectedUser?.email}
                          </Button>
                        </a>

                        {selectedUser?.mobile}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Membership</Td>
                      <Td>{selectedUser?.membership}</Td>
                    </Tr>
                    <Tr>
                      <Td>Application Date</Td>
                      <Td>
                        {selectedUser?.applicationDate &&
                          new Date(
                            selectedUser?.applicationDate
                          ).toLocaleDateString()}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Employer</Td>
                      <Td>{selectedUser?.employer}</Td>
                    </Tr>
                    <Tr>
                      <Td>Specialty</Td>
                      <Td>{selectedUser?.specialty}</Td>
                    </Tr>
                    <Tr>
                      <Td>Signed NDA</Td>
                      <Td>
                        {selectedUser?.signedNDA}
                        <span style={{ padding: 5 }}></span>
                        <DownloadPDF
                          htmlString={
                            <NDAContent
                              displayName={selectedUser?.displayName}
                              NDADate={selectedUser?.signedNDA}
                            />
                          }
                          buttonLabel={'NDA'}
                          size={'sm'}
                          leftIcon={
                            <DownloadIcon h={4} w={4} color={'white.300'} />
                          }
                        />
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Bio</Td>
                      <Td
                        className="note"
                        dangerouslySetInnerHTML={{
                          __html: selectedUser?.bio,
                        }}
                      ></Td>
                    </Tr>
                    <Tr>
                      <Td>Admin Notes</Td>
                      <Td
                        className="note"
                        dangerouslySetInnerHTML={{
                          __html: selectedUser?.adminNote,
                        }}
                      ></Td>
                    </Tr>
                    <Tr>
                      <Td>CV</Td>
                      <Td
                        className="note"
                        dangerouslySetInnerHTML={{
                          __html: selectedUser?.cv,
                        }}
                      ></Td>
                    </Tr>
                  </Tbody>
                )}
              </Table>
            </TableContainer>
          </Flex>
        )}

        {selectedTab === 'Patients' && (
          <Flex direction="column">
            <TableContainer>
              {currentUser && (
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      {patientsHeaders.map(l => (
                        <Th>{l}</Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {patients &&
                      patients?.map(l => (
                        <Tr
                          cursor="pointer"
                          onClick={() =>
                            navigate(
                              `${window.location.pathname}/patient/${l.id}`
                            )
                          }
                        >
                          <Td>{l.firstName}</Td>
                          <Td>{l.lastName}</Td>
                          <Td>{new Date(l.dob).toLocaleDateString()}</Td>
                          <Td>{l.email}</Td>
                          <Td>{l.mobile}</Td>
                          <Td className="mark">{l.address}</Td>

                          <Td
                            className="mark"
                            dangerouslySetInnerHTML={{ __html: l.note }}
                          ></Td>
                          <Td className="id">{l.id}</Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              )}
            </TableContainer>
          </Flex>
        )}
        {selectedTab === 'Labs' && (
          <Flex direction="column">
            <TableContainer>
              <Table variant="simple">
                {currentUser && (
                  <>
                    <Thead>
                      <Tr>
                        <Th>Id</Th>
                        <Th>Date</Th>
                        <Th>Status</Th>
                        <Th>Actions</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {labs?.map(l => (
                        <Tr key={l.id}>
                          <Td>
                            <Button
                              size="sm"
                              colorScheme="blue"
                              variant="ghost"
                              onClick={() =>
                                navigate(
                                  `${window.location.pathname}/lab/${l.id}`
                                )
                              }
                              style={{ marginLeft: -12 }}
                            >
                              {l.id}
                            </Button>
                          </Td>
                          <Td>{new Date(l.date).toLocaleDateString()}</Td>
                          <Td>{l.status}</Td>
                          <Td>
                            <Button
                              key={l.id}
                              colorScheme="gray"
                              size="sm"
                              isLoading={isResending && selectedLabId === l.id}
                              loadingText="Resending Patient Consent Form"
                              onClick={() => handleResend(l)}
                            >
                              Resend Patient Consent Form
                            </Button>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </>
                )}
              </Table>
            </TableContainer>
          </Flex>
        )}

        <br />
      </Stack>
      {/* <iframe
        title="secret-frame-two"
        name="secret-frame-two"
        width="0"
        height="0"
        border="0"
        style={{ display: 'none' }}
      ></iframe>
      <form
        className="form"
        action="https://docs.google.com/forms/d/e/1FAIpQLSd0siGl6pf4z1J7kEdxk6mzAqZo0YTd_pULjJMNBa9gk38B6Q/formResponse"
        id="ss-form2"
        method="POST"
        target="secret-frame-two"
        style={{ display: 'none' }}
      >
        <div className="form-group">
          <label htmlFor="dateValue">Date</label>
          <input
            name="entry.1726393305"
            readOnly
            type="text"
            value={selectedLab?.date}
          />
        </div>
        <div className="form-group">
          <label htmlFor="labId">LabId</label>
          <input
            name="entry.1252597670"
            readOnly
            type="text"
            value={selectedLab?.id}
          />
        </div>
        <div className="form-group">
          <label htmlFor="patientId">PatientId</label>
          <input
            name="entry.2107253374"
            readOnly
            type="text"
            value={patient?.id}
          />
        </div>
        <div className="form-group">
          <label htmlFor="patientEmail">PatientEmail</label>
          <input
            name="entry.235882463"
            readOnly
            type="email"
            value={patient?.email}
          />
        </div>
        <div className="form-group">
          <label htmlFor="patientFirstName">PatientFirstName</label>
          <input
            name="entry.1702512101"
            readOnly
            type="text"
            value={patient?.firstName}
          />
        </div>
        <div className="form-group">
          <label htmlFor="doctorName">DoctorName</label>
          <input
            name="entry.850389264"
            readOnly
            type="text"
            value={selectedUser?.displayName}
          />
        </div>
        <div className="form-group">
          <label htmlFor="type">Type</label>
          <input name="entry.351738770" readOnly type="text" value={''} />
        </div>
        <input
          id="submitconsentformtwo"
          type="submit"
          value="Submit Consent Form Two"
        />
      </form> */}
    </div>
  );
}

export default AdminUser;
