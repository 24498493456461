import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Stack,
  Heading,
  Text,
  Container,
  Input,
  Button,
  SimpleGrid,
  Avatar,
  AvatarGroup,
  useBreakpointValue,
  IconProps,
  Icon,
  FormControl,
  FormLabel,
  Center,
  AvatarBadge,
  IconButton,
  FormHelperText,
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { FaGoogle } from 'react-icons/fa';
import { authFire } from '../../api/fire';
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import { validateEmail } from '../../Helpers/Validators';

const provider = new GoogleAuthProvider();
const Blur = props => {
  return (
    <Icon
      width={useBreakpointValue({ base: '100%', md: '40vw', lg: '30vw' })}
      zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="71" cy="61" r="111" fill="#F56565" />
      <circle cx="244" cy="106" r="139" fill="#ED64A6" />
      <circle cy="291" r="139" fill="#ED64A6" />
      <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936" />
      <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B" />
      <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78" />
      <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" />
    </Icon>
  );
};

export default function SignIn({ setCurrentUser }) {
  const navigate = useNavigate();
  const auth = getAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    if (email.length > 5 && password.length > 5) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  }, [email, password]);

  const loginUser = () => {
    signInWithEmailAndPassword(
      //auth,
      authFire,
      email,
      password,
      setCurrentUser
    )
      .then(userCredential => {
        const user = userCredential.user;
        setCurrentUser(user);
        setTimeout(() => {
          navigate('/');
        }, 1000);
      })
      .catch(error => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setErrorMessage(error.message);
      });
  };

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then(result => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;

        setCurrentUser(user);
        setTimeout(() => {
          navigate('/');
        }, 1000);
      })
      .catch(error => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  return (
    <Box id="sign-up" position={'relative'}>
      <Blur
        position={'absolute'}
        top={-10}
        left="0"
        width="100vw"
        height="100vh"
        style={{ filter: 'blur(70px)' }}
        zIndex={-1}
      />
      <Container
        as={SimpleGrid}
        justifyContent="center"
        maxW={'7xl'}
        mt={100}
        mb={100}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack
          bg={'gray.50'}
          rounded={'xl'}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: 'lg' }}
        >
          <Stack spacing={4}>
            <Heading
              color={'gray.800'}
              lineHeight={1.1}
              display="flex"
              fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
            >
              Sign In
            </Heading>
            {/*            <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
              Welcome back!
            </Text>
            <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
              For doctors: You can apply for certification to get access to
              ordering labs after signing up below.
            </Text> */}
          </Stack>
          <Box as={'form'}>
            <Stack spacing={4}>
              <FormControl id="email" isRequired>
                {email.length > 5 && <FormLabel>Email</FormLabel>}
                <Input
                  placeholder="Email"
                  _placeholder={{ color: 'gray.500' }}
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl id="password" isRequired>
                {password.length > 5 && <FormLabel>Password</FormLabel>}
                <Input
                  placeholder="Password"
                  _placeholder={{ color: 'gray.500' }}
                  value={password}
                  type="password"
                  onChange={e => setPassword(e.target.value)}
                />
              </FormControl>
            </Stack>
            <Button
              isDisabled={!isEnabled}
              fontFamily={'heading'}
              mt={8}
              w={'full'}
              color={'white'}
              colorScheme="blue"
              onClick={() => loginUser()}
            >
              Sign In
            </Button>
          </Box>

          <Box as={'form'}>
            <div>
              <div className="or">----- OR -----</div>
              <Button
                fontFamily={'heading'}
                mt={8}
                w={'full'}
                color={'white'}
                colorScheme="blue"
                leftIcon={<FaGoogle h={4} w={4} color={'gray.300'} />}
                onClick={() => signInWithGoogle()}
              >
                Sign In with Google
              </Button>
              <Text
                color={'gray.500'}
                mt={4}
                fontSize={{ base: 'xs', sm: 'sm' }}
              >
                By signing in and creating an account you agree to our{' '}
                <Link to="/terms">Terms of Service</Link> and{' '}
                <Link to="/privacy">Policies</Link>.
              </Text>
            </div>
            <Flex marginTop={6} justify={'space-between'}>
              <Link to="/sign-up">Sign Up</Link>
              <Link to="/reset-password">Forgot Password?</Link>
            </Flex>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
