import React, { useEffect, useState } from 'react';

import {
  Box,
  Flex,
  Stack,
  Heading,
  Text,
  Container,
  Input,
  Button,
  SimpleGrid,
  Avatar,
  AvatarGroup,
  useBreakpointValue,
  IconProps,
  Icon,
  FormControl,
  FormLabel,
  Center,
  AvatarBadge,
  IconButton,
  FormHelperText,
  useToast,
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { authFire } from '../../api/fire';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { validateEmail } from '../../Helpers/Validators';

const Blur = props => {
  return (
    <Icon
      width={useBreakpointValue({ base: '100%', md: '40vw', lg: '30vw' })}
      zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="71" cy="61" r="111" fill="#F56565" />
      <circle cx="244" cy="106" r="139" fill="#ED64A6" />
      <circle cy="291" r="139" fill="#ED64A6" />
      <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936" />
      <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B" />
      <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78" />
      <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" />
    </Icon>
  );
};

export default function SignIn({ setCurrentUser }) {
  const toast = useToast();
  const [email, setEmail] = useState('');
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    if (email.length > 5 && email.includes('@')) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  }, [email]);

  const resetPassword = () => {
    sendPasswordResetEmail(authFire, email);
    toast({
      title: `Successfully sent reset password link to ${email}`,
      description: `Please check your email for instructions.`,
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    setEmail('');
    setIsEnabled(false);
  };

  return (
    <Box id="sign-up" position={'relative'}>
      <Blur
        position={'absolute'}
        top={-10}
        left="0"
        width="100vw"
        height="100vh"
        style={{ filter: 'blur(70px)' }}
        zIndex={-1}
      />
      <Container
        as={SimpleGrid}
        justifyContent="center"
        maxW={'7xl'}
        mt={100}
        mb={100}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack
          bg={'gray.50'}
          rounded={'xl'}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: 'lg' }}
          minW={{ lg: 'lg' }}
        >
          <Stack spacing={4}>
            <Heading
              color={'gray.800'}
              lineHeight={1.1}
              display="flex"
              fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
            >
              Reset Password
            </Heading>
          </Stack>
          <Box as={'form'}>
            <Stack spacing={4}>
              <FormControl id="email" isRequired>
                {email.length > 5 && <FormLabel>Email</FormLabel>}
                <Input
                  placeholder="Email"
                  _placeholder={{ color: 'gray.500' }}
                  type="email"
                  value={email}
                  className={email?.length > 0 ? 'filled' : ''}
                  onChange={e => setEmail(e.target.value)}
                />
              </FormControl>
            </Stack>
            <Button
              isDisabled={!isEnabled}
              fontFamily={'heading'}
              mt={4}
              w={'full'}
              color={'white'}
              colorScheme="blue"
              onClick={() => resetPassword()}
            >
              Reset Password
            </Button>
          </Box>

          <Box>
            <Flex marginTop={6} justify={'center'}>
              <Link to="/reset-password">Sign In?</Link>
            </Flex>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
