import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Stack,
  Heading,
  Text,
  Container,
  Input,
  Button,
  SimpleGrid,
  Avatar,
  AvatarGroup,
  useBreakpointValue,
  IconProps,
  Icon,
  FormControl,
  FormLabel,
  Center,
  AvatarBadge,
  IconButton,
  FormHelperText,
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { FaGoogle } from 'react-icons/fa';
import { authFire } from '../../api/fire';
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';

const provider = new GoogleAuthProvider();

export default function ConferenceSignUp({ setCurrentUser }) {
  const navigate = useNavigate();
  const auth = getAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    if (password === passwordAgain) {
      setIsEnabled(true);
      return;
    } else {
      setIsEnabled(false);
    }
  }, [email, password, passwordAgain]);

  const handleSignUp = () => {
    createUserWithEmailAndPassword(authFire, email, password)
      .then(userCredential => {
        const user = userCredential.user;

        navigate('/conference/become-certified');
        setCurrentUser(user);
      })
      .catch(error => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setErrorMessage(error.message);
      });
  };

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then(result => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        navigate('/conference/become-certified');
        setCurrentUser(user);
      })
      .catch(error => {
        console.log('error88', error);
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData?.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  return (
    <Box id="sign-up" position={'relative'}>
      <Container
        as={SimpleGrid}
        justifyContent="center"
        height="100vh"
        maxW={'7xl'}
      >
        <Stack
          rounded={'xl'}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: 'lg' }}
          height="100vh"
          justifyContent="center"
        >
          <Stack spacing={4}>
            <Flex alignItems="center" gap={4}>
              <Heading
                color={'gray.800'}
                lineHeight={1.1}
                display="flex"
                fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
              >
                Sign Up
              </Heading>
              <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
                Step 1/2
              </Text>
            </Flex>
          </Stack>
          <Box as={'form'}>
            <Stack spacing={4}>
              <FormControl id="email" isRequired>
                {email.length > 5 && <FormLabel>Email</FormLabel>}
                <Input
                  placeholder="Email"
                  _placeholder={{ color: 'gray.500' }}
                  type="email"
                  value={email}
                  className={email?.length > 0 ? 'filled' : ''}
                  onChange={e => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl id="password" isRequired>
                {password.length > 5 && <FormLabel>Password</FormLabel>}
                <Input
                  placeholder="Create Password"
                  _placeholder={{ color: 'gray.500' }}
                  value={password}
                  className={password?.length > 0 ? 'filled' : ''}
                  onChange={e => setPassword(e.target.value)}
                />
              </FormControl>
              <FormControl id="passwordagain" isRequired>
                {passwordAgain.length > 5 && (
                  <FormLabel>Password Again</FormLabel>
                )}
                <Input
                  placeholder="Password Again"
                  _placeholder={{ color: 'gray.500' }}
                  value={passwordAgain}
                  className={password?.length > 0 ? 'filled' : ''}
                  onChange={e => setPasswordAgain(e.target.value)}
                />
              </FormControl>
            </Stack>
            <Button
              isDisabled={!isEnabled}
              fontFamily={'heading'}
              mt={8}
              w={'full'}
              color={'white'}
              colorScheme="blue"
              onClick={() => handleSignUp()}
            >
              Sign Up
            </Button>
          </Box>

          <Box as={'form'}>
            <div>
              <div className="or">----- OR -----</div>
              <Button
                fontFamily={'heading'}
                mt={8}
                w={'full'}
                color={'white'}
                leftIcon={<FaGoogle h={4} w={4} color={'gray.300'} />}
                onClick={() => signInWithGoogle()}
                colorScheme="blue"
              >
                Sign Up with Google
              </Button>
              <Text
                color={'gray.500'}
                mt={4}
                fontSize={{ base: 'xs', sm: 'sm' }}
              >
                By signing in and creating an account you agree to our{' '}
                <Link to="/terms">Terms of Service</Link> and{' '}
                <Link to="/privacy">Policies</Link>.
              </Text>
            </div>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
