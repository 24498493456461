import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Heading,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { BiUser } from 'react-icons/bi';
import './Navigation.scss';
import NavigationMobile from './NavigationMobile';
import NavigationDesktop from './NavigationDesktop';
import { getNavItems } from '../../Helpers/UserHelpers';
import { useMediaQuery } from '@chakra-ui/react';

export default function NavigationAuthed({ currentUser, isCertified }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Box id="navigation">
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
      >
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <Text
            textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
            fontFamily={'heading'}
            color={useColorModeValue('gray.800', 'white')}
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/')}
          >
            <Heading fontSize="1.5em" fontFamily="Contrail One">
              BIOME SQUAD
              <span className="trademark">™</span>
            </Heading>
          </Text>

          <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
            <NavigationDesktop
              navItems={getNavItems(currentUser)}
              navigate={navigate}
              pathname={pathname}
            />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          direction={'row'}
          spacing={6}
        >
          {!isCertified && (
            <Button
              as={'a'}
              fontSize={'sm'}
              color={'gray.600'}
              fontWeight={600}
              onClick={() => navigate('/become-certified')}
            >
              {isMobile ? 'Apply' : 'Become Certified'}
            </Button>
          )}

          <Button
            as={'a'}
            display={{ base: 'none', md: 'inline-flex' }}
            fontSize={'sm'}
            fontWeight={600}
            color={'white'}
            onClick={() => navigate('/profile')}
            title="Profile"
          >
            <Icon as={BiUser} color={'gray.600'} w={5} h={5} />
          </Button>
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <NavigationMobile
          currentUser={currentUser}
          navItems={getNavItems(currentUser)}
          pathname={pathname}
          onClose={onClose}
        />
      </Collapse>
    </Box>
  );
}
