import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Heading,
  Container,
  Input,
  Button,
  SimpleGrid,
  useBreakpointValue,
  Icon,
  FormControl,
  FormLabel,
  Spinner,
  Select,
  Flex,
} from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { applyForCertification } from '../../api/api';
import {
  certifiedMembersData,
  certifiedMembersType,
} from '../../data/certifiedmembers';
import { useNavigate } from 'react-router-dom';

export default function ConferenceForm({ currentUser, setCurrentUser }) {
  const [isEnabled, setIsEnabled] = useState(false);
  const [cv, setCv] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [mobile, setMobile] = useState('');
  const [specialty, setSpecialty] = useState('');
  const [employer, setEmployer] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [selectedType, setSelectedType] = useState(
    certifiedMembersData[0].value
  );
  const [licenseNumber, setLicenseNumber] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (mobile.length > 5 && specialty.length > 1 && employer.length > 1) {
      setIsEnabled(true);
      return;
    } else {
      setIsEnabled(false);
    }
  }, [cv, mobile, specialty, employer]);

  const handleFormSubmit = async () => {
    setIsSending(true);
    setIsEnabled(false);
    const res = await applyForCertification(
      { ...currentUser, displayName: currentUser?.displayName || displayName },
      cv,
      mobile,
      specialty,
      employer,
      selectedType,
      licenseNumber,
      address,
      city,
      state,
      zip
    );
    navigate('/conference/success');
    setCurrentUser(res);
    setIsSending(false);
    setIsEnabled(true);
    window.scrollTo(0, 0);
  };

  return (
    <form>
      <Stack spacing={4}>
        <FormControl isRequired>
          <FormLabel>I am a</FormLabel>
          <Select
            placeholder="Select Type"
            value={selectedType}
            onChange={e => {
              setSelectedType(e.target.value);
            }}
          >
            {certifiedMembersType.map(c => (
              <option value={c.value} key={c.id}>
                {c.value}
              </option>
            ))}
          </Select>
        </FormControl>
        {!currentUser?.displayName && (
          <FormControl isRequired>
            {displayName.length > 5 && <FormLabel>Name</FormLabel>}
            <Input
              placeholder="First and Last Name"
              bg={'gray.100'}
              border={0}
              color={'gray.500'}
              _placeholder={{
                color: 'gray.500',
              }}
              className={displayName?.length > 0 ? 'filled' : ''}
              autocomplete="tel"
              value={displayName}
              onChange={e => setDisplayName(e.target.value)}
            />
          </FormControl>
        )}
        <FormControl isRequired>
          {mobile.length > 5 && (
            <FormLabel>
              Mobile/Cell (Include country code if outside US)
            </FormLabel>
          )}
          <Input
            placeholder="Mobile/Cell (Include country code if outside US) *"
            bg={'gray.100'}
            border={0}
            color={'gray.500'}
            _placeholder={{
              color: 'gray.500',
            }}
            className={mobile?.length > 0 ? 'filled' : ''}
            autocomplete="tel"
            value={mobile}
            onChange={e => setMobile(e.target.value)}
          />
        </FormControl>
        <FormControl isRequired>
          {specialty.length > 1 && (
            <FormLabel>Specialty (GI, neuro, internal, etc.)</FormLabel>
          )}
          <Input
            placeholder="Specialty  (GI, neuro, internal, etc.) *"
            bg={'gray.100'}
            border={0}
            color={'gray.500'}
            _placeholder={{
              color: 'gray.500',
            }}
            className={specialty?.length > 0 ? 'filled' : ''}
            value={specialty}
            onChange={e => setSpecialty(e.target.value)}
          />
        </FormControl>
        {selectedType !==
          certifiedMembersData[certifiedMembersData.length - 1].value && (
          <FormControl>
            {licenseNumber.length > 1 && (
              <FormLabel>DEA, NPI, or License Number</FormLabel>
            )}
            <Input
              placeholder="DEA, NPI, or License Number"
              bg={'gray.100'}
              border={0}
              color={'gray.500'}
              _placeholder={{
                color: 'gray.500',
              }}
              value={licenseNumber}
              className={licenseNumber?.length > 0 ? 'filled' : ''}
              onChange={e => setLicenseNumber(e.target.value)}
            />
          </FormControl>
        )}
        DEA and NPI number or License Number,
        <FormControl isRequired>
          {employer.length > 1 && (
            <FormLabel>Employer ('private practice' is acceptable)</FormLabel>
          )}
          <Input
            placeholder="Employer ('Private Practice' is acceptable) *"
            bg={'gray.100'}
            border={0}
            color={'gray.500'}
            _placeholder={{
              color: 'gray.500',
            }}
            className={employer?.length > 0 ? 'filled' : ''}
            value={employer}
            onChange={e => setEmployer(e.target.value)}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Address</FormLabel>
          <Input
            placeholder="Street Address"
            bg={'gray.100'}
            border={0}
            color={'gray.500'}
            _placeholder={{
              color: 'gray.500',
            }}
            className={address?.length > 0 ? 'filled' : ''}
            value={address}
            onChange={e => setAddress(e.target.value)}
          />
        </FormControl>
        <Flex gap={4}>
          <FormControl isRequired>
            <FormLabel>City</FormLabel>
            <Input
              placeholder="City"
              bg={'gray.100'}
              border={0}
              color={'gray.500'}
              _placeholder={{
                color: 'gray.500',
              }}
              className={city?.length > 0 ? 'filled' : ''}
              value={city}
              onChange={e => setCity(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>State</FormLabel>
            <Input
              placeholder="State"
              bg={'gray.100'}
              border={0}
              color={'gray.500'}
              _placeholder={{
                color: 'gray.500',
              }}
              className={state?.length > 0 ? 'filled' : ''}
              value={state}
              onChange={e => setState(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Zip</FormLabel>
            <Input
              placeholder="Zip"
              bg={'gray.100'}
              border={0}
              color={'gray.500'}
              _placeholder={{
                color: 'gray.500',
              }}
              className={zip?.length > 0 ? 'filled' : ''}
              value={zip}
              onChange={e => setZip(e.target.value)}
            />
          </FormControl>
        </Flex>
      </Stack>
      <Button
        isDisabled={!isEnabled}
        fontFamily={'heading'}
        mt={8}
        w={'full'}
        bgGradient="linear(to-r, red.400,pink.400)"
        color={'white'}
        _hover={{
          bgGradient: 'linear(to-r, red.400,pink.400)',
          boxShadow: 'xl',
        }}
        onClick={() => handleFormSubmit()}
      >
        {isSending ? (
          <>
            <Spinner
              color="white.500"
              size="sm"
              style={{ marginRight: '10px' }}
            />
            Saving
          </>
        ) : (
          'Complete Registration'
        )}
      </Button>
    </form>
  );
}
