import React, { useEffect, useState } from 'react';
import {
  Button,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import PatientsAdd from './PatientsAdd';
import { getDocuments, getPatients } from '../../api/api';
import { useNavigate } from 'react-router-dom';
import PatientsPlaceholder from './PatientsPlaceholder';
import Loader from '../../Components/Loader/Loader';
import { getAge } from '../../Helpers/UserHelpers';

const patientsHeaders = [
  'first name',
  'last name',
  'date of birth',
  'age',
  'email',
  'mobile',
  'address',
  'notes',
  'id',
];

function PatientsCertified({ currentUser, setCurrentUser }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [patients, setPatients] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    getDocuments(currentUser?.id, 'patients', setPatients);
    setTimeout(() => {
      setIsLoaded(true);
    }, 500);
  }, [currentUser]);

  if (!isLoaded) return <Loader hideLogo={true} />;

  return (
    <div id="patients" className="page-padding">
      <PatientsAdd
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      {!patients && isLoaded && (
        <PatientsPlaceholder currentUser={currentUser} callback={setIsOpen} />
      )}
      {patients && (
        <>
          <Heading
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={4}
            fontSize={{
              base: '4xl',
              md: '5xl',
            }}
          >
            Patients
            <Button colorScheme="blue" onClick={() => setIsOpen(true)}>
              Add Patient
            </Button>
          </Heading>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  {patientsHeaders.map(l => (
                    <Th>{l}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {patients &&
                  patients.map(l => (
                    <Tr onClick={() => navigate(`/patient/${l.id}`)}>
                      <Td>{l.firstName}</Td>
                      <Td>{l.lastName}</Td>
                      <Td>{new Date(l.dob).toLocaleDateString()}</Td>
                      <Td>{getAge(new Date(l.dob))}</Td>
                      <Td>{l.email}</Td>
                      <Td>{l.mobile}</Td>
                      <Td className="mark">
                        {l.address?.length > 0 ? (
                          <img src="./checkmark-sharp.svg" alt="True" />
                        ) : (
                          ''
                        )}
                      </Td>

                      <Td className="mark">
                        {l.note?.length > 0 ? (
                          <img src="./checkmark-sharp.svg" alt="True" />
                        ) : (
                          ''
                        )}
                      </Td>
                      <Td className="id">{l.id}</Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
}

export default PatientsCertified;
