import React from 'react';
import LabsPlaceholder from './LabsPlaceholder';
import LabsCertified from './LabsCertified';
import './Labs.scss';

function Labs({ currentUser, setCurrentUser, isCertified }) {
  if (!isCertified) {
    return (
      <div id="labs">
        <LabsPlaceholder />
      </div>
    );
  } else {
    return (
      <div id="labs">
        <LabsCertified
          currentUser={currentUser}
          setCurrentUser={setCurrentUser}
        />
      </div>
    );
  }
}

export default Labs;
