import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Heading,
  Container,
  Input,
  Button,
  SimpleGrid,
  useBreakpointValue,
  Icon,
  FormControl,
  FormLabel,
  Spinner,
  Select,
  Flex,
  Avatar,
  Center,
  useToast,
} from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { applyForCertification } from '../../api/api';
import { certifiedMembersType } from '../../data/certifiedmembers';
import { updateProfilePic } from '../../api/api';

const Blur = props => {
  return (
    <Icon
      width={useBreakpointValue({ base: '100%', md: '40vw', lg: '30vw' })}
      zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="71" cy="61" r="111" fill="#F56565" />
      <circle cx="244" cy="106" r="139" fill="#ED64A6" />
      <circle cy="291" r="139" fill="#ED64A6" />
      <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936" />
      <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B" />
      <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78" />
      <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" />
    </Icon>
  );
};

export default function RegisterBC({ currentUser, setCurrentUser, fbUser }) {
  //console.log('cU', currentUser, fbUser);
  const [isEnabled, setIsEnabled] = useState(false);
  const [bio, setBio] = useState(currentUser?.bio || '');
  const [displayName, setDisplayName] = useState(
    currentUser?.displayName || ''
  );
  const [mobile, setMobile] = useState('');
  const [specialty, setSpecialty] = useState('');
  const [employer, setEmployer] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [selectedType, setSelectedType] = useState(
    certifiedMembersType[0].value
  );
  const [licenseNumber, setLicenseNumber] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const toast = useToast();

  useEffect(() => {
    if (
      bio.length > 5 &&
      mobile.length > 5 &&
      specialty.length > 1 &&
      employer.length > 1
    ) {
      setIsEnabled(true);
      return;
    } else {
      setIsEnabled(false);
    }
  }, [bio, mobile, specialty, employer]);

  const handleSetProfilePic = e => {
    const file = e.target.files[0];
    //setProfilePic(file);

    if (file) {
      //setAvatar(URL.createObjectURL(file));

      //save to firebase storage and url in user collection
      updateProfilePic(currentUser, file, setCurrentUser);

      toast({
        title: 'Profile Pic Saved!',
        status: 'success',
        duration: 6000,
        isClosable: false,
        position: 'top',
      });
    }
  };

  const handleFormSubmit = async () => {
    setIsSending(true);
    setIsEnabled(false);
    const res = await applyForCertification(
      { ...currentUser, displayName: currentUser?.displayName || displayName },
      bio,
      mobile,
      specialty,
      employer,
      selectedType,
      licenseNumber,
      address,
      city,
      state,
      zip
    );
    setCurrentUser(res);
    setIsSending(false);
    setIsEnabled(true);
    window.scrollTo(0, 0);
  };

  return (
    <Box id="BC" position={'relative'}>
      <Blur
        position={'absolute'}
        top={'-10vh'}
        right="-10vw"
        width="100%"
        height="80vh"
        style={{ filter: 'blur(70px)' }}
        zIndex={-1}
      />
      <Container as={SimpleGrid} maxW={'7xl'} py={{ base: 2, md: 6 }}>
        <Stack
          bg={'white'}
          rounded={'xl'}
          p={{ base: 4, sm: 8, md: 12 }}
          spacing={{ base: 10 }}
          maxW={{ lg: 'lg' }}
          minW={{ base: '320px', sm: '340px', md: '500px' }}
        >
          <Heading
            color={'gray.800'}
            lineHeight={1.1}
            display="flex"
            fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
          >
            Application Form
          </Heading>
          <form>
            <Stack spacing={4}>
              {!fbUser?.providerData[0]?.providerId?.includes('oogle') ? (
                <FormControl id="profilePic">
                  <Stack direction={['row', 'row']} spacing={6} align="center">
                    <div>
                      <FormLabel>Profile Pic</FormLabel>
                      <Button>
                        <Input
                          style={{
                            border: 'none',
                            paddingTop: '7.5px',
                            backgroundColor: 'transparent',
                          }}
                          type="file"
                          onChange={e => handleSetProfilePic(e)}
                        />
                      </Button>
                    </div>

                    <Center>
                      <Avatar
                        className="avatar"
                        size="xl"
                        src={currentUser?.photoURL}
                      ></Avatar>
                    </Center>
                  </Stack>
                </FormControl>
              ) : (
                <Avatar size="xl" src={fbUser?.photoURL}></Avatar>
              )}
              <FormControl isRequired>
                <FormLabel>I am a</FormLabel>
                <Select
                  placeholder="Select Type"
                  value={selectedType}
                  onChange={e => {
                    setSelectedType(e.target.value);
                  }}
                >
                  {certifiedMembersType.map(c => (
                    <option value={c.value} key={c.id}>
                      {c.value}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {/* {currentUser?.displayName && (
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input
                    placeholder="First and Last Name"
                    bg={'gray.100'}
                    border={0}
                    color={'gray.500'}
                    _placeholder={{
                      color: 'gray.500',
                    }}
                    disabled={true}
                    className={
                      currentUser?.displayName.length > 0 ? 'filled' : ''
                    }
                    value={currentUser?.displayName}
                  />
                </FormControl>
              )} */}
              <FormControl isRequired>
                {displayName.length > 5 && <FormLabel>Name</FormLabel>}
                <Input
                  placeholder="First and Last Name"
                  bg={'gray.100'}
                  border={0}
                  color={'gray.500'}
                  _placeholder={{
                    color: 'gray.500',
                  }}
                  className={displayName?.length > 0 ? 'filled' : ''}
                  autocomplete="name"
                  value={displayName}
                  onChange={e => setDisplayName(e.target.value)}
                />
              </FormControl>
              <FormControl isRequired>
                {mobile.length > 5 && (
                  <FormLabel>
                    Mobile/Cell (Include country code if outside US)
                  </FormLabel>
                )}
                <Input
                  placeholder="Mobile/Cell (Include country code if outside US) *"
                  bg={'gray.100'}
                  border={0}
                  color={'gray.500'}
                  _placeholder={{
                    color: 'gray.500',
                  }}
                  className={mobile?.length > 0 ? 'filled' : ''}
                  autocomplete="tel"
                  value={mobile}
                  onChange={e => setMobile(e.target.value)}
                />
              </FormControl>
              <FormControl isRequired>
                {specialty.length > 1 && (
                  <FormLabel>Specialty (GI, neuro, internal, etc.)</FormLabel>
                )}
                <Input
                  placeholder="Specialty  (GI, neuro, internal, etc.) *"
                  bg={'gray.100'}
                  border={0}
                  color={'gray.500'}
                  _placeholder={{
                    color: 'gray.500',
                  }}
                  className={specialty?.length > 0 ? 'filled' : ''}
                  value={specialty}
                  onChange={e => setSpecialty(e.target.value)}
                />
              </FormControl>
              {selectedType !==
                certifiedMembersType[certifiedMembersType.length - 1].value && (
                <FormControl isRequired>
                  {licenseNumber.length > 1 && (
                    <FormLabel>DEA, NPI, or License Number</FormLabel>
                  )}
                  <Input
                    placeholder="DEA, NPI, or License Number *"
                    bg={'gray.100'}
                    border={0}
                    color={'gray.500'}
                    _placeholder={{
                      color: 'gray.500',
                    }}
                    value={licenseNumber}
                    className={licenseNumber?.length > 0 ? 'filled' : ''}
                    onChange={e => setLicenseNumber(e.target.value)}
                  />
                </FormControl>
              )}
              DEA and NPI number or License Number,
              <FormControl isRequired>
                {employer.length > 1 && (
                  <FormLabel>
                    Employer ('private practice' is acceptable)
                  </FormLabel>
                )}
                <Input
                  placeholder="Employer ('Private Practice' is acceptable) *"
                  bg={'gray.100'}
                  border={0}
                  color={'gray.500'}
                  _placeholder={{
                    color: 'gray.500',
                  }}
                  className={employer?.length > 0 ? 'filled' : ''}
                  value={employer}
                  onChange={e => setEmployer(e.target.value)}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Address</FormLabel>
                <Input
                  placeholder="Street Address"
                  bg={'gray.100'}
                  border={0}
                  color={'gray.500'}
                  _placeholder={{
                    color: 'gray.500',
                  }}
                  className={address?.length > 0 ? 'filled' : ''}
                  value={address}
                  onChange={e => setAddress(e.target.value)}
                />
              </FormControl>
              <Flex gap={4}>
                <FormControl isRequired>
                  <FormLabel>City</FormLabel>
                  <Input
                    placeholder="City"
                    bg={'gray.100'}
                    border={0}
                    color={'gray.500'}
                    _placeholder={{
                      color: 'gray.500',
                    }}
                    className={city?.length > 0 ? 'filled' : ''}
                    value={city}
                    onChange={e => setCity(e.target.value)}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>State</FormLabel>
                  <Input
                    placeholder="State"
                    bg={'gray.100'}
                    border={0}
                    color={'gray.500'}
                    _placeholder={{
                      color: 'gray.500',
                    }}
                    className={state?.length > 0 ? 'filled' : ''}
                    value={state}
                    onChange={e => setState(e.target.value)}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Zip</FormLabel>
                  <Input
                    placeholder="Zip"
                    bg={'gray.100'}
                    border={0}
                    color={'gray.500'}
                    _placeholder={{
                      color: 'gray.500',
                    }}
                    className={zip?.length > 0 ? 'filled' : ''}
                    value={zip}
                    onChange={e => setZip(e.target.value)}
                  />
                </FormControl>
              </Flex>
              <FormControl isRequired>
                <FormLabel>Bio</FormLabel>
                <ReactQuill theme="snow" value={bio} onChange={setBio} />
              </FormControl>
            </Stack>
            <Button
              isDisabled={!isEnabled}
              fontFamily={'heading'}
              mt={8}
              w={'full'}
              bgGradient="linear(to-r, red.400,pink.400)"
              color={'white'}
              _hover={{
                bgGradient: 'linear(to-r, red.400,pink.400)',
                boxShadow: 'xl',
              }}
              onClick={() => handleFormSubmit()}
            >
              {isSending ? (
                <>
                  <Spinner
                    color="white.500"
                    size="sm"
                    style={{ marginRight: '10px' }}
                  />
                  Saving
                </>
              ) : (
                'Submit'
              )}
            </Button>
          </form>
        </Stack>
      </Container>
    </Box>
  );
}
