export const certifiedMembersData = [
  {
    id: 0,
    displayName: 'DR. SABINE HAZAN',
    photoURL: '/squad/Sabine.jpg',
    employer: 'Ventura Clinical Trials / Progenabiome',
  },
  {
    id: 1,
    displayName: 'DR. THOMAS BORODY',
    photoURL: '/squad/Thomas.jpg',
    employer: 'Centre for Digestive Diseases, Australia',
  },
  {
    id: 2,
    displayName: 'DR. NEIL STOLLMAN',
    photoURL: '/squad/Neil.jpg',
    employer: 'UCSF / East Bay Center for Digestive Health',
  },
  {
    id: 3,
    displayName: 'DR. ALEX KHORUTS',
    photoURL: '/squad/Alex.jpg',
    employer: 'University of Minnesota',
  },
  {
    id: 4,
    displayName: 'DR. COLLEEN KELLY',
    photoURL: '/squad/Colleen.jpg',
    employer: 'Brown University',
  },
  {
    id: 5,
    displayName: 'DR. SCOTT JACKSON',
    photoURL: '/squad/Scott.png',
    employer: 'National Institute of Standards and Technology (NIST)',
  },
  {
    id: 6,
    displayName: 'DR. YINGHONG WANG',
    photoURL: '/squad/Wang.png',
    employer: 'MD Anderson Cancer Center',
  },
  {
    id: 7,
    displayName: 'DR. PAUL FEUERSTADT',
    photoURL: '/squad/Paul.png',
    employer: 'Yale School of Medicine',
  },
  {
    id: 8,
    displayName: 'DR. HOWARD YOUNG',
    photoURL: '/squad/Howard.png',
    employer: 'National Institutes of Health (NIH)',
  },
  {
    id: 9,
    displayName: 'DR. FAMING ZHANG',
    photoURL: '/squad/Faming.png',
    employer: 'Nanjing Medical University, China',
  },
  {
    id: 10,
    displayName: 'DR. ANDEAS PAPOUTSIS',
    photoURL: '/squad/Andreas.png',
    employer: 'ProgenaBiome',
  },
  {
    id: 11,
    displayName: 'DR. JAMES B. ADAMS',
    photoURL: '/squad/James.png',
    employer: 'Arizona State University',
  },
  {
    id: 12,
    displayName: 'DR. SAHIL KHANNA',
    photoURL: '/squad/Sahil.png',
    employer: 'MAYO CLINIC',
  },
];

export const certifiedMembersType = [
  {
    id: 0,
    value: 'MD',
  },
  {
    id: 1,
    value: 'DO',
  },
  {
    id: 2,
    value: 'PhD',
  },
  { id: 3, value: 'Nutritionist/Dietician' },
  { id: 4, value: 'FNP' },
  { id: 5, value: 'PA' },
  { id: 6, value: 'NP' },
  { id: 7, value: 'Other' },
];

export const certifiedMembersConferenceType = [
  {
    id: 0,
    value: 'MD',
  },
  {
    id: 1,
    value: 'DO',
  },
  { id: 2, value: 'PhD' },
  { id: 3, value: 'Nutritionist/Dietician' },
  { id: 4, value: 'FNP' },
  { id: 5, value: 'PA' },
  { id: 6, value: 'NP' },
  { id: 7, value: 'PharmD' },
  { id: 8, value: 'Other' },
];
