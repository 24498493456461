import React from 'react';
import { Heading, Spinner, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

function Loader({ hideLogo }) {
  const navigate = useNavigate();
  return (
    <div>
      <div
        style={{
          height: '80vh',
          width: '80vw',
          margin: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {!hideLogo && (
          <Text
            fontFamily={'heading'}
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/')}
          >
            <Heading fontSize="2em" fontFamily="Contrail One">
              BIOME SQUAD
              <span
                className="trademark"
                style={{ fontFamily: 'Contrail One, sans-serif' }}
              >
                ™
              </span>
            </Heading>
          </Text>
        )}

        <Spinner
          thickness="8px"
          speed="0.65s"
          emptyColor="#eee"
          color="#4299e1"
          style={{ height: '60px', width: '60px' }}
        />
      </div>
    </div>
  );
}

export default Loader;
