// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDfAi_8VHFsicH-txEG4LvXKw3Vb3XE7S4',
  authDomain: 'gutapp-f8772.firebaseapp.com',
  projectId: 'gutapp-f8772',
  storageBucket: 'gutapp-f8772.appspot.com',
  messagingSenderId: '335120081982',
  appId: '1:335120081982:web:1c536879442a5bd1d31d6f',
  measurementId: 'G-0NSJKM2JVE',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const db = getFirestore(app);
const auth = getAuth(app);

export const fire = app;
export const fireStorage = storage;
export const fireStore = db;
export const authFire = auth;
