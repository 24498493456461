import React, { useEffect, useState } from 'react';
import {
  Button,
  Heading,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from '@uidotdev/usehooks';

const labHeaders = [
  'order id',
  'flora number',
  'order date',
  'patient email',
  'patient',
  'patient dob',
  'status',
];

function AdminLabs({ data }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState(null);
  const debouncedSearchTerm = useDebounce(searchQuery, 300);
  const [flatLabs, setFlatLabs] = useState([]);

  useEffect(() => {
    const searchHN = async () => {
      let results = [];
      if (debouncedSearchTerm) {
        const array = flatLabs.map(d => ({
          ...d,
          dataString: Object.values(d).join(),
        }));
        const fArray = array.filter(a =>
          a.dataString.toLowerCase().includes(searchQuery?.toLowerCase())
        );
        setFilteredData(fArray);
      } else {
        setFilteredData(flatLabs);
      }
    };
    searchHN();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (!data) return;
    const fLabs = data
      .map(d => {
        if (d.labs) {
          console.log(d.labs);
          return d.labs?.map(dd => ({ ...dd, providerId: d.id }));
        }
      })
      .flat();
    setFlatLabs(fLabs.filter(f => f));
    setFilteredData(fLabs.filter(f => f));
  }, [data]);

  return (
    <div id="patients" className="page-padding">
      <Heading
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        fontSize={{
          base: '4xl',
          md: '5xl',
        }}
      >
        Labs
        {/* <Button colorScheme="blue" onClick={() => setIsOpen(true)}>
          Add Patient
        </Button> */}
        <Input
          width={400}
          borderRadius={20}
          focusBorderColor="blue.400"
          placeholder="Search..."
          className={searchQuery?.length > 0 ? 'filled' : ''}
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
      </Heading>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              {labHeaders.map(l => (
                <Th>{l}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {filteredData?.map(l => (
              <Tr
                cursor="pointer"
                onClick={() => {
                  let selection = window.getSelection().toString();
                  if (selection.length <= 0) {
                    navigate(`/admin/user/${l?.providerId}/lab/${l?.id}`);
                  }
                }}
              >
                <Td className="id">{l?.id}</Td>
                <Td className="floraNumber">{l?.floraNumber}</Td>
                <Td className="date">
                  {new Date(l?.date).toLocaleDateString()}
                </Td>
                <Td className="email">{l?.patient?.email}</Td>
                <Td className="firstName">
                  {l?.patient?.firstName} {l?.patient?.lastName}
                </Td>
                <Td className="dob">
                  {new Date(l?.patient?.dob).toLocaleDateString()}
                </Td>
                <Td className="status">{l?.status}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default AdminLabs;
