export const meetingData = [
  {
    heading: 'Prelude/Introduction',
    description: 'Sabine Hazan, MD',
    videoUrl: 'https://www.youtube.com/embed/2rNSQXoVm5k?si=lsApw20CTwIZ7r-n',
  },
  {
    heading: 'Microbiome 101',
    description: 'Neil Stollman, MD, FACP, FACG, AGAF',
    videoUrl: 'https://www.youtube.com/embed/11qiUAIVb4o?si=YHuNnehZQSpYCf8P',
  },
  {
    heading: 'Implications of the Gut Microbiome in Cancer',
    description: 'Yinghong Wang, MD, PhD, MS',
    videoUrl: 'https://www.youtube.com/embed/h_Fj-iAzgIg?si=_QhafsMzpfja2BZ_',
  },
  {
    heading: 'Developing Standards for Microbiome Research & Development',
    description: 'Scott Jackson, PhD',
    videoUrl: 'https://www.youtube.com/embed/5SIhirryzPE?si=e8udsCW-ANW4rcRJ',
  },
  {
    heading: 'From Fecal Transplants to Microbiota-Based Therapeutics',
    description: 'Alexander Khoruts, MD',
    videoUrl: 'https://www.youtube.com/embed/pu35AQCCjY0?si=hg33dOPcJbHZXuXf',
  },
];
