import { Container, Heading } from '@chakra-ui/react';
import React from 'react';

function Terms() {
  return (
    <Container
      className="legal"
      justifyContent="center"
      maxW={'7xl'}
      mt={50}
      mb={50}
      py={{ base: 5, sm: 10, lg: 15 }}
    >
      <Heading
        color={'gray.800'}
        lineHeight={1.1}
        display="flex"
        fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
      >
        Terms of Service
      </Heading>
      (Effective: October 1, 2023)
      <p>
        These Terms of Service (the “Terms”) contain essential terms and
        conditions regarding your use of our Services and Products, including
        any materials, data, information, and content available therethrough.
        PLEASE REVIEW THE TERMS VERY CAREFULLY BEFORE USING OUR SERVICES. We
        encourage you to contact us at info@thebiomesquad.com with any questions
        or concerns before proceeding any further with our Services or Products.
      </p>
      <p>
        The Terms incorporate The Biome Squad’s Privacy Policy by reference in
        its entirety – as though herein completed stated – and all references to
        these Terms shall refer, without limitation, to our Privacy Policy, as
        above-linked. Please accordingly review our Privacy Policy very
        carefully.
      </p>
      <p>
        AN IMPORTANT NOTE: THESE TERMS AND CONDITIONS REQUIRE THE BINDING
        ARBITRATION OF DISPUTES ON AN INDIVIDUALIZED BASIS. EXCEPT AS HEREIN
        PROVIDED, BY AGREEING TO THESE TERMS AND CONDITIONS, YOU HEREBY WAIVE
        YOUR RIGHTS TO: (A) SEEK RELIEF IN A COURT OF LAW; (B) HAVE DISPUTES
        DECIDED BY JUDGE OR JURY; AND (C) PARTICIPATE IN A CLASS ACTION LAWSUIT
        AGAINST THE BIOME SQUAD. PLEASE REVIEW SECTION 16 FOR MORE DETAILS.
      </p>
      <p>
        The Terms constitute a legally-binding contract between you and The
        Biome Squad that governs your access to and use of our services and
        products, including but not limited to: (a) your use of the website
        thebiomesquad.com (the “Site”) and its features; and (b) any purchases
        and/or uses of our Services. BY ACCESSING AND/OR MAKING USE OF OUR
        SERVICES, INCLUDING WITHOUT LIMITATION VIA ONE OR MORE OF OUR WEBSITES,
        ADVERTISEMENTS, AND/OR APPLICATIONS, YOU REPRESENT THAT YOU FULLY
        UNDERSTAND AND AGREE TO BE UNCONDITIONALLY BOUND BY THE TERMS IN THEIR
        ENTIRETY, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
      </p>
      <p>
        Our Services are only available to persons eighteen (18) years of age or
        older. You must close your browser, and you may not access or use our
        Services or Products if you are under the age of eighteen (18). At this
        time, The Biome Squad does not allow you to create an account for or
        provide information relating to anyone under the age of eighteen (18).
      </p>
      <p>1. Definitions</p>
      <p>
        a. “The Biome Squad,” the “Company,” “us,” “our,” and “we,” all refer to
        The Biome Squad Health, Inc., a Delaware corporation.
      </p>
      <p>
        b. “You”, “you,” “your”, and “yourself,” and “User” refers to you, as an
        individual, plus any entity, individual, and/or organization on whose
        behalf you are acting in connection with our Services (defined below).
      </p>
      <p>
        c. “Party” means each of you and The Biome Squad. Collectively, you and
        The Biome Squad are the “Parties.”
      </p>
      <p>
        d. “Affiliates” refer to an entity’s executives, employees, officers,
        directors, agents, attorneys, representatives, partners, joint ventures,
        parents, subsidiaries, successors, and assigns, collectively. An
        “Affiliate” refers to any one of the foregoing.
      </p>
      <p>
        e. “Claims” refer to claims, disputes, controversies, matters, and
        causes of action, collectively. A “Claim” refers to any one of the
        foregoing.
      </p>
      <p>
        f. “Losses” refers to liabilities, harms, losses, debts, damages,
        awards, expenses, fees, costs, penalties and fines, collectively. A
        “Loss” refers to any one of the foregoing.
      </p>
      <p>
        g. “Sample(s)” refer to any and all blood samples provided by The Biome
        Squad’s members to independent third-party laboratories for reporting in
        connection with The Biome Squad’s Services.
      </p>
      <p>
        h. “Lab Results” refer to the results of any and all Sample analyses;
        however, Lab Results do not include the formatting of your Lab Results,
        the graphic representation of your Lab Results, or any other FH Content
        accompanying your or otherwise furnished in connection with your Lab
        Results obtained from independent third-party laboratories.
      </p>
      <p>
        i. “Product(s)” refer to any and all products offered for sale and/or
        use in connection with our Services.
      </p>
      <p>
        j. “Services” refer to any and all services arising out of and/or
        relating to The Biome Squad. Our Services includes, without limitation:
        (a) facilitating convenient access to third-party laboratory services
        for the purposes of collecting Samples, (b) providing convenient access
        to Lab Results in conjunction with those Samples and furnishing a
        detailed yet comprehensible and consumer-friendly report relating
        thereto, (c) facilitating opportunities to connect with third-party
        medical professionals regarding ordering of tests and related Lab
        Results, and (d) providing informational content relating to health,
        wellness, fitness, diet, and lifestyle, including but not limited to in
        connection with a set of parameters in Lab Results. Our Services further
        include, without limitation, our software applications, text and SMS
        messages, websites, emails, social media accounts, and the creation,
        publication, exhibition, and distribution of FH Content. NEITHER The
        BIOME SQUAD NOR OUR AFFILIATES PROVIDES ANY MEDICAL ADVICE, CARE, OR
        OPINIONS IN CONNECTION WITH OUR SERVICES, AND YOUR USE OF OUR SERVICES
        DOES NOT ESTABLISH A DOCTOR-PATIENT RELATIONSHIP WITH The BIOME SQUAD OR
        OUR AFFILIATES.
      </p>
      <p>
        k. “FH Content” refers to any and all data, information, content,
        material, and other intellectual property available through our Services
        in various formats – including but not limited to as code, data, text,
        images, multimedia content, designs, graphics, illustrations,
        photographs, banners, messages, videos, visual interfaces, features,
        functionalities, software applications and, but specifically excluding
        PII and User Content.
      </p>
      <p>
        l. “User Content” refers to any text, photos, graphics, images, video,
        audio, multimedia, and other materials you create, modify, post,
        provide, upload, send, and/or share in connection with our Services.
        User Content specifically does not include any user passwords, contact
        information, PII, or “protected health information” or “medical
        information” that is protected under the Health Insurance Portability
        and Accountability Act of 1996 or other applicable law– those items are
        subject to their own rules, as explained in our Privacy Policy – in
        public-facing text, photo(s), graphics, image(s)...and/or other
        materials.
      </p>
      <p>
        m. “Payment Information” refers to your financially-sensitive
        information that enables a purchase, such as credit/debit card numbers,
        CVCs, expiration dates, and PINs.
      </p>
      <p>
        n. “Payment Method,” refers to a valid, current, accepted means of
        remitting payment for Services, as permitted by The Biome Squad –
        typically by submitting valid, current, accepted Payment Information.
      </p>
      <p>
        o. “PII” refers to any personally identifiable information that – by
        itself or in conjunction with other information – identifies, describes,
        is associated with, or could reasonably be linked to a particular
        individual or household (e.g. a person’s name, address info, phone
        number, email address, account number), as well as information relating
        to an individual’s personal or demographic background (e.g. age,
        occupation, race/ethnicity), health background (e.g. medical history and
        related data, blood type, current health conditions/symptoms), Sample,
        and/or Lab Results (to the extent it is not considered “protected health
        information” or “medical information” that is protected under the Health
        Insurance Portability and Accountability Act of 1996 or other applicable
        law, as more specifically described in our Privacy Policy). PII also
        includes any Payment Information.
      </p>
      <p>2. The Biome Squad Does Not Provide Any Medical Services.</p>
      <p>
        In order to use of our Services, you must acknowledge and agree to the
        following:
      </p>
      <p>
        a. THE BIOME SQUAD DOES NOT OFFER YOU MEDICAL ADVICE, A DIAGNOSIS,
        MEDICAL TREATMENT, OR ANY FORM OF A MEDICAL OPINION, THROUGH OUR
        SERVICES OR OTHERWISE. All material, information, data, and content that
        The Biome Squad provides through our Services is strictly for general
        information purposes.
      </p>
      <p>
        b. OUR SERVICES ARE NOT A SUBSTITUTE FOR QUALIFIED MEDICAL CARE,
        QUALIFIED MEDICAL ADVICE, AND/OR A DETAILED DISCUSSION WITH YOUR OWN
        QUALIFIED HEALTHCARE PROFESSIONAL. The Biome Squad strongly recommends
        that you seek the advice of your own qualified healthcare professional
        to assist you in making decisions regarding any diagnosis, treatment,
        course, and/or care – including without limitation in relation to any
        data and/or information we provide – and you agree to follow this
        recommendation. You covenant that you will not delay in seeking care or
        otherwise ignore the advice or direction of a qualified medical
        professional based on your use of our Services. If you are concerned
        that you may be experiencing a medical emergency, please dial 9-1-1
        immediately. You understand and agree that accessing The Biome Squad
        Content, Services, and Products does not create a patient relationship
        with any medical provider or laboratory. A patient relationship with a
        medical provider or laboratory is only established when you have
        actually been treated by a medical provider or laboratory. In addition,
        you acknowledge and agree that you have selected each medical provider
        or laboratory and elected to receive services from the medical provider
        or laboratory and that no third-party, including The Biome Squad, has
        referred, suggested or recommended the medical providers or laboratories
        to you.
      </p>
      <p>
        c. To the fullest extent permitted by applicable law, you agree that you
        use our Services and rely on the materials, data, content, and
        information you receive in connection our Services – including without
        limitation the FH Content – at your own risk. To the fullest extent
        permitted by applicable law, The Biome Squad disclaims all liability and
        responsibility arising out of and/or relating to any reliance placed by
        you and/or any other person on our Services and/or any data and/or
        information you receive in connection with our Services, including
        without limitation as relating to data and information relating to Lab
        Results and/or Content.
      </p>
      <p>
        3. Please Exercise Caution Regarding Our Services & Your Lab Results.
      </p>
      <p>
        a. The Biome Squad sincerely hopes to provide you access to the Lab
        Results you most wish to receive. Nonetheless, you acknowledge that your
        Lab Results might not be as hoped or expected, and may even contain
        distressing and/or life-altering information. While it is not unusual to
        feel a little bit anxious about pending Lab Results, you also
        acknowledge that you should consult a qualified healthcare professional
        if such anxiety interferes with your daily life.
      </p>
      <p>
        b. You acknowledge that the process of reviewing Lab Results is
        irreversible; once you review your Lab Results, you are committing
        yourself to an awareness of such Lab Results. You accordingly
        acknowledge that – before using our Services – it is important to seek
        the advice of a qualified healthcare professional regarding whether,
        when, and to what extent you should make use of such Services given your
        medical history and personal circumstances.
      </p>
      <p>
        c. Obtaining some laboratory testing involve your provision of a blood
        sample. The risks associated with obtaining a blood sample include
        bruising, hematoma, lightheadedness, fainting, infection, and/or
        excessive bleeding, and you may feel slight-to-moderate physical
        discomfort upon being pricked with a needle and having your blood drawn.
        Please make sure you consult with a qualified physician if you are at
        elevated risk of harm in connection with a blood draw, or if you suffer
        from a fear of needles or blood.
      </p>
      <p>
        d. While The Biome Squad strives to facilitate access to complete and
        accurate Lab Results 100% of the time, you acknowledge and accept that:
        (a) your Sample may yield incomplete or even inaccurate Lab Results; and
        (b) The Biome Squad does not warrant the accuracy, completeness,
        usefulness, timeliness, or precision of any Lab Results delivered or
        accessed through our Services. You further acknowledge and agree that
        your Lab Results may yield data or information that may have a limited
        significance presently, but may take on greater meaning as science
        evolves, knowledge increases, and/or future discoveries are made.
      </p>
      <p>
        e. You acknowledge that The Biome Squad recommends your careful
        discretion in deciding what aspects of your Lab Results to share, how to
        share them (e.g., we strongly advise against posting Lab Results on
        social media), and with whom. You further acknowledge that, to the
        extent you disclose your Lab Results, or any other PII, with a
        healthcare provider, such data and information may become part of your
        medical records and may thereby be available to insurance companies
        and/or other healthcare providers, except as limited by applicable law.
      </p>
      <p>4. Only Eligible Individual May Use Our Services.</p>
      <p>
        a. Eligibility. By accessing our Services, you represent that you are of
        sound mind and body to agree to our Terms and are in possession of the
        full rights and/or authorization to: (a) consent to our Terms, (b) agree
        to and pay for any purchase made by you, whether for your own benefit or
        the benefit of a third party, (c) authorize all rights and licenses
        herein granted to The Biome Squad and assume all responsibilities,
        liabilities, debts, and obligations herein required of you, and (d)
        submit any PII and other data and information provided in connection
        with our Services, whether for your own benefit or the benefit of a
        third party. You must be eighteen (18) years of age to make use of The
        Biome Squad’s Services.
      </p>
      <p>
        b. Non-Eligibility. We reserve the right to terminate your use of and
        access to, our Services – and cancel any purchase thereunder – for any
        violation of our Terms or any provision of any other agreement between
        you and The Biome Squad. Furthermore, you may not use our Services if
        you are: i. Currently restricted or prohibited from using our Services;
        ii. A past, present, or imminent competitor of The Biome Squad and/or
        closely connected to one or more of our past, present, or imminent
        competitors; iii. Acting on behalf of another person or entity without
        that person or entity’s permission; iv. Under a legal obligation that
        restricts and/or precludes your use of our Services; and/or v. Otherwise
        unwilling to fully agree to and follow all of our Terms.
      </p>
      <p>
        c. No Guarantees re: Eligibility. While we make every effort to keep a
        tight-knit community based on strict eligibility criteria, we make no
        warranty or representation that every user satisfies our eligibility
        criteria; there may be ineligible individuals who still gain access to
        and/or use our Services. d. Immediate Termination. You agree to close
        your browser window and cease from all further use of our Services if
        you are or become ineligible to use these Services. You further agree to
        email info@thebiomesquad.com with the subject “End Use” with details
        about your reason for ceasing all further use.
      </p>
      <p>
        e. Additional Terms & Conditions. You acknowledge that – in order to
        purchase and/or use certain Services and/or Products –The Biome Squad
        may require you to enter into one or more separate agreements and/or
        accept additional terms and conditions, which are hereby incorporated by
        reference, in their entirety, into these Terms. To the extent that The
        Biome Squad requires your consent to further terms and conditions, you
        will have opportunity to review such terms and conditions before
        proceeding to purchase and/or use such Services and/or Products. Insofar
        as there is any conflict between such terms and conditions and these
        Terms, the former will take precedence, but only with respect to such
        conflicting terms and/or conditions.
      </p>
      <p>
        5. If Eligible, You Are Granted a Limited License to Make Use of Our
        Services.
      </p>
      <p>
        a. As long as you remain eligible, you are granted a limited,
        non-exclusive, non-assignable, non-transferable, non-sublicensable,
        non-delegable, and fully revocable license to access and make use of our
        Services for personal, non-commercial use in accordance with these
        Terms. The Biome Squad reserves the right to suspend, limit, or revoke
        this license, for any reason, with or without warning or notice, in our
        sole and absolute discretion.
      </p>
      <p>
        i. You acknowledge and agree that this license, unto itself, does not
        grant you permission to use Services requiring payment without first
        remitting payment. You accept that, in order to receive certain Products
        and Services, you may be required furnish Payment Information and remit
        payment, as directed by The Biome Squad.
      </p>
      <p>
        ii. You are permitted to download and/or print a reasonable volume of FH
        Content for your personal, non-commercial use in compliance with the
        Terms. You are also permitted to download a single copy of any
        applications that we make available for your download, provided such a
        download is for your personal, non-commercial use in compliance with the
        Terms and any additional agreements required for you to download,
        access, and/or use such an application. To the extent our features
        enable you to share FH Content via social media, you are permitted to
        share FH Content strictly as enabled by those features.
      </p>
      <p>
        iii. You are permitted to store files that are automatically cached by
        your web browser. You are also permitted to temporarily store copies of
        FH Content in RAM, insofar as such storage is incident to your access
        and/or use of such FH Content.
      </p>
      <p>
        b. You are allowed to create a single account for yourself, as an
        individual eighteen (18) years of age or older, in order to make use of
        our Services (an “Account”). You are also allowed to create an Account
        for each adult who has given you prior express written authorization to
        agree to these Terms and operate an Account on her/his behalf. You
        accept that, in order to create an Account, The Biome Squad may require
        you to provide certain PII and a login/password; this includes, without
        limitation furnishing a first and last name, date of birth, address,
        email address, race/ethnicity, height/weight, blood type, and
        information regarding your health history, your present health
        condition, and any symptoms. You represent that you have provided and
        warrant that you will provide truthful, accurate, complete, up-to-date
        information at all times in connection with our Services; you further
        covenant that you will update any information that you furnish if, and
        as soon as, you learn that it is inaccurate, incomplete, and/or
        outdated; this applies, without limitation, to PII.
      </p>
      <p>
        c. You acknowledge and accept the The Biome Squad will attribute all
        actions and usage of your Account to you. To the fullest extent
        permitted by applicable law, you are solely responsible for the security
        of your password, Account information, and any PII thereto connected;
        you agree to maintain all such information as confidential at all times,
        and you agree to accept full responsibility for any decision to disclose
        and/or failure to protect your such information to the fullest extent
        permitted by applicable law. In this connection, to the fullest extent
        permitted by applicable law, you agree (i) that you are solely
        responsible for any actions and omissions relating to your Account
        and/or its nexus to our Services, including as it relates to the use
        and/or misuse of PII, and (ii) you shall not hold The Biome Squad or its
        Affiliates liable for any compromise of or any unauthorized access to
        and/or use of your PII, or for otherwise relying on the authority of
        anyone using your login credentials and/or otherwise accessing your
        Account. You shall immediately alert us to any breach of your Account.
        You acknowledge and agree that you shall remain particularly vigilant of
        your Account security when using a public or shared network and/or
        computer. If you have an Account, you promise to exit from your Account
        at the end of each session using our Services.
      </p>
      <p>
        d. By entering into these Terms, you consent to the use of unsecured
        email, mobile phone text message, or other electronic methods of
        communication (“E-messages”) between you and The Biome Squad for
        purposes of discussing information relevant to the Services you receive
        from The Biome Squad. You hereby acknowledge that E-Messages are
        typically not a confidential means of communication, and that there is a
        reasonable chance that a third-party (e.g. people in your home or other
        environments who can access your phone, computer, or other devices; your
        employer if using your work email or network; and/or third parties on
        the Internet, such as. server administrators and others who monitor
        Internet traffic) may be able to intercept and see E-Messages, including
        without limitation any attachments thereto and content therein. You have
        been informed of the risks of transmitting your PII by an unsecured
        means, including but not limited to the risk with respect to the
        confidentiality of such information.
      </p>
      <p>
        e. You may elect to deactivate your account with The Biome Squad, cease
        using our Services, delete your account, and/or terminate your agreement
        to these Terms at any time by sending an email to
        info@thebiomesquad.com.
      </p>
      <p>
        i. You acknowledge and agree that your decision to deactivate, cease
        usage, and/or terminate this Agreement does not automatically entitle
        you to a credit, rebate, refund, or discount and may result in the
        removal and/or deletion of your Account, your PII, and/or your User
        Content, at The Biome Squad’s sole election and discretion.
      </p>
      <p>
        ii. Termination of your agreement to these Terms shall not prejudice or
        affect any right of action or remedy which has accrued or will
        thereafter accrue to either Party with respect to a breach of any
        representations, warranties, covenants, and/or other obligations
        hereunder. Each term and condition within these Terms that, either
        expressly or by implication and/or nature, is reasonably intended to
        survive the termination of this Agreement shall so survive – until its
        purpose is fulfilled or moot, or otherwise indefinitely – regardless of
        which Party terminates this Agreement; this includes, without limitation
        terms and conditions pertaining to (a) waiver, (b) assumption of risk,
        (c) limitation and/or exclusion of liability, (d) indemnification, (e)
        applicable representations, warranties and covenants, and (f) The Biome
        Squad’s rights and licenses with respect to your data, information and
        User Content. The Parties acknowledge and agree that, insofar as any of
        the Terms contain one or more provisions which are not applicable as to
        this Subsection 5.d the parties acknowledge and agree that the
        inapplicable provision(s) shall simply be severed from interpretation,
        and that the rest of the provisions shall survive as described in this
        Subsection 5.d.ii.
      </p>
      <p>
        iii. As permitted by applicable law, The Biome Squad may still retain
        and use your PII after you have deleted your Account. For more
        information, please review our Privacy Policy.
      </p>
      <p>6. Further User Representations, Warranties, & Covenants.</p>
      <p>
        a. You covenant that you shall not sell, rent, lease, barter, recreate,
        reproduce, copy, modify, create derivative works from, license,
        sublicense, distribute, publish, republish, perform, transmit, transfer,
        display, distribute, exhibit, exploit, and/or make commercial use of our
        Services, or any aspect thereof (including without limitation the FH
        Content), without the prior express written consent of The Biome Squad
        and/or our applicable third party licensor and/or provider; you
        acknowledge that you are strictly forbidden from doing any of the
        foregoing and represent that you have not done any of the foregoing up
        to this point in time.
      </p>
      <p>
        b. You covenant that you shall not directly or indirectly resell or
        export any aspect of our Services or any of our Products – including
        without limitation our facilitation of access to third-party laboratory
        and telemedicine services, any Lab Results, and any personalized
        materials, and any FH Content resulting therefrom and/or relating
        thereto – and you represent that you have not done any of the foregoing
        up to this point in time.
      </p>
      <p>
        c. You represent and warrant that you are neither an employer seeking
        information regarding an employee, nor a person acting on behalf and/or
        in furtherance of insurance company seeking information about a
        prospective or actual customer.
      </p>
      <p>
        d. You represent that you have not violated and covenant that you shall
        not violate any export control laws or other restrictions on shipping to
        foreign countries in the course of purchasing and/or making use of
        Services and/or Products.
      </p>
      <p>
        7. You Must Be Reasonable and Fair in Accessing and Using Our Services.
      </p>
      <p>
        a. The Biome Squad’s rules for access and use of our Services should be
        easy to follow. Nonetheless, here are a few key points to help you along
        the path: i. Treat The Biome Squad, our Affiliates, affiliated service
        providers, and our community members kindly and respectfully. ii. All
        info you provide must be complete, truthful, and up-to-date. iii. Do not
        attempt to disrupt our tech or misappropriate our I.P. iv. Follow the
        law at all times in connection with our Services and Products. b. You
        hereby represent that you have not used and warrant that you shall not
        use our Services to directly or indirectly attempt, engage in, aid,
        abet, support, incite, encourage, perpetrate, and/or otherwise assist
        any of the following:
      </p>
      <p>
        i. a violation of a local, state, federal, and/or international law,
        rule, or regulation; ii. a violation of municipal, administrative and/or
        professional rules, standards, guidelines, or requirements; iii. fraud,
        misrepresentation, impersonation, or identity theft; iv. the
        exploitation or harm of a minor, disclosure of a minor’s information, or
        violation of a minor’s privacy; v. the deliberate submission of false,
        misleading, invalid, outdated, or inaccurate PII; vi. an illegal and/or
        deceptive business practice; vii. a violation of our rights, the rights
        of our Affiliates, or the rights of any of our licensors, service
        providers, or any of their respective Affiliates; viii. a violation,
        infringement, or misappropriation of third-party rights, including
        without limitation infringements of third-party intellectual property
        rights and violations of third-party rights to privacy; ix. the
        unauthorized use or disclosure of a third-party’s PII; x. harassment,
        trolling, intimidation, stalking, hate speech, bigotry, racism,
        prejudice, threats of violence, and/or any other abusive conduct; xi.
        obscene, lewd, sexually explicit, and/or pornographic content; xii.
        slander, defamation, libel or disparagement; xiii. an effort to offer or
        render professional advice (e.g., medical, financial); xiv. any
        implication of an affiliation with or endorsement by The Biome Squad;
        and/or xv. an effort to disrupt or interfere with our Services and/or
        business operations.
      </p>
      <p>
        c. You promise that, in the course of using our Services, you shall not
        introduce and/or circulate malicious content, code, files, or programs,
        or otherwise engage in malicious conduct. Among other things, you
        acknowledge and agree that you are not permitted to engage in phishing,
        harvesting, spamming, or the introduction or circulation of viruses,
        spyware, worms, Trojan Horses, Easter eggs, keystroke loggers, time
        bombs, or logic bombs. Furthermore, in connection with our Services, you
        shall not engage in deceptive business practices, pursue unsolicited
        and/or unauthorized marketing or advertising, circulate a chain letter
        or junk mail, or facilitate or participate in a pyramid scheme, or
        undertake any other form of solicitation that adversely impacts one or
        more other user’s ability to use and/or enjoy our Services.
      </p>
      <p>
        d. You shall not make efforts to impair, disrupt, overburden, damage, or
        interfere with the proper working order of any technologies, systems, or
        operations, belonging to The Biome Squad, our Affiliates, our licensors,
        and/or any of our third-party providers. Under no circumstances shall
        you make efforts to modify or alter any aspect of our Services or the
        services of our Affiliates, licensors, and/or third-party providers.
        This paragraph includes but is not limited to a restriction on
        manipulation and/or interference with our websites, applications,
        scheduling systems and services, messaging systems and services,
        storefront technologies systems, and recordkeeping systems, as well as
        third-party blood draw services, third-party telemedicine service, and
        other third-party services arising out of and/or relating to our
        Services. You shall not attack our Services in any fashion, online or in
        person, including without limitation by a denial-of-services or
        distributed denial-of-services attack.
      </p>
      <p>
        e. Under no circumstances shall you make efforts to decompile,
        disassemble, reverse engineer, translate, render generically, modify,
        mimic, duplicate, or otherwise derive any aspect, feature, function, or
        detail concerning or relating to our Services, except to the extent that
        such a restriction is expressly forbidden by law; this clause includes,
        but is not limited to the modification, mimicking, duplication and/or
        rebranding any FH Content and/or any technology relating to our
        Services, as well as the display of FH Content separate from
        accompanying text or otherwise out of full context. You are not
        permitted to use the FH Content to develop any functions, programs,
        applications, technologies, or services that leverage the Services.
      </p>
      <p>
        f. You shall not make efforts to index, copy, extract, download, or
        otherwise copy or retrieve any of aspect of our Services, including
        without limitation the FH Content; this includes, without limitation, a
        prohibition on use of a spider, data miner, crawler, robot, or similar
        tool. You are not permitted to link or deep-link to our Site or any of
        our other Services. You may not utilize framing or mirroring techniques
        to enclose any item of FH Content (e.g., logo, name, text, images), and
        you are forbidden from using "hidden text" that references the FH
        Content without the prior express written consent of The Biome Squad
        and/or our third-party licensor(s). You may not and shall not: (i)
        delete, alter, or remove any copyright designations or notices,
        trademark designations or notices, or other proprietary designations or
        notices; or (ii) circumvent any digital rights management systems in
        connection with our Services; (ii) disable, disarm, bypass, or hack
        around any of our security systems. Notwithstanding the foregoing,
        operators of pubic search engines are granted a limited, revocable,
        non-sublicensable right to use spiders and provide links in connection
        with the FH Content strictly to assist in the legitimate development of
        a public index that reflects content and information appearing in
        publicly-facing areas of our Site.
      </p>
      <p>
        g. During the course of using our Services, you shall not sell, rent,
        lease, barter, recreate or reproduce, copy, create derivative works
        from, license, sublicense, distribute, publish, perform, transmit,
        transfer, display, distribute, exhibit, exploit, or otherwise make
        commercial use of any aspect of our Services, including without
        limitation any item or aspect of FH Content, without the prior express
        written consent of The Biome Squad and/or our applicable third-party
        licensor.
      </p>
      <p>
        h. You and you alone are responsible for your User Content. Any User
        Content that you provide to us is at your own risk of Losses. The Biome
        Squad shall not be responsible or liable for any Losses arising out of
        and/or relating to your User Content. 8. The Biome Squad Holds and
        Reserves Numerous Rights.
      </p>
      <p>
        a. At any time, for any reason, with or without notice, in our sole
        discretion, The Biome Squad reserves the right to update, improve,
        modify, alter, limit, suspend, discontinue, and re-introduce any of our
        Services – and any aspects, portions, details, features, specifications,
        and functions thereof – as well as adjust or modify any pricing,
        availability, and timeframes offered with respect to our Services.
        Without limitation, this paragraph pertains to all FH Content, all User
        Content, including without limitation your own, and all other
        technologies, applications, programs, designs, and offerings connected
        with and/or supporting our Services,
      </p>
      <p>
        i. You agree that The Biome Squad reserves the right to supplement,
        delete, or otherwise modify some of all these Terms – or any portions
        thereof – at any time, for any reason, with or without notice, in our
        sole discretion. In the event that The Biome Squad revises these Terms,
        your continued and/or subsequent access and/or use of our Services will
        constitute your unconditional acceptance of the revised Terms. You
        acknowledge and accept that you are expected to check-back with the page
        on our Site containing our Terms, as any changes are binding on you.
        This paragraph applies, without limitation, to The Biome Squad’s Privacy
        Policy, which is incorporated by reference into these Terms in its
        entirety.
      </p>
      <p>
        ii. You acknowledge and agree that The Biome Squad may refuse, suspend,
        terminate, ban, or otherwise restrict access to our Services to any
        person, including you, at any time, for any reason, with or without
        notice, in our sole discretion, without liability for any Losses arising
        therefrom and/or relating thereto. You recognize and accept that The
        Biome Squad may restrict access to certain aspects of our Services to
        persons who create an Account and/or remit payment for certain Services.
        You agree that your acceptance of these Terms does not, in and of
        itself, grant you access to those areas of The Biome Squad’s Services
        requiring payment for access.
      </p>
      <p>
        iii. To the extent you enter into a separate agreement and/or consent
        for Services with The Biome Squad, and such an agreement or consent
        contains different termination terms than herein provided, the terms of
        such separate agreement and/or consent shall control termination as to
        and only as to the specific Services subject to such an agreement.
      </p>
      <p>
        b. You acknowledge that, to the extent permitted by applicable law, The
        Biome Squad reserves the right to decide how it will contact you
        regarding any service messages and other publicly-directed
        communications – among other options, we specifically reserve the right
        to use email, push notification, text/SMS message, telephone call, and a
        website banner - and you agree that any reasonable means of contact
        selected by The Biome Squad (including any of the aforementioned) shall
        suffice for timely and adequate notice; this provision, embraces,
        without limitation, communications concerning changes to one or more of
        the Terms (including without limitation one or more terms set forth in
        this Privacy Policy). You agree that all other notices, requests,
        consents, claims, demands, waivers, and other communications that The
        Biome Squad sends to you may be sent to the email that you provided to
        The Biome Squad when you registered for our Services – or as updated in
        connection with your account.
      </p>
      <p>
        c. To the fullest extent permitted by law, and except as otherwise
        specified in writing, The Biome Squad holds no obligation or
        responsibility to take action or refrain from acting with respect to the
        continuation, provision, maintenance, updating, improvement, renovation,
        and/or modification of: (a) our Services; (b) aspects, portions,
        details, features, specifications, and functions of our Services; (c)
        terms and /or conditions relating to our Services; and/or (d) material,
        data, information, and/or content arising out of or relating to our
        Services, including without limitation the FH Content.
      </p>
      <p>
        d. To the fullest extent permitted under law, all FH Content – and all
        copyright, trademark rights, service mark rights, patent rights, and
        other intellectual property rights and proprietary rights arising out
        of, relating to, and/or appearing in connection with the FH Content –
        are owned by us and/or one of our third-party licensors or other
        providers and are protected by the intellectual property laws of the
        United States and other countries. You acknowledge and agree that all
        names, logos, brands trademarks, service marks, slogans, copyright, and
        designs, and all rights thereto, arising out of, relating to, and/or
        appearing in connection with the FH Content belong exclusively to The
        Biome Squad and/or one of our third-party licensors or other providers,
        irrespective of the presence of any legal designation or lack thereof,
        and are subject to protection from misappropriation, misuse, blurring,
        tarnishing, dilution, impersonation, or other unauthorized exploitation
        under United States law and international law; this includes, without
        limitation, our trademarks, service marks, and/or logos for “The Biome
        Squad.” You acknowledge and agree that: (i) nothing in these Terms,
        express or implied, grants you any right or license to make use of any
        names, logos, trademarks, service marks, slogans, designs, patents,
        copyright, or other FH Content arising out of, relating to, and/or
        appearing in connection with our Services; and (ii) these Terms
        expressly forbid your use any names, logos, trademarks, service marks,
        slogans, designs, patents, copyright, or other FH Content arising out
        of, relating to, and/or appearing in connection with our Services
        without our prior express written consent and/or the prior express
        written consent of the third-party who owns and/or has the applicable
        rights to such intellectual property.
      </p>
      <p>
        e. To the fullest extent permitted under law, The Biome Squad reserves
        any and all rights and interests not expressly granted by these Terms.
        You acknowledge and agree that: (i) no right, title, or interest in and
        to the FH Content is transferred to you; and (ii) your provision of a
        Sample or any PII does not afford you any right, title, or interest in
        and to any research by The Biome Squad, our Affiliates, our licensors,
        and/or our third-party providers or any related Services, Products,
        documents, data, information, materials, or offerings; you acknowledge
        and accept that you shall not receive any financial benefits, such as
        royalties or compensation, by virtue of your provision of the same.
      </p>
      <p>
        f. Subject to the conditions set forth in these Terms (including but not
        limited to our Privacy Policy), you hereby grant The Biome Squad a
        perpetual, irrevocable, non-exclusive, royalty-free, fully
        sub-licensable, fully transferrable worldwide right and license to
        exercise any and all rights you may have as to any User Content you
        disclose, generate and/or submit publicly arising out of and/or relating
        to your use of our Services. Likewise, subject to the conditions set
        forth in these Terms (including but not limited to our Privacy Policy),
        you agree that The Biome Squad may, in our sole discretion: copy,
        reproduce, adapt, translate, sell, distribute, syndicate, publish,
        transfer, communicate, modify, exhibit, advertise, market, create
        derivative works from, display, make commercial use of (including
        currently known and future, currently unknown commercial uses), and/or
        otherwise use your User Content throughout the world in any media in any
        manner, fashion and/or context we wish. You further agree that you waive
        any moral rights – including, but not limited to, the rights of
        attribution – in connection with any content and any data and
        information arising out of and/or relating to your use of our Services;
        provided, The Biome Squad reserves the right to attribute your User
        Content to you in our sole discretion, at any time, for any reason.
        Furthermore, you represent that you have full authority and right to
        grant these licenses and waive these rights pursuant to our Terms.
      </p>
      <p>
        g. You acknowledge and agree that any suggestions, ideas,
        recommendations, complaints, feedback, and other submissions
        (“Submissions”) you provide to The Biome Squad shall constitute User
        Content for the purposes of this Agreement, and shall accordingly be
        subject to all licenses, waivers, and other terms applicable to User
        Content hereunder. You recognize that not all User Content (including
        without limitation Submissions) is subject to protection under the
        applicable intellectual property laws. You acknowledge that The Biome
        Squad may already be in the process of creating, developing, finalizing,
        and/or commercializing products, services, content, or other materials
        that overlap with or are substantially similar one or more Submissions
        at the time you submit such Submission(s).
      </p>
      <p>
        9. It Is Important for You to Understand How The Biome Squad Uses Your
        Information.
      </p>
      <p>
        a. The Biome Squad’s Privacy Policy can be found here. Once again, we
        urge you to carefully review our Privacy Policy, as it directly impacts
        how The Biome Squad handles your PII, among other types of data and
        information. You acknowledge and agree that The Biome Squad’s Privacy
        Policy is incorporated by reference into these Terms, in its entirety;
        by accepting our Terms, you agree to all terms and conditions in our
        Privacy Policy.
      </p>
      <p>
        b. The Biome Squad does not collect, store, analyze, or retain any
        Samples during the course of providing our Services. Please consult the
        terms and conditions of your laboratory services provider, for more
        information regarding the collection, storage, analysis and retention of
        any Sample you provide to a laboratory service provider that is
        facilitated through our Services.
      </p>
      <p>
        c. In furtherance of facilitating third-party laboratory testing and
        medical care services directly for you, you hereby expressly permit
        affiliated contractors and providers to (i) receive, handle, store,
        transport, and analyze your Sample; (ii) transmit and disclose documents
        and files containing and/or reflecting PII (including without limitation
        Lab Results) (1) within and among one other as necessary to furnish
        and/or facilitate the Services and (2) to persons and/or entities whom
        you specifically designate for receipt of your Lab Results.
      </p>
      <p>
        10. The Biome Squad Offers and Utilizes Third-Party Services and
        Products; However, We Are Not Responsible or Liable for Those Third
        Parties or Their Services or Products.
      </p>
      <p>
        During the course of using our Services, The Biome Squad may offer you
        the opportunity to connect with third-party providers; such providers
        may include, without limitation, payment processing providers,
        laboratory services providers, data processing and visualization
        providers, customer service specialists, and telemedicine providers.
        Notwithstanding the fact that The Biome Squad may help facilitate such
        opportunities with certain third-party medical providers and
        laboratories, you are free to use any medical provider or laboratory of
        your choosing. Upon your request, The Biome Squad will use best efforts
        to integrate information you provide to us from such providers into the
        The Biome Squad Service ecosystem. The Biome Squad may also offer you
        the opportunity to receive, view, and interact with content created
        and/or developed by a third-party. Furthermore, in conjunction with our
        Services, The Biome Squad may offer you the opportunity to purchase
        and/or receive third-party services, products, content, advertisements,
        devices, and offerings (“Offerings”). PLEASE CAREFULLY REVIEW THESE
        DISCLAIMERS, WAIVERS, AND LIMITATIONS OF LIABILITY WITH RESPECT TO
        THIRD-PARTY SERVICES AND PRODUCTS.
      </p>
      <p>
        a. NEITHER THE BIOME SQUAD NOR OUR AFFILIATES MAKES ANY WARRANTY OR
        REPRESENTATION REGARDING THE AVAILABILITY, QUALITY, EFFICACY, ACCURACY,
        RELIABILITY, UP-TO-DATENESS, USEFULNESS, TIMELINESS, SAFETY, COST,
        LEGALITY, OR RESULTS OF ANY THIRD PARTY’S OFFERINGS – OR ANY
        INFORMATION, RECOMMENDATIONS, OPINION, GUIDANCE, COURSE, TREATMENT,
        ADVICE, OR CARE PROVIDED IN CONNECTION THEREWITH.
      </p>
      <p>
        b. NOTWITHSTANDING ANY INTERVIEWS, BACKGROUND CHECKS, OR OTHER VETTING
        PROCEDURES WE MAY OPT TO CONDUCT, NEITHER THE BIOME SQUAD NOR OUR
        AFFILIATES MAKES ANY WARRANTY, REPRESENTATION, GUARANTEE,
        RECOMMENDATION, REFERRAL, OR OPINION REGARDING ANY THIRD-PARTY
        PROVIDER’S QUALIFICATIONS, CREDENTIALS, LICENSING, QUALITY OF WORK,
        CORRECTNESS OF OPINION, OR SOUNDNESS OR APPLICABILITY OF ADVICE. NEITHER
        THE BIOME SQUAD NOR OUR AFFILIATES MAKES ANY ENDORSEMENT, WARRANTY,
        REPRESENTATION, GUARANTEE, RECOMMENDATION, REFERRAL, OR OPINION AS TO
        ANY SPECIFIC THIRD-PARTY PROVIDER OPINION, RECOMMENDATION, COURSE,
        TREATMENT, PLAN, ITEM OF ADVICE, OR OTHER OFFERING. YOU ACKNOWLEDGE THAT
        YOU HAVE SELECTED EACH MEDICAL PROVIDER OR LABORATORY ON YOUR OWN AND
        THAT NO THIRD PARTY, INCLUDING THE BIOME SQUAD, HAS REFERRED, ARRANGED
        OR RECOMMENDED ANY MEDICAL PROVIDER OR LABORATORY TO YOU.
      </p>
      <p>
        c. YOU ACKNOWLEDGE AND AGREE THAT, TO THE FULLEST EXTENT PERMITTED BY
        APPLICABLE LAW, NEITHER THE BIOME SQUAD OR NOR OUR AFFILIATES SHALL HOLD
        ANY LIABILITY OR RESPONSIBILITY WITH RESPECT TO ANY THIRD PARTY ADVICE,
        INFORMATION, OPINION, GUIDANCE, PLAN, COURSE OF TREATMENT, CARE, TEST
        RESULT, OR OTHER OFFERING: YOU AGREE, TO THE FULLEST EXTENT PERMITTED BY
        APPLICABLE LAW, TO RELEASE THE BIOME SQUAD AND HOLD THE BIOME SQUAD AND
        OUR AFFILIATES HARMLESS FROM ANY CLAIM ARISING OUT OF AND/OR RELATING TO
        ANY THIRD PARTY OFFERING. THE TERMS IN THIS PARAGRAPH EMBRACE, WITHOUT
        LIMITATION ANY LOSSES ARISING OUT OF AND/OR RELATING TO (i) THE
        COLLECTION, PROCESSING AND/OR ANALYSIS OF A SAMPLE BY A THIRD-PARTY
        LABORATORY SERVICES PROVIDER; (ii) THE COMMUNICATION OF LAB RESULTS BY A
        THIRD-PARTY LABORATORY SERVICES PROVIDER; (iii) ANY ADVICE, OPINION,
        GUIDANCE, PLAN, COURSE OF TREATMENT, OR CARE OFFERED BY A THIRD-PARTY
        MEDICAL, HEALTHCARE, AND/OR WELLNESS PROVIDER; (iv) ANY THIRD-PARTY
        CONTENT YOU ENCOUNTER WHILE USING OUR SERVICES: (v) ANY THIRD-PARTY
        ADVERTISEMENT PUBLISHED AND/OR CIRCULATED IN CONNECTION WITH OUR
        SERVICES; AND/OR (vi) ANY THIRD-PARTY PRODUCTS AND/OR DEVICES OBTAINED
        AND/OR USED BY YOU IN CONNECTION WITH OUR SERVICES. WE ENCOURAGE YOU TO
        RESEARCH APPLICABLE DOCTORS, SERVICE PROVIDERS, AND MEDICAL
        PRODUCTS/DEVICES – AND TO SEEK HELP ELSEWHERE OR RECEIVE A SECOND
        OPINION IF YOU ARE NOT 100% COMFORTABLE WITH ANY THIRD-PARTY ADVICE,
        GUIDANCE, OPINION, COURSE OF TREATMENT, PLAN, OR PROVIDER. YOU
        ACKNOWLEDGE THAT OUR SERVICES ARE NOT INTENDED FOR EMERGENCY PURPOSES;
        IN THE EVENT OF A MEDICAL EMERGENCY, YOU AGREE TO IMMEDIATELY CALL YOUR
        DOCTOR, VISIT YOUR NEAREST HOSPITAL, OR DIAL 9-1-1.
      </p>
      <p>
        d. NEITHER THE BIOME SQUAD NOR OUR AFFILIATES MAKE ANY REPRESENTATIONS,
        WARRANTIES, COVENANTS, OR OTHER PROMISES REGARDING THE ACTS OR OMISSIONS
        OF ANY THIRD-PARTY– INCLUDING WITHOUT LIMITATION ANY THIRD-PARTY MEDICAL
        SERVICES PROVIDER, FINANCIAL SERVICE PROVIDER, TECHNOLOGY SERVICES
        PROVIDER, CONTENT PROVIDER, ADVERTISER, OR MANUFACTURER OR DISTRIBUTER –
        EVEN IF THOSE THIRD-PARTIES OFFER PRODUCTS, CONTENT, OR SERVICES THAT
        THE BIOME SQUAD LINKS TO OR OTHERWISE FEATURES, ADVERTISES, AND/OR
        BUNDLES IN CONNECTION WITH OUR SERVICES. YOU ACKNOWLEDGE THAT NEITHER
        THE BIOME SQUAD NOR OUR AFFILIATES ENDORSES, CONTROLS, ASSUMES LIABILITY
        FOR, OR OTHERWISE TAKES RESPONSIBILITY FOR ANY ISSUES ARISING OUT OF OR
        RELATING TO THE ACTS OR OMISSIONS OF ANY THIRD-PARTY PROVIDER – EVEN IF
        ACCESSED THROUGH OUR SERVICES – AND WE MAKE NO REPRESENTATIONS,
        WARRANTIES, COVENANTS, OR OTHER PROMISES IN CONNECTION THEREWITH. YOU
        AGREE, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, TO RELEASE THE
        BIOME SQUAD AND OUR AFFILIATES HOLD THE BIOME SQUAD AND OUR AFFILIATES
        HARMLESS FROM ANY CLAIM ARISING OUT OF AND/OR RELATING TO ALL
        THIRD-PARTY PRODUCTS, SERVICES, CONTENT, MATERIALS, INFORMATION, AND
        OTHER OFFERINGS.
      </p>
      <p>
        e. YOU AGREE THAT NEITHER THE BIOME SQUAD NOR OUR AFFILIATES PUTS FORTH
        ANY REPRESENTATIONS OR WARRANTIES WITH RESPECT TO THIRD-PARTY
        INTELLECTUAL PROPERTY RIGHTS, TITLES, OR INTERESTS – OR ANY RELATED
        RIGHTS, TITLES, INTERESTS, OR LICENSES, AS THEY ARISE IN CONNECTION WITH
        OUR PRODUCTS AND/OR SERVICES, OR THE PRODUCTS AND SERVICES OF OUR
        THIRD-PARTY PROVIDERS. f. YOU ACKNOWLEDGE AND AGREE THAT NEITHER THE
        BIOME SQUAD NOR OUR AFFILIATES HAS ANY CONTROL, INPUT, OR AUTHORITY WITH
        RESPECT TO ANY THIRD PARTY’S TERMS OF SERVICE, PRIVACY POLICY, OR
        RELATED POLICIES AND PRACTICES – INCLUDING WITHOUT LIMITATION POLICIES
        AND PRACTICES RELATING TO THE COLLECTION, STORAGE, AND USE OF YOUR PII -
        EVEN IF THE BIOME SQUAD INCORPORATES, LINKS TO, RELIES ON, OFFERS,
        FEATURES, AND/OR OTHERWISE FACILITATES ACCESS TO THE OFFERINGS OF SUCH
        THIRD PARTIES. YOU AGREE, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE
        LAW, TO RELEASE THE BIOME SQUAD AND OUR AFFILIATES AND HOLD THE BIOME
        SQUAD AND OUR AFFILIATES HARMLESS FROM ANY CLAIM ARISING OUT OF AND/OR
        RELATING TO ANY THIRD PARTY’S TERMS OF SERVICES PRIVACY POLICY, AND/OR
        RELATED POLICIES AND PRACTICES. YOU FURTHER ACKNOWLEDGE THAT THE BIOME
        SQUAD STRONGLY RECOMMENDS THAT, FOR ALL THIRD-PARTY OFFERINGS YOU
        ENCOUNTER IN CONNECTION WITH OUR PRODUCTS AND SERVICES, YOU CAREFULLY
        REVIEW ALL APPLICABLE TERMS OF SERVICE, PRIVACY POLICIES, AND RELATED
        POLICIES AND PRACTICES PRIOR TO ACCESSING, USING, AND/OR INTERACTING
        WITH SUCH OFFERINGS.
      </p>
      <p>
        g. YOU ACKNOWLEDGE AND AGREE THAT NEITHER THE BIOME SQUAD NOR OUR
        AFFILIATES HAS ANY CONTROL, INPUT, OR AUTHORITY WITH RESPECT TO ANY
        THIRD PARTY’S SCHEDULING DECISION, APPOINTMENT CANCELLATION, LATENESS,
        DELAY, OR FAILURE TO FULLY AND SUCCESSFULLY PROVIDE SERVICES, PRODUCTS,
        OR OTHER OFFERINGS. YOU AGREE, TO THE FULLEST EXTENT PERMITTED BY
        APPLICABLE LAW, TO RELEASE THE BIOME SQUAD AND OUR AFFILIATES AND HOLD
        THE BIOME SQUAD AND OUR AFFILIATES HARMLESS FROM ANY CLAIM ARISING OUT
        OF AND/OR RELATING TO ANY THIRD PARTY'S SCHEDULING DECISION, APPOINTMENT
        CANCELLATION, LATENESS, DELAY, OR FAILURE TO FULLY AND SUCCESSFULLY
        PROVIDE A SERVICE, PRODUCT, AND/OR OTHER OFFERING.
      </p>
      <p>
        h. YOU ACKNOWLEDGE AND AGREE THAT NEITHER YOUR LAB RESULTS NOR ANY OTHER
        DOCUMENTS, MATERIAL, INFORMATION OR CONTENT WE FURNISH IS INTENDED AS
        MEDICAL ADVICE OR A SUBSTITUTE THEREFOR, EVEN IF AUTHORED, DISTRIBUTED,
        EDITED, OR RECOMMENDED BY A LICENSED MEDICAL PROFESSIONAL. TO THE
        FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU WILL NOT HOLD THE BIOME
        SQUAD OR OUR AFFILIATES RESPONSIBLE OR LIABLE FOR THE TIMING OR
        SUBSTANCE OF YOUR LAB RESULTS, AND/OR ANY DOCUMENTS, COMMUNICATIONS,
        DATA, OR INFORMATION RELATING THERETO; TO THE FULLEST PERMITTED BY
        APPLICABLE LAW, YOU AGREE TO RELEASE THE BIOME SQUAD AND OUR AFFILIATES
        AND TO HOLD THE BIOME SQUAD AND OUR AFFILIATES HARMLESS FROM ANY MATTER
        ARISING OUT OF AND/OR RELATING TO ANY FH CONTENT, YOUR LAB RESULTS,
        AND/OR ANY DOCUMENTS, COMMUNICATIONS, DATA, OR INFORMATION RELATING TO
        YOUR LAB RESULTS.
      </p>
      <p>
        i. YOU ACKNOWLEDGE THAT YOU MAY ENCOUNTER THIRD-PARTY INDIVIDUALS AND/OR
        INTERACTIONS THAT YOU DEEM TO BE HOSTILE, OBSCENE, OFFENSIVE,
        PREJUDICED, UNLAWFUL, INCOMPETENT, HARASSING, AND/OR OTHERWISE
        INAPPROPRIATE DURING THE COURSE OF OR AS A RESULT OF USING OUR SERVICES.
        THIS INCLUDES, WITHOUT LIMITATION, THIRD-PARTY INTERACTIONS AND
        INDIVIDUALS YOU MAY ENCOUNTER WHEN (i) INTERACTING WITH THIRD-PARTY
        LABORATORY SERVICES AND/OR THIRD-PARTY TELEMEDICINE SERVICES PROVIDERS,
        (ii) POSTING AND ENGAGING WITH USER CONTENT, AND (iii) RECEIVING,
        DISCUSSING, AND/OR COMMUNICATING REGARDING LAB RESULTS. YOU AGREE THAT,
        TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU WILL NOT HOLD THE
        BIOME SQUAD OR OUR AFFILIATES RESPONSIBLE OR LIABLE FOR ANY SUCH
        THIRD-PARTY INDIVIDUALS AND/OR INTERACTIONS YOU ENCOUNTER IN CONNECTION
        WITH AND/OR AS A RESULT OF OUR SERVICES; TO THE FULLEST EXTENT PERMITTED
        BY APPLICABLE LAW, YOU AGREE TO RELEASE THE BIOME SQUAD AND OUR
        AFFILIATES AND HOLD THE BIOME SQUAD AND OUR AFFILIATES HARMLESS FROM ANY
        MATTER ARISING OUT OF AND/OR RELATING TO SUCH AN INTERACTION AND/OR
        INDIVIDUAL.
      </p>
      <p>
        j. NEITHER THE BIOME SQUAD NOR OUR AFFILIATES MAKES A WARRANTY OR
        REPRESENTATION REGARDING ANY INSURANCE OR OTHER HEALTHCARE POLICY, ANY
        BENEFITS THEREUNDER, OR THE SCOPE, VALIDITY OR ACCEPTABILITY THEREOF. TO
        THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU WILL NOT HOLD THE
        BIOME SQUAD OR OUR AFFILIATES RESPONSIBLE OR LIABLE FOR ANY INSURANCE OR
        OTHER HEALTHCARE POLICY CLAIMS, COVERAGE, REIMBURSEMENT, OR ACCEPTANCE
        ISSUES; TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE TO
        RELEASE THE BIOME SQUAD AND OUR AFFILIATES AND HOLD THE BIOME SQUAD AND
        OUR AFFILIATES HARMLESS FROM ANY MATTER ARISING OUT OF AND/OR RELATING
        TO ANY INSURANCE OR OTHER HEALTHCARE POLICY CLAIMS, COVERAGE,
        REIMBURSEMENT, OR ACCEPTANCE ISSUES.
      </p>
      <p>
        k. THE BIOME SQUAD DOES NOT GUARANTEE THAT WE WILL AUDIT, ANALYZE, OR
        REVIEW USER CONTENT OR OTHER THIRD-PARTY CONTENT BEFORE IT GOES LIVE.
        NEITHER THE BIOME SQUAD, NOR OUR AFFILIATES IS IN ANY WAY RESPONSIBLE
        FOR WHAT IS PUBLISHED AS USER CONTENT OR OTHER THIRD-PARTY CONTENT IN
        CONNECTION WITH OUR SERVICES, AND WE ARE UNDER NO OBLIGATION TO EDIT OR
        CONTROL USER CONTENT OR OTHER THIRD-PARTY CONTENT. WHILE WE LIKE TO MAKE
        EFFORTS TO HELP OUR USERS, WE MAKE NO WARRANTIES OR REPRESENTATIONS
        REGARDING RESPONDING TO USER CONTENT ISSUES, THIRD PARTY CONTENT ISSUES,
        OR OTHER ISSUES ARISING OUT OF, OR RELATING TO OUR SERVICES; IT IS
        POSSIBLE THAT YOUR ISSUE MAY GO UNADDRESSED. THAT SAID, WE RESERVE THE
        RIGHT TO DELETE ANY USER CONTENT OR ANY OTHER CONTENT AT ANY TIME FOR
        ANY REASON WITHOUT NOTICE, CAUSE, OR CONSENT. 11. In Order to Use Our
        Services, The Biome Squad Requires You to Make These Additional
        Representations and Warranties, and to Assume These Additional
        Obligations.
      </p>
      <p>
        a. YOU ACKNOWLEDGE AND AGREE THAT, TO THE FULLEST EXTENT PERMITTED BY
        APPLICABLE LAW, YOU ASSUME ALL RISK, LIABILITY, AND RESPONSIBILITY FOR
        DETERMINING THE EXTENT TO WHICH OUR SERVICES AND ANY THIRD-PARTY
        OFFERINGS AVAILABLE THROUGH OUR SERVICES ARE APPROPRIATE, VIABLE, AND
        SAFE FOR YOUR USE AND SUITABLE FOR YOUR PARTICULAR NEEDS AND SUFFICIENT
        FOR YOUR EXPECTATIONS. CERTAIN SERVICES OR PRODUCTS MAY NOT BE
        APPROPRIATE, VIABLE, SUITABLE, AND/OR SAFE FOR CERTAIN INDIVIDUALS. YOU
        AGREE THAT, BETWEEN YOU ONE THE ONE HAND AND THE BIOME SQUAD AND OUR
        AFFILIATES ON THE OTHER HAND, YOU ARE SOLELY RESPONSIBLE AND LIABLE FOR
        DETERMINING: WHETHER TO MAKE USE OF ANY PRODUCT OR SERVICE; WHETHER AND
        TO WHAT EXTENT TO SEEK MEDICAL CARE OR OTHER HEALTHCARE SERVICES IN
        CONNECTION WITH YOUR LAB RESULTS – AND IF SO, FROM WHOM, AT WHAT COST,
        FOR WHAT PURPOSES, AND TO WHAT EXTENT; AND WHETHER AND TO WHAT EXTENT TO
        FOLLOW A GIVEN OPINION, RECOMMENDATION, COURSE, TREATMENT, PLAN OR ITEM
        OF ADVICE. YOU AGREE THAT YOU SHALL RELEASE THE BIOME SQUAD AND OUR
        AFFILIATES AND HOLD HARMLESS THE BIOME SQUAD AND OUR AFFILIATES IN
        CONNECTION WITH ANY DECISION BY YOU CONCERNING: THE SELECTION, PURCHASE,
        AND/OR USAGE OF A PRODUCT, SERVICE, OR OTHER OFFERING; WHETHER AND TO
        WHAT EXTENT TO PURSUE MEDICAL CARE OR HEALTHCARE SERVICES; AND WHETHER
        AND TO WHAT EXTENT TO FOLLOW A GIVEN OPINION, RECOMMENDATION, COURSE,
        TREATMENT, PLAN OR ITEM OF ADVICE. You further acknowledge that if a
        medical provider is not your primary care provider, it is your sole
        responsibility to follow through with your primary care provider on any
        medical conditions or treatments suggested in your treatment by any
        medical provider, and to obtain a medical examination by your primary
        care provider related to the findings, or lack of findings, in your
        treatment with a medical provider.
      </p>
      <p>
        b. YOU ACKNOWLEDGE AND AGREE THAT, TO THE FULLEST EXTENT LEGALLY
        ALLOWED, YOU ASSUME ALL RISK, LIABILITY, AND RESPONSIBILITY FOR YOUR OWN
        ACTS AND OMISSIONS IN CONNECTION WITH OUR SERVICES; THIS INCLUDES,
        WITHOUT LIMITATION, YOUR ACTS AND OMISSIONS IN CONNECTION WITH: (i) YOUR
        SCHEDULING AND RECEIPT OF SAMPLE COLLECTION SERVICES; (ii) YOUR RECEIPT
        OF ANY LAB RESULTS (iii) ANY THIRD-PARTY MEDICAL ADVICE AND/OR CARE; AND
        (iv) THE PURCHASE AND/OR USE OF ANY MEDICAL PRODUCT OR DEVICES. YOU
        ACCORDINGLY AGREE TO CAREFULLY READ AND FOLLOW ALL INSTRUCTIONS DIRECTED
        TO YOU BY THE BIOME SQUAD, OUR AFFILIATES, AND OUR THIRD-PARTY PARTNERS
        AND PROVIDERS IN CONNECTION WITH OUR SERVICES AND PRODUCTS AND ANY
        SERVICES AND PRODUCTS THERETHROUGH AVAILABLE. YOU FURTHER AGREE TO
        ASSUME THE COST OF ALL REPAIRS ARISING OUT OF AND/OR RELATING TO YOUR
        USE OF OUR SERVICES, OUR PRODUCTS AND RELATED THIRD-PARTY PRODUCTS AND
        SERVICES.
      </p>
      <p>
        c. You represent that you have carefully assessed whether our Services
        and Products and any third-party Offerings facilitated through our
        Services are appropriate, viable, and safe for your use, given your
        particular needs and sufficient for your expectations – and you promise
        that you will continue to do so to the extent you make use of additional
        Services or Products and/or related third-party offerings. Furthermore,
        you represent and warrant that you are mentally and physically capable
        of using and healthy and sound enough to use our Services and Products –
        and any third-party Offerings available in conjunction with our Services
        – to the extent used by you. If you have or develop a condition and/or
        disability that could impact your use of our Services and/or related
        third-party offerings, you represent and warrant that a licensed medical
        professional has approved your use our Services and any related
        third-party offerings to the extent used by you.
      </p>
      <p>
        12. Please Review These Terms Governing Your Purchase of Services and
        Products.
      </p>
      <p>
        a. You Authorize The Biome Squad to Effectuate Your Purchase. You should
        plan for your payment to process immediately upon finalizing your
        purchase with The Biome Squad. Effective immediately, you hereby
        authorize The Biome Squad to: (i) charge your Payment Method in full for
        all amounts listed at the time of purchase/transaction confirmation,
        inclusive of any taxes, charges, surcharges, and fees; (ii) disclose
        your PII, inclusive of your Payment Information, to our Affiliates, as
        well as our payment providers, and any laboratory companies, shipping
        providers, and other service providers, as reasonably necessary to
        facilitate the purchase and/or delivery of our Services and Products and
        related third-party Offerings; (iii) make use of any other Payment
        Methods you have submitted in the event that one of your Payment Methods
        is refused or denied. You represent and warrant that, to the extent you
        submit Payment Information during the course of using our Services: (i)
        the entity, organization, or institution connected to such a transaction
        will honor the charges you have incurred; (ii) you shall be bear full
        responsibility for all amounts denied, refused, or uncollected by said
        entity, organization, or institution. You agree that The Biome Squad may
        limit, suspend, or revoke your access if you fail to submit and keep
        up-to-date a viable Payment Method.
      </p>
      <p>
        b. Return & Refund Policy. The Biome Squad intends to be fair and
        reasonable in allowing you to cancel your purchase for Services and/or
        Products.
      </p>
      <p>
        i. If you purchase third-party diagnostic testing services via The Biome
        Squad that require a physician’s approval, and the physician does not
        approve your request for such testing, The Biome Squad will arrange for
        a refund of the cost of such testing, less: (1) any amounts charged by
        the third-party medical provider who reviewed the applicable request for
        diagnostic services; (2) any amounts charged by the third-party
        diagnostic testing provider that said provider deems non-refundable; and
        (3) any applicable service and/or technology fees charged by The Biome
        Squad in connection with your purchase of said third-party diagnostic
        testing services. If you purchase third-party diagnostic testing
        services via The Biome Squad that require a physician’s approval, and
        you fail to receive such testing within ninety (90) days of said
        purchase, The Biome Squad will arrange for a refund of the cost of such
        testing, less: (1) any amounts charged by the third-party medical
        provider who reviewed the applicable request for diagnostic services;
        (2) any amounts charged by the third-party diagnostic testing provider
        that said provider deems non-refundable; and (3) any applicable service
        and/or technology fees charged by The Biome Squad in connection with
        your purchase of said third-party diagnostic testing services.
      </p>
      <p>
        ii. If you purchase third-party diagnostic testing services via The
        Biome Squad that do not require a physician’s approval, you may cancel
        your purchase of such services and receive a full refund if you (1)
        cancel within forty-eight (48) hours of that purchase; (2) have not made
        use of such services and/or otherwise availed yourself of such services
        prior to cancellation; (3) do not make use of such services and/or
        otherwise avail yourself of such services subsequent to cancellation.
      </p>
      <p>
        iii. Unless otherwise noted, you may cancel your purchase of a Product
        at any time prior to shipment of said Product. If you cancel within this
        time period, you will receive a full refund of your purchase, inclusive
        of taxes. You are not entitled to any refunds or returns. You are not
        entitled to receive a refund in connection with any Product that has
        been damaged, destroyed, lost, or abandoned.
      </p>
      <p>
        iv. You agree to allow The Biome Squad up to sixty (60) days to process
        and issue a refund, based on the date of your refund request.
      </p>
      <p>
        v. Although The Biome Squad may help facilitate the processing of any
        refund requests with any third-party laboratory or medical providers,
        such refunds shall be solely in the discretion of and subject to the
        terms and conditions established by such third-party laboratory or
        medical providers. You agree that The Biome Squad shall have no
        liability to you or responsibility to refund any such amounts to the
        extent that a third-party denies a refund request.
      </p>
      <p>
        c. Damaged & Non-Conforming Products. All risk of loss, damage,
        destruction, and abandonment with respect to a Product passes to you
        upon the delivery of that Product to you or your designated recipient.
        If The Biome Squad and/or any Affiliate fails to deliver any Product to
        specification (e.g., type, model, dimensions) by its guaranteed delivery
        date, or if such a Product is damaged or destroyed when delivered, you
        are entitled to return that Product and either (i) receive a replacement
        Product in lieu of the one you returned; or (ii) cancel your purchase
        and receive a full refund or credit (at your election) for all amounts
        paid towards the Product in question. Under either scenario, The Biome
        Squad will arrange for and cover all costs and expenses arising out of
        the pick-up and return of a damaged and/or non-conforming Product. In
        order to effectuate a return of a damaged and/or non-conforming Product,
        you must notify The Biome Squad of the damage and/or non-conformity in
        writing within forty-eight (48) hours of your receipt of the Product.
        You acknowledge and accept that all risk of loss, damage, destruction
        and abandonment with respect to a Product that you are attempting to
        return remains with you until you have provided that Product for return
        to The Biome Squad’s authorized shipping provider, except to the extent
        that such a Product was damaged or destroyed when delivered.
      </p>
      <p>
        d. EXCEPT AS STATED IN THIS SECTION 12, ALL SALES ARE FINAL. Except as
        herein permitted, The Biome Squad does not issue any refunds or credits,
        and we do not accept returns. The Biome Squad reserves the right, in its
        sole discretion, to issue a refund, credit, rebate, discount, coupon, or
        some other form of reimbursement or incentive to one or more individuals
        or entities without any obligation to offer the same at any other time,
        to any other person or entity. Furthermore, The Biome Squad reserves the
        right to correct any errors relating to the pricing or charging of
        prices in connection with our Services and Products, and any third-party
        Offerings in connection with our Services and Products; this includes,
        without limitation, the right to correct any overcharges or
        undercharges, and to cancel and refund any purchases relating to such
        errors.
      </p>
      <p>
        13. You Agree to Indemnify The Biome Squad, Limit Our Liability, and
        Refrain from Injunctive Measures.
      </p>
      <p>
        a. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE THAT
        NEITHER THE BIOME SQUAD NOR OUR AFFILIATES SHALL BE LIABLE TO YOU OR ANY
        THIRD PARTY FOR ANY CONSEQUENTIAL, INDIRECT, INCIDENTAL, RELIANCE OR
        SPECIAL DAMAGES – OR FOR ANY LOSSES ARISING THEREFROM AND/OR RELATING
        THERETO – IRRESPECTIVE OF (i) THE NATURE AND THEORY OF LIABILITY, (ii)
        THE FORESEEABILITY OF HARM OR DAMAGES, (iii) WHETHER THE BIOME SQUAD WAS
        ADVISED AS TO THE POSSIBILITY OF HARM OR DAMAGES, (iv) WHETHER THERE WAS
        AN ACQUISITION OF SUBSTITUTE GOODS AND/OR SERVICES, AND (v) WHETHER A
        REMEDY FAILED OF ITS ESSENTIAL PURPOSE. YOU ACKNOWLEDGE AND AGREE THAT,
        IF YOU ARE NOT FULLY SATISFIED WITH OUR SERVICES OR ANY PORTION THEREOF,
        YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF OUR SERVICES. TO
        THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE THAT THE
        MAXIMUM, AGGREGATE LIABILITY FOR THE BIOME SQUAD AND OUR AFFILIATES IN
        CONNECTION WITH THE BIOME SQUAD’S SERVICES, PRODUCTS, AND/OR THESE TERMS
        SHALL BE THE GREATER OF (i) $10.00 U.S.D. OR (ii) AMOUNTS ACTUALLY PAID
        BY YOU FOR THE RECEIPT OF THE SUBJECT SERVICES AND/OR PRODUCTS. THIS
        LIMITATION OF LIABILITY SHALL NOT APPLY TO ANY MATTERS ARISING OUT OF
        AND/OR RELATING TO THE RECKLESSNESS AND/OR INTENTIONAL MISCONDUCT OF THE
        BIOME SQUAD. FURTHERMORE, THIS LIMITATION OF LIABILITY SHALL NOT APPLY
        TO MATTERS ALLEGING A BODILY INJURY OR DEATH DIRECTLY AND PROXIMATELY
        CAUSED BY THE ACTIONS AND/OR OMISSIONS OF THE BIOME SQUAD AND/OR OUR
        AFFILIATES. THIS LIMITATION OF LIABILITY DOES NOT APPLY TO ANY
        INDEMNIFICATION OBLIGATIONS SET FORTH IN THESE TERMS. THIS LIMITATION OF
        LIABILITY APPLIES, WITHOUT LIMITATION, TO LOSS OF USE, LOSS OF REVENUE,
        LOSS OF PROFITS, LOSS OF BUSINESS AND/OR OPPORTUNITY, LOSS OF REVENUE,
        PROPERTY DAMAGE, HARM TO REPUTATION, LOSS OF GOODWILL, HARM TO THE RIGHT
        OF PRIVACY, EMOTIONAL DISTRESS DAMAGES, BUSINESS INTERRUPTION, LOSS OR
        CORRUPTION OF DATA, AND TECHNICAL MALTHE BIOME SQUAD. THIS LIMITATION OF
        LIABILITY APPLIES, WITHOUT LIMITATION, TO THE BIOME SQUAD’S SERVICES
        RELATING TO: THE FACILITATION OF THIRD-PARTY LABORATORY SERVICES, THE
        RECEIPT OF LAB RESULTS, THE FACILITATION OF THIRD-PARTY TELEMEDICINE
        SERVICES, INFORMATION SHARING, RESEARCH, DATA COMPILATION AND ANALYSIS,
        AND CONTENT CREATION.
      </p>
      <p>
        b. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU HEREBY AGREE:
        (i) YOU SHALL NOT, DIRECTLY OR INDIRECTLY, MAKE ANY EFFORT TO ENJOIN THE
        BIOME SQUAD OR OTHERWISE RESTRAIN US FROM PROVIDING SERVICES; (ii) YOU
        ARE NOT ENTITLED TO ANY INJUNCTIVE OR EQUITABLE RELIEF IN CONNECTION
        WITH THESE SERVICES. THIS PROVISION APPLIES, WITHOUT LIMITATION, TO THE
        BIOME SQUAD’S SERVICES RELATING TO: THE FACILITATION OF THIRD-PARTY
        LABORATORY SERVICES, THE RECEIPT OF LAB RESULTS, THE FACILITATION OF
        THIRD-PARTY TELEMEDICINE SERVICES, INFORMATION SHARING, RESEARCH, DATA
        COMPILATION AND ANALYSIS, AND CONTENT CREATION.
      </p>
      <p>
        c. UNDER CALIFORNIA CIVIL CODE SECTION 1542, A GENERAL RELEASE DOES NOT
        EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN
        HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY
        HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE
        DEBTOR. IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA
        CIVIL CODE SECTION 1542 WITH RESPECT TO ANY ALL MATTERS ARISING OUT OF,
        RELATING TO, AND/OR CONCERNING THE BIOME SQUAD, OUR AFFILIATES, OUR
        SERVICES, OUR PRODUCTS, YOUR PII, THESE TERMS, AND/OR ANY OTHER
        AGREEMENT BETWEEN YOU AND THE BIOME SQUAD. IF YOU ARE A RESIDENT OF A
        STATE AND/OR JURISDICTION WITH LEGAL PROVISIONS OR PROTECTIONS SIMILAR
        TO CALIFORNIA CIVIL CODE § 1542, YOU HEREBY WAIVE ALL SUCH PROVISIONS
        AND PROTECTIONS WITH RESPECT TO ANY ALL MATTERS ARISING OUT OF, RELATING
        TO, AND/OR CONCERNING THE BIOME SQUAD, OUR AFFILIATES, OUR SERVICES, OUR
        PRODUCTS, YOUR PII, THESE TERMS, AND/OR ANY OTHER AGREEMENT BETWEEN YOU
        AND THE BIOME SQUAD. d. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE
        LAW, YOU HEREBY AGREE TO INDEMNIFY, DEFEND, AND HOLD THE BIOME SQUAD AND
        OUR AFFILIATES HARMLESS IN CONNECTION WITH ANY THIRD-PARTY CLAIM – AND
        ALL RELATED LOSSES – ARISING OUT OF AND/OR RELATING TO YOUR USE OF OUR
        SERVICES AND/OR PRODUCTS AND/OR YOUR ACTS AND/OR OMISSIONS IN CONNECTION
        THEREWITH, EXCEPT WITH RESPECT TO ACTS AND/OR OMISSIONS THAT RESULTED
        FROM THE BIOME SQUAD’S SOLE NEGLIGENCE, RECKLESSNESS, KNOWING INTENT, OR
        STRICT LIABILITY. THIS INDEMNIFICATION PROVISION EMBRACES, WITHOUT
        LIMITATION: (i) YOUR USE AND/OR MISUSE OF OUR SERVICES, OUR PRODUCTS,
        AND/OR ANY THIRD-PARTY SERVICES OTHER OFFERINGS ARISING OUT OF AND/OR
        RELATING TO OUR SERVICES AND/OR PRODUCTS (INCLUDING WITHOUT LIMITATION
        ANY THIRD-PARTY); (ii) ANY ACTS AND/OR OMISSIONS TAKEN AS A RESULT OF
        YOUR RECEIPT OF LAB RESULTS AND/OR FH CONTENT; (iii) ANY THIRD-PARTY
        OPINION, RECOMMENDATION, COURSE OF TREATMENT, PLAN, AND/OR ITEM OF
        ADVICE THAT CONCERNS, ARISES OUT, RELATES TO, INCORPORATES, EMANATES
        FROM, AND/OR BEARS ON OF THE USE OF OUR SERVICES; (iv) ANY USAGE,
        DISCLOSURE, DECISION, ACT, AND/OR OMISSION ARISING OUT OF, RELATING TO,
        AND/OR RESULTING FROM LAB RESULTS AND/OR FH CONTENT; (v) ANY INFORMATION
        AND/OR DATA YOU PROVIDE TO US AND/OR ONE OR MORE OF OUR AFFILIATES,
        LICENSORS, AND THIRD-PARTY SERVICES PROVIDER, INCLUDING WITHOUT
        LIMITATION ANY SELF-REPORTED PII; (vi) YOUR FAILURE TO MAINTAIN THE
        CONFIDENTIALITY AND/OR SECURITY OF YOUR PASSWORD, ACCOUNT INFORMATION,
        OR PII; (viii) YOUR VIOLATION OF ANY THIRD-PARTY RIGHTS (E.G. PRIVACY,
        CONFIDENTIALITY, INTELLECTUAL PROPERTY); AND (viii) YOUR VIOLATION OF
        ONE OR MORE TERMS, INCLUDING WITHOUT LIMITATION ANY BREACH OF
        REPRESENTATION, WARRANTY, OR COVENANT SPECIFIED IN THIS THESE TERMS.
        THIS PARAGRAPH APPLIES TO ALL MANNERS OF CLAIMS, WITHOUT EXCEPTION. YOU
        ACKNOWLEDGE AND AGREE THAT THE BIOME SQUAD AND ITS AFFILIATES RESERVE
        THE EXCLUSIVE RIGHT TO CONTROL THE DEFENSE, SETTLEMENT, AND SELECTION OF
        COUNSEL IN CONNECTION WITH ANY CLAIM FOR WHICH YOU ARE BOUND TO PROVIDE
        INDEMNIFICATION BY THE TERMS AND CONDITIONS SET FORTH IN THIS PARAGRAPH
        OR ANY OTHER TERM OR CONDITION, AT YOUR COST AND EXPENSE. THE BIOME
        SQUAD WILL MAKE A GOOD-FAITH EFFORT TO NOTIFY IF WE LEARN OF ANY
        CIRCUMSTANCES THAT GIVE RISE TO THE INDEMNIFICATION OBLIGATIONS HEREIN
        SPECIFIED.
      </p>
      <p>
        14. In Order to Use Our Services, You Accept the Following Waivers and
        Disclaimers.
      </p>
      <p>
        CERTAIN JURISDICTIONS MAY LIMIT OR FORBID CERTAIN OF THE EXCLUSIONS,
        LIMITATIONS, WAIVERS AND/OR DISCLAIMERS APPEARING THROUGHOUT THIS
        AGREEMENT, INCLUDING WITHOUT LIMITATION AS LISTED IN THIS SECTION 14.
        ACCORDINGLY, IT IS POSSIBLE THAT THE FOLLOWING MAY NOT APPLY TO YOU IN
        ITS ENTIRETY. TO THE MAXIMUM EXTENT ALLOWED UNDER APPLICABLE LAW, YOU
        HEREBY AGREE TO THE ALL OF THE FOLLOWING DISCLAIMERS AND WAIVERS, IN
        THEIR ENTIRETY:
      </p>
      <p>
        a. NEITHER THE BIOME SQUAD NOR OUR AFFILIATES PROVIDES ANY WARRANTIES
        WITH RESPECT TO ANY SERVICES OR PRODUCTS, OR ANY ASPECT THEREOF THAT IS
        NOT EXPRESSLY STATED IN THESE TERMS. ALL SERVICES AND PRODUCTS AND
        ASPECTS AND FEATURES THEREOF ARE PROVIDED "AS IS,'' “WITH ALL FAULTS”,
        AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTY OF ANY KIND, EXPRESS OR
        IMPLIED (E.G. WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
        PURPOSE, TITLE, NON-INFRINGEMENT, OPERABILITY, CONDITION, ACCURACY, AND
        VALUE). THIS PARAGRAPH APPLIES REGARDLESS OF WHETHER THE WARRANTY IN
        QUESTION IS EXPRESS OR IMPLIED, OR WHETHER THE WARRANTY ARISES IN THE
        COURSE OF PERFORMANCE, USAGE OF TRADE, DEALING, OR OTHERWISE. THIS
        PARAGRAPH APPLIES, WITHOUT LIMITATION, TO OUR LAB RESULTS, FH CONTENT,
        PERSONALIZED OFFERINGS, THIRD-PARTY OFFERINGS, AND ALL MATERIAL, DATA,
        INFORMATION, AND CONTENT RELATING THERETO. b. WITH RESPECT TO ANY
        “FORWARD-LOOKING STATEMENTS,” THE BIOME SQUAD DISCLAIMS ALL DUTIES,
        DEBTS, OBLIGATIONS, AND LIABILITIES. c. THOUGH CERTAIN ASPECTS OF THE FH
        CONTENT, LAB RESULTS, AND OTHER THIRD-PARTY MATERIALS AND SERVICES
        FACILITATED THROUGH THE BIOME SQUAD MAY BE PROVIDED BY HEALTHCARE
        PROFESSIONALS, NEITHER THE BIOME SQUAD NOR OUR AFFILIATES OFFERS YOU
        MEDICAL ADVICE, OPINION, GUIDANCE, DIAGNOSIS, TREATMENT, OR CARE, OR ANY
        OTHER MEDICAL SERVICES, IN THE COURSE OF PROVIDING SERVICES OR
        OTHERWISE. THE BIOME SQUAD DOES NOT INTERFERE WITH THE PRACTICE OF
        MEDICINE BY MEDICAL PROVIDERS OR THE PROVISION OF LABORATORY SERVICES BY
        LABORATORIES, EACH OF WHOM IS SOLELY RESPONSIBLE FOR THE MEDICAL CARE,
        LABORATORY SERVICES AND TREATMENT PROVIDED TO YOU. d. NEITHER THE BIOME
        SQUAD NOR OUR AFFILIATES MAKES ANY WARRANTY OR REPRESENTATION REGARDING
        THE AVAILABILITY, QUALITY, EFFICACY, ACCURACY, RELIABILITY,
        UP-TO-DATENESS, USEFULNESS, TIMELINESS, SAFETY, OR RESULTS OF ANY OF OUR
        SERVICES – EITHER GENERALLY, OR WITH RESPECT TO ANY PERSON’S OR ENTITY’S
        SPECIFIC, INDIVIDUAL NEEDS. THIS PARAGRAPH EMBRACES, WITHOUT LIMITATION,
        A LACK OF WARRANTY AND REPRESENTATION REGARDING ANY LAB RESULTS AND ANY
        FH CONTENT. YOU AGREE THAT NEITHER THE BIOME SQUAD NOR ITS AFFILIATES
        SHALL BE LIABLE FOR ANY LOSSES ARISING OUT OF AND/OR RELATING TO YOUR
        RELIANCE ON ANY LAB RESULTS, FH CONTENT AND/OR ANY OTHER DATA AND/OR
        INFORMATION ARISING OUT OF AND/OR RELATING TO OUR SERVICES THAT CONTAINS
        AN OMISSION, MISTAKE, ERROR. YOU AGREE THAT NEITHER THE BIOME SQUAD NOR
        ITS AFFILIATES SHALL BE LIABLE FOR ANY LOSSES ARISING OUT OF AND/OR
        RELATING TO YOUR INABILITY TO ACCESS DATA OR INFORMATION OBTAINED IN
        CONNECTION WITH OUR SERVICES AND/OR ANY OF OUR THIRD-PARTY PROVIDERS’
        SERVICES – INCLUDING WITHOUT LIMITATION AS RESULT OF DELETION, FAILURE
        IN TRANSMISSION, INTERRUPTION, DELAY, DEFECT, UNAUTHORIZED ACCESS,
        THEFT, VIRUS, OPERATIONAL FAILURE, COMMUNICATIONS FAILURE, DESTRUCTION,
        AND/OR AN ACT OF GOD. e. NEITHER THE BIOME SQUAD NOR OUR AFFILIATES
        WARRANTS OR REPRESENTS THAT THE FH CONTENT OR ANY LAB RESULTS WILL BE
        COMPLETE, ACCURATE, USEFUL, PRECISE, UP-TO-DATE, AVAILABLE, RELIABLE, OR
        OF A CERTAIN STANDARD OR QUALITY. YOU ACKNOWLEDGE THAT, WITH RESPECT TO
        OUR SERVICES, YOUR ACCESSIBILITY, VISIBILITY, AND SPEED OF USE MAY BE
        IMPACTED BY SUCH FACTORS AS LOCATION, CONNECTION, DEVICE TYPE,
        BANDWIDTH, AND CONCURRENT TRAFFIC. YOU AGREE THAT THE BIOME SQUAD SHALL
        NOT BE LIABLE FOR THE UNAVAILABILITY OF OUR SERVICES OR ANY ASPECT
        THEREOF. f. THE BIOME SQUAD USES CERTAIN SAFEGUARDS TO REASONABLY
        PROTECT THE SECURITY AND INTEGRITY OF YOUR PII. NONETHELESS, YOU
        ACKNOWLEDGE AND ACCEPT THAT – EVEN WITH THE MOST ROBUST SECURITY
        POLICIES AND PRACTICES – THE BIOME SQUAD CANNOT GUARANTEE OR PROMISE
        THAT (i) SUCH INFORMATION OR ANY OTHER INFORMATION, DATA, CONTENT,
        MATERIAL, OR TECHNOLOGY, WILL BE 100% SAFE FROM UNAUTHORIZED ACCESS
        AND/OR USE; (ii) OUR TECHNOLOGIES, CONTENT, AND MATERIALS WILL REMAIN
        FREE OF HARMFUL OR DESTRUCTIVE COMPONENTS LIKE MALWARE, TROJAN HORSES,
        WORMS, AND VIRUSES; (iii) OUR SERVICES WILL NOT BE IMPACTED BY A
        DISTRIBUTED DENIAL OF SERVICE ATTACK; OR (iv) OUR COLLECTION, RECEIPT,
        STORAGE, AND TRANSMISSION OF SUCH INFORMATION OR ANY OTHER INFORMATION,
        DATA, CONTENT, MATERIAL, OR TECHNOLOGY WILL REMAIN UNINTERRUPTED OR
        COMPLETELY SECURE. THE BIOME SQUAD AND OUR AFFIIATES ACCORDINGLY
        DISCLAIM ALL LIABILITY ARISING OUT OF AND/OR RELATING TO THE SECURITY,
        INTEGRITY, AND/OR UNAUTHORZED ACCESS OR USE OF YOUR DATA AND INFORMATION
        (INCLUDING WITHOUT LIMITATION YOUR PII), EXCEPT TO THE EXTENT IT RESULTS
        FROM THE RECKLESSNESS AND/OR INTENTIONAL MISCONDUCT OF THE BIOME SQUAD
        AND/OR ONE OR MORE OF OUR AFFILIATES; THE TERMS IN THIS PARAGRAPH
        EMBRACE, WITHOUT LIMITATION ANY LOSSES, ARISING OUT OF AND/OR RELATING
        TO ANY VIRUS, MALWARE, DISTRIBUTED DENIAL OF SERVICE ATTACK OR OTHER
        HARMFUL CODE OR MATERIALS THAT MAY IMPACT YOUR DATA, INFORMATION,
        DEVICE, COMPUTER, PII, OR OTHER PROPERTY (INTELLECTUAL OR OTHERWISE) AND
        ARISE OUT OF AND/OR RELATE TO YOUR USE OF OUR SERVICES, AS WELL AS ANY
        UNAUTHJORIZED GRANT OF ACCESS TO YOUR ACCOUNT AND/OR INFORMATION ON THE
        BASIS OF FALSE PRETENSES, STOLEN AND/OR MISAPPROPRIATED LOGIN/PASSWORD
        INFORMATION, SOCIAL ENGINEERING, OR OTHERWISE. YOU ACKNOWLEDGE AND AGREE
        THAT YOUR TRANSMISSION OF DATA AND INFORMATION IN CONNECTION WITH OUR
        SERVICES IS AT YOUR OWN RISK. g. NEITHER THE BIOME SQUAD NOR OUR
        AFFILIATES WARRANTS OR REPRESENTS THAT OUR SERVICES (INCLUDING BUT NOT
        LIMTED TO SERVICES RELATING TO THE FACILITATION OF LABORATORY SERVICES,
        THE RECEIPT OF LAB RESULTS, AND THE FACILITATION OF TELEMEDICINE
        SERVICES) HAVE THE BIOME SQUADED OR WARRANT THAT THEY WILL THE BIOME
        SQUAD IN A MANNER THAT IS ERROR-FREE AND UNINTERRUPTED. NEITHER THE
        BIOME SQUAD NOR OUR AFFILIATES WARRANTS OR REPRESENTS THAT WE WILL
        ADDRESS ANY MALTHE BIOME SQUADS OR DEFECTS, PRESERVE ANY FH CONTENT, LAB
        RESULTS, OR OTHER DOCUMENTS AND/OR INFORMATION, REVIEW ANY PRODUCTS OR
        SERVICES, OR CONDUCT ANY UPDATES OR IMPROVEMENTS. AND PRODUCTS 15.
        Choice of Law and Jurisdiction a. Choice of Law. Any Claim arising out
        of or relating to The Biome Squad, our Affiliates, our Services, our
        Products, a transaction in connection with one or more Products and/or
        Services, one or more of third-party Offerings arising out of and/or
        relating to our Services and/or Products, and/or the Terms will be
        construed and governed in accordance with the laws of the State of
        Texas, without regard to its conflict of laws principles, except to the
        extent that Texas law is preempted by or inconsistent with federal law.
        b. Venue and Jurisdiction. Except to the extent that a dispute is
        arbitrated or brought in small claims court pursuant to Section 16
        below, the parties agree that all disputes must be litigated in the
        state or federal courts the State of Texas. You and The Biome Squad each
        waive any defense or claim of lack of personal jurisdiction, the
        inappropriateness of venue, or forum non conveniens. c. U.S.
        Jurisdiction; Foreign Access. The Biome Squad and its properties are
        located in and operated from the United States. Our Services are
        intended only for use by persons located in the United States. The Biome
        Squad does not intend to be subject to any non-U.S. law or jurisdiction,
        under any circumstances, and you agree that you shall not pursue any
        claims, disputes, or controversies against The Biome Squad insofar as
        they are subject The Biome Squad to non-U.S. law or jurisdiction.
        Moreover, you acknowledge and agree that The Biome Squad makes no
        representation or warranty that our Services – including without
        limitation our website, the FH Content and any Lab Results relating to
        our Services – are accessible, legally permitted, and/or appropriate for
        consumption in any countries or jurisdictions outside of the United
        States. It may not be legal for your and/or other persons to access this
        site based on the country or jurisdiction in which you and/or such
        persons are located; you accordingly assume all risk, liability, and
        responsibility in connection with accessing this site outside of the
        United States and complying with any laws, rules, regulations, or
        guidelines applicable thereto. Furthermore, insofar as you are resident
        of a country other than the United States and/or located in such a
        country when accessing our Services (e.g., in a country belonging to the
        European Union), you acknowledge that you are engaging in the transfer
        of data and information to the United States, which may have less
        protective data laws, privacy regulations, and disclosure regulations
        than your country of residency and/or location. 16. Binding Arbitration
        of Disputes on an Individual Basis.
      </p>
      <p>
        a. Claims subject to arbitration. To the fullest extent permitted by
        applicable law, you and The Biome Squad agree to arbitrate all Claims
        arising out of or relating to The Biome Squad, our Affiliates, these
        Terms, our Services, our Products, a transaction in connection with one
        or more Products and/or Services, PII, one or more of third-party
        products and/or services arising out of and/or relating to our Services
        and/or Products. This arbitration provision is intended to be broadly
        interpreted. It includes, but is not limited to:
      </p>
      <p>
        · Claims relating to our facilitation of third-party laboratory
        services, your Sample, an analysis of your Sample, your Lab Results, any
        documents, communications, data, or information relating to your Lab
        Results, and/or any actions and/or omissions in connection therewith
        and/or resulting therefrom; · Claims relating to any aspect of the
        relationship between you and The Biome Squad, whether based in contract,
        tort, negligence, fraud, misrepresentation, trespass, or any other
        statutory or common-law legal theory; · Claims relating to your
        interactions with or any actions taken by The Biome Squad, our
        Affiliates, and/or any of our third-party affiliates and/or providers; ·
        Claims relating to the FH Content, your receipt and/or review thereof,
        your distribution thereof, and/or any actions and/or omissions in
        connection therewith and/or resulting therefrom; · Claims relating to
        The Biome Squad’s use, protection, or disclosure of information about
        you, including your PII; · Claims concerning The Biome Squad’s marketing
        and/or advertising of third-party products and services to you; · Claims
        that arose before your acceptance of the Terms or any current or prior
        agreement between you and The Biome Squad, such as Claims related to
        advertising or disclosures; · Claims that arise after the termination of
        this or any other agreement between you and The Biome Squad; and ·
        Except as specified in Section 16.b below, any disputes relating to the
        interpretation, applicability, scope, waiver, or enforceability of this
        arbitration provision, such as a dispute over whether a Claim can or
        must be brought in arbitration.
      </p>
      <p>
        In this Section 16 only, references in this to “The Biome Squad,” “we,”
        “our,” and “us” include our respective predecessors in interest, as well
        as our respective past, present, and future subsidiaries, Affiliates,
        related entities, and all authorized or unauthorized users or
        beneficiaries of our Services or any Products purchased from The Biome
        Squad. You agree that, by agreeing to the Terms, you and The Biome Squad
        are each waiving the right to a trial by jury or to participate in a
        class action. The Terms evidence a transaction in interstate commerce,
        and thus the Federal Arbitration Act governs the interpretation and
        enforcement of this arbitration provision. This arbitration provision,
        as set forth by the terms of this Section 16, shall survive termination
        of your use of our Services.
      </p>
      <p>
        b. Claims not subject to arbitration. Notwithstanding the foregoing, you
        or The Biome Squad may bring an action in your local small claims court
        seeking only individualized relief, so long as the action remains in
        that court and is not removed or appealed to a court of general
        jurisdiction. If your jurisdiction permits small claims court judgments
        to be removed or appealed to a court of general jurisdiction for a trial
        de novo, that appeal shall be resolved in arbitration in accordance with
        this arbitration provision. This arbitration provision does not preclude
        you or The Biome Squad from bringing issues to the attention of federal,
        state, or local agencies or law enforcement. Notwithstanding anything
        herein to the contrary, any dispute over the applicability and/or
        enforceability of any of the terms and/or conditions in this Section
        16(b), shall be determined in a court of competent jurisdiction unless
        the parties mutually agree otherwise in writing. Notwithstanding
        anything herein to the contrary, either Party may bring a claim arising
        out of and/or relating to intellectual property rights, or seek
        temporary or preliminary relief and/or specific performance, in any
        court of competent jurisdiction, without the posting of bond or other
        security.
      </p>
      <p>
        c. Class Action Waiver. The arbitrator may award relief (including, but
        not limited to, damages, restitution, declaratory relief, and injunctive
        relief) only in favor of the individual party seeking relief and only to
        the extent necessary to provide relief warranted by that party’s
        individual Claim. TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LE LAW,
        YOU AND THE BIOME SQUAD EACH AGREE TO BRING CLAIMS AGAINST THE OTHER IN
        AN INDIVIDUAL CAPACITY. TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LE
        LAW, YOU AND THE BIOME SQUAD EACH AGREE NOT TO PURSUE OR RECOVER ANY
        DAMAGES OR OTHER RELIEF IN CONNECTION WITH ANY CLAIMS AGAINST THE OTHER
        IN A CLASS ACTION, COLLECTIVE ACTION, MASS ACTION, PRIVATE ATTORNEY
        GENERAL PROCEEDING, OR OTHER AGGREGATED PROCEEDING. Further, unless both
        you and The Biome Squad agree otherwise, the arbitrator may not
        consolidate more than one person’s Claims. To the fullest extent
        permitted by applicable law, the arbitrator may not preside over any
        form of a collective action, class action, mass action, private attorney
        general action, or other aggregated proceeding. If, after exhaustion of
        all appeals, any of the aforementioned prohibitions on
        non-individualized relief is found to be unenforceable with respect to a
        particular Claim then the parties agree that such a Claim or request for
        relief shall be decided by a court of competent jurisdiction after all
        other claims and requests for relief shall be arbitrated.
      </p>
      <p>
        d. Arbitration procedures. A Party who intends to seek arbitration must
        first send to the other a written Notice of Dispute. A Notice of Dispute
        to The Biome Squad should be addressed to The Biome Squad Health, Inc.,
        Attn: Legal Department, 5302 Cascades Court, College Station, TX 77845.
        A Notice of Dispute to you will be sent to the last address you provided
        to The Biome Squad. A Notice of Dispute must (i) provide your name,
        address, phone number, and the email address we have on file for you;
        (ii) describe the nature and basis of the claim or dispute; and (iii)
        set forth the specific relief sought. The Parties agree to negotiate
        regarding any claim or dispute subject to a Notice of Dispute for a
        period of not less than sixty (60) days from receipt of said Notice– and
        if the Parties do not reach an agreement to resolve the claim within
        sixty (60) days after the Notice of Dispute is received, you or The
        Biome Squad may commence arbitration with JAMS, which shall administer
        the arbitration under its Consumer Arbitration Rules in effect at the
        time (“JAMS Rules”), no later than the latest date permitted by the
        statute of limitations that would have applied to said a dispute if it
        had proceeded in a court of competent jurisdiction instead of in
        arbitration. You may obtain a copy of the JAMS Rules by visiting the
        JAMS website at jams.org. If the JAMS is unavailable to administer an
        arbitration, another provider will be selected by agreement of the
        parties or by the court pursuant to 9 U.S.C. § 5. Except as specified in
        this Agreement (e.g., limitations and exclusions around Claims and
        Losses), the arbitrator can award the same remedies available under
        applicable law that a court can award. The arbitrator may consider
        rulings in other arbitrations involving different customers, but an
        arbitrator’s ruling will not be binding in proceedings involving
        different customers. Unless you and The Biome Squad agree otherwise, any
        arbitration hearings will take place by videoconference or by telephone
        or resolve claims or defenses on the basis of papers submitted by the
        parties, or to the extent not permitted, at the JAMS location nearest
        your billing address. Regardless of the manner in which the arbitration
        is conducted, a single arbitrator will conduct the arbitration – as
        agreed-upon by the Parties within ten (10) business days following the
        commencement of Arbitration, or as alternatively determined pursuant to
        the JAMS rules – and said arbitrator shall issue a reasoned written
        decision. The award shall be final and binding on the parties; judgment
        may be entered on the award by any court with jurisdiction and by any
        state or federal court in Houston, Texas, the jurisdiction and venue of
        which you and The Biome Squad both agree, and such a judgment shall not
        be subject to modification, appeal, or vacation except as allowed by
        Sections 10 and 11 of the Federal Arbitration Act, which the Parties
        hereby agree apply to these Terms. Except as otherwise explicitly stated
        in this Agreement, the arbitrator shall have the sole and final
        authority to decide on the validity, applicability, and scope of the
        arbitration provisions of this agreement, as well as the arbitrability
        of a Party’s claim(s). e. Arbitration fees. If The Biome Squad initiates
        arbitration or you initiate arbitration of claims valued at $25,000 or
        less, The Biome Squad will pay all JAMS filing, administration, case
        management, hearing, and arbitrator fees (“JAMS Fees”), so long as you
        have fully complied with the Notice of Dispute requirements in Section
        16.d. If you seek relief valued at greater than $25,000, the payment of
        JAMS Fees shall be governed by the JAMS Rules. Nothing in this Section
        16.e prevents the arbitrator from reallocating the JAMS Fees in
        accordance with the JAMS Rules in the event that the arbitrator finds
        that a claim violates the standards set in Federal Rule of Civil
        Procedure 11. f. 30-Day period to reject arbitration. You may opt out of
        this Section 16 of the Terms within thirty (30) days of execution by
        providing written notice to The Biome Squad Health, Inc., Attention:
        Legal Dept., 5302 Cascades Court, College Station, TX 77845. To be
        valid, an opt-out notice must (i) include your name, the email address
        we have on file for you, and a statement that you are rejecting the
        arbitration provision in the Terms; and (ii) be received by The Biome
        Squad within 30 days after your initial acceptance of the Terms. If you
        elect to opt-out under this paragraph, you agree that all other terms
        and conditions herein stated and stated in other agreements between you
        and shall remain in full force and effect; that includes, without
        limitation any other arbitration agreements between you and The Biome
        Squad. g. Time Limitation on Claims. To the fullest extent permitted by
        applicable law, you and The Biome Squad agree that any claim, dispute,
        controversy, matter, or cause of action arising out of and/or relating
        to The Biome Squad, our Affiliates, these Terms, our Services, our
        Products, a transaction in connection with one or more Products and/or
        Services, PII, and/or one or more of third-party products and/or
        services arising out of and/or relating to our Services and/or Products
        shall be permanently barred if it is not filed and/or submitted for
        arbitration within one year of when the alleging party knew or
        reasonably should have known of the issue or matter giving rise thereto.
        Notwithstanding the foregoing, this paragraph shall not apply to the
        filing and/or submission of any counterclaim. 17. Miscellaneous a.
        Except as expressly permitted otherwise in writing by The Biome Squad,
        you may not assign, delegate, sell, or transfer any of your rights or
        obligations under these Terms. Any purported assignment or delegation in
        violation of this Section 17.a is null and void. No assignment or
        delegation relieves you of any of your obligations under these Terms.
        Notwithstanding anything herein contrary, The Biome Squad may freely
        assign, delegate, sell, and/or transfer our rights and obligations under
        these Terms– and any assets relating to, arising out of, and/or
        concerning these Terms – including without limitation circumstances of
        sale, merger, acquisition, reincorporation, consolidation,
        reorganization, or other change of control. These Terms will be binding
        on the Parties’ heirs, permitted assigns, administrators, and other
        legal represents, and shall inure to the benefit of the Parties and any
        of its successors and/or assigns. b. You acknowledge and agree that The
        Biome Squad shall not hold any liability or responsibility for any
        Losses – or be deemed to have defaulted or breached these Terms – for
        any failure or delay in fulfilling or performing any term of these Terms
        that results from factors beyond the scope of our reasonable control.
        This includes without limitation, acts of God, weather, the slowdown or
        shutdown of carriers, transportation, and/or utilities, strikes and
        protests, border delays, health crises and/or pandemic illnesses,
        closures and/or lockdowns, acts of warfare and/or terrorism, and actions
        taken by government agencies. c. If any of the Terms herein – or any
        portion(s) of any terms or conditions – are held unenforceable, the
        impact of that unenforceability (i.e. limitation or exclusion of terms)
        will be construed as narrowly as possible to the extent permitted by
        applicable law and it does not invalidate or render unenforceable such
        term or provision in any other jurisdiction; moreover, the remainder of
        these Terms– and all other portions thereof – shall nevertheless remain
        in full force and effect to the extent legally permissible. d. Any
        translation of these Terms, your Lab Results, and/or the FH Content is
        merely provided as a convenience. Any discrepancy or dispute involving a
        translated version of these Terms, your Lab Results, and/or the FH
        Content and the English version thereof shall be resolved in favor of
        the English version. Our headings and section titles in these Terms are
        provided strictly for your convenience; they have no binding or
        representative effect on either Party. e. No waiver under these Terms is
        effective unless it is in writing, identified as a waiver to these
        Terms, and signed by an authorized representative of the Party waiving
        its right(s). The Parties agree that any failure by either Party to
        require the other’s strict adherence to any term or condition herein
        shall, in no manner, be construed as a waiver of any right(s) by that
        Party, nor shall such a failure be construed to remove or dilute the
        effect of any term, condition, or requirement stated in these Terms.
        These Terms may not be amended or modified unless specified in writing
        and bearing the consent of both Parties. f. Insofar as these Terms
        include any errors or ambiguities related to spelling, grammar, or
        syntax – or any other clear errors or ambiguities – these errors and
        ambiguities shall be construed to reflect the intent of the Parties.
        This includes, without limitation: (a) any ambiguities, inconsistencies,
        misuses, or uncertainties around the usage of the word “and” vs. “or”
        vs. “and/or”; and (b) any ambiguities, inconsistencies, misuses, or
        uncertainties around the usage of the word “including” – which shall
        mean “including without limitation,” rather than as point of exclusion,
        unless otherwise noted; (c) any ambiguities, inconsistencies, misuses,
        or uncertainties around the usage of the word “Service” vs. “Services”
        vs. “Service(s)” - which shall not render any language inapplicable by
        virtue of the fact that it does or does not refer to more than one (1)
        Service. The Parties agree that the Terms shall be construed as if
        drafted jointly by the Parties, and no presumption or burden of proof
        shall arise favoring or burdening any of the Parties by virtue of the
        authorship of any term or condition herein appearing; the language used
        herein will be deemed to be the language chosen by the Parties hereto to
        express their mutual intent, and no rule of strict construction will be
        applied against any Party. g. All notices, requests, consents, claims,
        demands, waivers, and other communications from you to The Biome Squad
        in connection with these Terms (each, a “Notice”) shall be in writing
        and addressed to The Biome Squad at the address on this website. All
        Notices shall be delivered by personal delivery, nationally recognized
        overnight courier (with all fees pre-paid) or certified or registered
        mail (in each case, return receipt requested, postage prepaid). h. These
        Terms benefit solely the Parties and their permitted assigns and nothing
        in these Terms, express or implied, confers on any other person or
        entity any legal or equitable right, benefit, or remedy of any nature
        whatsoever under or by reason of these Terms. i. These Terms, including
        without limitation the Privacy Policy, along with any further membership
        agreement and/or any other consent for Services to which you have
        consented, collectively, constitute the entire agreement between you and
        The Biome Squad with respect to our Services and supersede all prior
        and/or contemporaneous agreements between you and The Biome Squad,
        whether oral or written, arising out of and/or relating to our Services.
        In the event of any conflict between a term and/or condition between you
        and The Biome Squad, on the one hand, and a third-party term and/or
        condition, on the other hand, The Biome Squad’s term and/or condition
        shall control. j. California residents are entitled to the following,
        specific consumer rights information: The provider of the Services is:
        Attention: Legal Department 5302 Cascades Court College Station, TX
        77845 (737) 259-6190
      </p>
      <p>
        If you should have any further questions, the Complaint Assistance Unit
        of the Division of Consumer Services of the California Department of
        Consumer Affairs may be contacted in writing at 1625 North Market Blvd.,
        Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800)
        952-5210. 18. Digital Millennium Copyright Act (17 U.S.C. §512)
      </p>
      <p>
        a. If you believe that your copyrighted work appears in connection with
        our Services and/or is accessible through our Services in a way that
        constitutes copyright infringement, please notify The Biome Squad by
        providing us with the following information in writing to the address
        listed below: (1) the physical or electronic signature of either the
        copyright owner or of a person authorized to act on the owner’s behalf;
        (2) a description of the copyrighted work you claim has been infringed,
        and a description of the activity that you claim to be infringing; (3)
        identification of the URL or other specific location where the material
        or activity you claim to be infringing is located or is occurring; (4)
        your name, address, telephone number and, if you have one, your e-mail
        address; (5) a statement by you that you have a good faith belief that
        use in our Services of the copyrighted work in the manner you are
        complaining of is not authorized by the copyright owner, any agent of
        the copyright owner, or the law; and (6) a statement by you, made under
        penalty of perjury, that the information you have provided in your
        notice is accurate and that you are either the copyright owner or are
        authorized to act on behalf of the copyright owner.
      </p>
      <p>
        b. If you believe you are the subject of an improper infringement claim,
        please notify The Biome Squad by providing us with the following
        information in writing to the address listed below: (1) the physical or
        electronic signature of either the copyright owner or of a person
        authorized to act on the owner’s behalf; (2) a detailed description of
        the copyrighted work you claim has been subject to an improper
        infringement claim; (3) identification of the URL or other specific
        location from where the allegedly infringing material has been removed,
        modified, and/or deleted; (4) your name, address, telephone number and,
        if you have one, your e-mail address; (5) the following statement: “I
        hold a legitimate, good faith belief that the allegedly offending
        material, as herein described, has been subject to complaint, removed,
        or rendered inaccessible as the result of misidentification,
        misfeasance, malfeasance, and/or mistake. All of the factual information
        I have provided in connection with this document is true and correct. I
        consent to the jurisdiction of the District Court in the United States
        judicial district in which I live – or, to the extent I reside outside
        the United States, in the Southern District of Texas – and will accept
        service of process from the person or the agent of the person who sent
        the initial notice of infringement to The Biome Squad Health, Inc. All
        attestations herein are given under penalty of perjury.”
      </p>
      <p>
        Thanks for reading! Again, please contact us if you have any questions,
        comments, concerns, or feedback. We appreciate your interest and wish
        you excellent health!
      </p>
    </Container>
  );
}

export default Terms;
