import { SearchIcon } from '@chakra-ui/icons';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import React from 'react';

function Search({ placeholder, size, colorScheme }) {
  return (
    <div id="search">
      <InputGroup size={size} colorScheme={colorScheme}>
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.300" />
        </InputLeftElement>
        <Input type="text" placeholder={placeholder} />
      </InputGroup>
    </div>
  );
}

export default Search;
