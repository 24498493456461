import React, { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  Heading,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from '@uidotdev/usehooks';
import { DownloadIcon } from '@chakra-ui/icons';
import csvDownload from 'json-to-csv-export';
import DownloadPDF from '../../Components/DownloadPDF/DownloadPDF';
import NDAContent from '../NDA/NDAContent';
import { renderToString } from 'react-dom/server';

const userHeaders = [
  'id',
  'email',
  'name',
  'mobile',
  'employer',
  'status',
  'nda',
];

function AdminUsers({ currentUser, data, applicationsOnly }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState(null);
  const debouncedSearchTerm = useDebounce(searchQuery, 300);

  const handleNavigate = id => {
    let selection = window.getSelection().toString();
    if (selection.length <= 0) {
      navigate(`/admin/user/${id}`);
    }
  };

  useEffect(() => {
    const searchHN = async () => {
      let results = [];
      if (debouncedSearchTerm) {
        const array = data.map(d => ({
          ...d,
          dataString: Object.values(d).join(),
        }));
        const fArray = array.filter(a =>
          a.dataString.toLowerCase().includes(searchQuery?.toLowerCase())
        );
        setFilteredData(fArray);
      } else {
        setFilteredData(data);
      }
    };
    searchHN();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setFilteredData(data);
    console.log('data', data);
  }, [data]);

  return (
    <div id="patients" className="page-padding">
      <Heading
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        fontSize={{
          base: '4xl',
          md: '5xl',
        }}
      >
        <Flex gap={3} alignItems="center">
          {applicationsOnly ? 'Open Applications' : 'Users'}
          <Button
            colorScheme="blue"
            leftIcon={<DownloadIcon h={4} w={4} color={'white.300'} />}
            onClick={() => csvDownload({ data })}
          >
            CSV
          </Button>
        </Flex>

        <Input
          width={400}
          borderRadius={20}
          focusBorderColor="blue.400"
          placeholder="Search..."
          className={searchQuery?.length > 0 ? 'filled' : ''}
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
      </Heading>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              {userHeaders.map(l => (
                <Th>{l}</Th>
              ))}
            </Tr>
          </Thead>
          {applicationsOnly ? (
            <Tbody>
              {filteredData
                ?.filter(d => d.membership === 'Applied')
                .map(l => (
                  <Tr
                    cursor="pointer"
                    onClick={() => {
                      let selection = window.getSelection().toString();
                      if (selection.length <= 0) {
                        navigate(`/admin/user/${l.id}`);
                      }
                    }}
                  >
                    <Td>{l.id}</Td>
                    <Td>{l.email}</Td>
                    <Td>{l.displayName}</Td>
                    <Td>{l.mobile}</Td>
                    <Td>{l.employer}</Td>
                    <Td style={{ textTransform: 'capitalize' }}>
                      {l.membership}s
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          ) : (
            <Tbody>
              {filteredData?.map(l => (
                <Tr cursor="pointer">
                  <Td onClick={() => handleNavigate(l.id)}>{l.id}</Td>
                  <Td onClick={() => handleNavigate(l.id)}>{l.email}</Td>
                  <Td onClick={() => handleNavigate(l.id)}>{l.displayName}</Td>
                  <Td onClick={() => handleNavigate(l.id)}>{l.mobile}</Td>
                  <Td onClick={() => handleNavigate(l.id)}>{l.employer}</Td>
                  <Td
                    onClick={() => handleNavigate(l.id)}
                    style={{ textTransform: 'capitalize' }}
                  >
                    {l.membership}
                  </Td>
                  {(l.membership === 'Certified' ||
                    l.membership === 'Premium') &&
                  l.signedNDA ? (
                    <Td>
                      <DownloadPDF
                        htmlString={
                          <NDAContent
                            displayName={l?.displayName}
                            NDADate={l?.signedNDA}
                          />
                        }
                        buttonLabel={'NDA'}
                        size={'sm'}
                        leftIcon={
                          <DownloadIcon h={4} w={4} color={'white.300'} />
                        }
                      />
                    </Td>
                  ) : (
                    ''
                  )}
                </Tr>
              ))}
            </Tbody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}

export default AdminUsers;
