import React from 'react';
import './Patients.scss';
import PatientsPlaceholder from './PatientsPlaceholder';
import PatientsCertified from './PatientsCertified';

function Patients({ currentUser, setCurrentUser, isCertified }) {
  if (!isCertified) {
    return (
      <div id="labs">
        <PatientsPlaceholder />
      </div>
    );
  } else {
    return (
      <div id="labs">
        <PatientsCertified
          currentUser={currentUser}
          setCurrentUser={setCurrentUser}
          isCertified={isCertified}
        />
      </div>
    );
  }
}

export default Patients;
