import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  addNewCommentToDiscussion,
  deleteDocument,
  getRootDocument,
  getRootDocumentCollections,
  updateRootDocument,
} from '../../api/api';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { FaCommentAlt, FaFlag, FaReply } from 'react-icons/fa';
import CommentsAdd from './CommentsAdd';
import ReactQuill from 'react-quill';
import { v4 as uuidv4 } from 'uuid';
import { serverTimestamp } from 'firebase/firestore';
import DiscussionsAdd from './DiscussionsAdd';
import Comments from './Comments';

function DiscussionsDetail({ currentUser, setCurrentUser }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showCommentQuill, setShowCommentQuill] = useState(false);
  const [comment, setComment] = useState('');
  const [isEnabled, setIsEnabled] = useState(false);
  const [comments, setComments] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  const getData = async () => {
    const newData = await getRootDocument(
      currentUser,
      'discussions',
      id,
      setData
    );

    const newComments = await getRootDocumentCollections(
      currentUser,
      'discussions',
      id,
      'comments',
      setComments
    );
  };

  const handleDelete = () => {
    deleteDocument(
      currentUser?.id,
      'discussions',
      id,
      '/discussions',
      navigate,
      true
    );
  };

  useEffect(() => {
    if (comment?.length > 1) {
      setIsEnabled(true);
    }
  }, [comment]);

  useEffect(() => {
    getData();
  }, [id]);

  const scrollTo = id => {
    const target = document.querySelector(`#${id}`);
    target.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };

  const handleFormSubmit = async () => {
    const commentObject = {
      id: /* data?.id || */ uuidv4(),
      date: new Date().toLocaleString(),
      timestamp: serverTimestamp(),
      comment,
      author: currentUser?.displayName,
      authorId: currentUser?.id,
      authorPhotoURL: currentUser?.photoURL,
    };
    const newCurrentUser = await addNewCommentToDiscussion(
      currentUser,
      data?.id,
      commentObject
    );
    setCurrentUser(newCurrentUser);
  };

  return (
    <div id="discussions" className="page-padding">
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Topic
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <DiscussionsAdd
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        discussion={data}
      />

      <Heading
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        fontSize={{
          base: '4xl',
          md: '5xl',
        }}
      >
        {data?.title}
        {currentUser?.id === data?.authorId && (
          <ButtonGroup>
            <Button colorScheme="gray" onClick={() => onOpen()}>
              Delete
            </Button>
            <Button colorScheme="blue" onClick={() => setIsModalOpen(true)}>
              Edit
            </Button>
          </ButtonGroup>
        )}
      </Heading>
      <div className="author">
        <Avatar
          backgroundColor={'gray.400'}
          size="2xl"
          className="avatar"
          src={data?.authorPhotoURL}
        ></Avatar>
        {data?.author} on {data?.date}
      </div>
      <div className="actions">
        <ButtonGroup mt={4}>
          <Button
            size="sm"
            gap={2}
            title="Comments"
            onClick={() => scrollTo('comments')}
          >
            <FaCommentAlt color="gray.300" /> {comments?.length}
          </Button>
          <Button size="sm" title="Report">
            <FaFlag color="gray.300" />
          </Button>
        </ButtonGroup>
      </div>
      <br />
      <div dangerouslySetInnerHTML={{ __html: data?.content }} />
      <br />
      {!showCommentQuill && (
        <>
          <Button onClick={() => setShowCommentQuill(!showCommentQuill)}>
            Write a Comment
          </Button>
          <br />
          <br />
        </>
      )}
      {showCommentQuill && (
        <form onSubmit={() => handleFormSubmit}>
          <Stack spacing={4}>
            <FormControl isRequired>
              <FormLabel>Comment</FormLabel>
              <ReactQuill theme="snow" value={comment} onChange={setComment} />
            </FormControl>
          </Stack>
          <Button
            isDisabled={!isEnabled}
            fontFamily={'heading'}
            mt={4}
            w={'full'}
            bgGradient="linear(to-r, red.400,pink.400)"
            color={'white'}
            _hover={{
              bgGradient: 'linear(to-r, red.400,pink.400)',
              boxShadow: 'xl',
            }}
            onClick={() => handleFormSubmit()}
          >
            Submit
          </Button>
        </form>
      )}
      <Comments
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        discussionId={id}
        comments={comments}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
}

export default DiscussionsDetail;
