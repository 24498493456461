import { Box, SimpleGrid, Text, Stack, Flex, Heading } from '@chakra-ui/react';
import { certifiedMembersData } from '../../data/certifiedmembers';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { getAllUsers, getCertifiedMemberProfiles } from '../../api/adminApi';

const Feature = ({ title, text, imgUrl, currentUser, index, id }) => {
  if (currentUser) {
    return (
      <>
        {/* <Link to={`/certified-members/${id}`}> */}
        <Stack alignItems="center">
          <Flex
            w={40}
            h={40}
            align={'center'}
            justify={'center'}
            color={'white'}
            rounded={'full'}
            bg={'gray.100'}
            mb={1}
            //backgroundColor={'#eee'}
            backgroundImage={
              imgUrl ? `url(${imgUrl})` : `url(${'/logo512.png'})`
            }
            backgroundSize="cover"
          />
          <Text fontWeight={600} textTransform={'capitalize'}>
            {title}
          </Text>
          <Text color={'gray.600'} textTransform={'capitalize'}>
            {text}
          </Text>
        </Stack>
        {/* </Link> */}
      </>
    );
  } else {
    if (index < 6) {
      return (
        <Stack alignItems="center">
          <Flex
            w={40}
            h={40}
            align={'center'}
            justify={'center'}
            color={'white'}
            rounded={'full'}
            bg={'gray.100'}
            mb={1}
            backgroundImage={`url(${imgUrl})`}
            backgroundSize="cover"
          />
          <Text fontWeight={600} textTransform={'capitalize'}>
            {title}
          </Text>
          <Text color={'gray.600'} textTransform={'capitalize'}>
            {text}
          </Text>
        </Stack>
      );
    } else {
      return (
        <Link to="/sign-in">
          <Stack alignItems="center">
            <Flex
              w={40}
              h={40}
              align={'center'}
              justify={'center'}
              color={'white'}
              rounded={'full'}
              bg={'gray.100'}
              mb={1}
              //backgroundImage={`url(${imgUrl})`}
              backgroundSize="cover"
            />
            <Text fontWeight={600}>Sign In To View</Text>
            {/* <Text color={'gray.600'}>{text}</Text> */}
          </Stack>
        </Link>
      );
    }
  }
};

export default function CertifiedMembers({ currentUser }) {
  const [cMembers, setCMembers] = useState([]);
  const [combinedMembers, setCombinedMembers] = useState([]);

  const getCertifiedMembers = async => {
    getCertifiedMemberProfiles(currentUser, setCMembers);
  };

  useEffect(() => {
    setCombinedMembers([...certifiedMembersData, ...cMembers]);
  }, [cMembers]);

  useEffect(() => {
    if (currentUser) {
      getCertifiedMembers();
    }
  }, []);

  return (
    <Box>
      <Box p={16} maxW={1200} margin="auto" textAlign="center">
        <Heading fontSize="2.5em" mb={12}>
          Certified Members
        </Heading>

        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
          {combinedMembers.map((c, index) => (
            <Feature
              currentUser={currentUser}
              index={index}
              imgUrl={c.photoURL || c.photoUrl}
              title={c.displayName}
              text={c.employer}
              id={c.id}
            />
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
}
