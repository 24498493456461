import {
  Stack,
  Flex,
  Button,
  Text,
  VStack,
  useBreakpointValue,
  Heading,
  ButtonGroup,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import './Hero.scss';

export default function Hero({ backgroundImage, actions }) {
  return (
    <div id="hero">
      <Flex
        w={'full'}
        h={{ base: '70vh', md: '100vh' }}
        //backgroundImage={`url(${backgroundImage})`}
        backgroundSize={'cover'}
        backgroundPosition={'center center'}
      >
        <video playsInline autoPlay muted loop poster={backgroundImage}>
          <source
            src="/landing/mixkit-petri-blood-cells-3951-medium.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        <VStack
          w={'full'}
          justify={'center'}
          px={useBreakpointValue({ base: 4, md: 8 })}
          bgGradient={'linear(to-b, blue.700, transparent)'}
        >
          <Stack maxW={'4xl'} align={'flex-start'} spacing={6}>
            <Heading fontSize="3.5em" color="white" fontFamily="Contrail One">
              BIOME SQUAD
              <span className="trademark">™</span>
            </Heading>

            <Heading
              lineHeight={1.1}
              display="flex"
              color={'white'}
              fontWeight={800}
              fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            >
              Uncover the beginning of microbiome research.
            </Heading>
            <Text
              color={'white'}
              fontWeight={600}
              lineHeight={1.2}
              fontSize={useBreakpointValue({ base: '1xl', md: '2xl' })}
            >
              Join a community of doctors, scientists and enthusiasts who are
              studying the microbiome and it's health implications.
            </Text>
            <Stack direction={'row'}>
              <ButtonGroup>
                {actions && (
                  <div>
                    {actions.map((a, index) => (
                      <Link key={`actions-${index}`} to={a.link}>
                        <Button
                          color={'white'}
                          fontWeight={800}
                          size="lg"
                          mt={2}
                          mr={2}
                          colorScheme="blue"
                        >
                          {a.title}
                        </Button>
                      </Link>
                    ))}
                    <Flex
                      mt={8}
                      direction="column"
                      color="white"
                      fontWeight={800}
                      align="center"
                      gap={4}
                    >
                      Or scan to register on your phone
                      <img
                        style={{ height: 100 }}
                        src="https://chart.googleapis.com/chart?cht=qr&chl=http%3A%2F%2Fthebiomesquad.com&chs=180x180&choe=UTF-8&chld=L|2"
                        alt="qr-code"
                      />
                    </Flex>
                  </div>
                )}
                {!actions && (
                  <Link to="/sign-up">
                    <Button
                      /* rounded={'full'} */
                      color={'white'}
                      fontWeight={800}
                      size="lg"
                      mt={2}
                      colorScheme="blue"
                    >
                      Sign Up For Free
                    </Button>
                  </Link>
                )}
              </ButtonGroup>
            </Stack>
          </Stack>
        </VStack>
      </Flex>
    </div>
  );
}
