import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

function CertifiedMembersDetail() {
  const { id } = useParams();

  useEffect(() => {
    console.log('id', id);
  }, [id]);

  return <div>CertifiedMembersDetail</div>;
}

export default CertifiedMembersDetail;
