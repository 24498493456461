import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  Avatar,
  Center,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './Profile.scss';
import { updateBio, updateProfilePic } from '../../api/api';

export default function EditProfile({ currentUser, setCurrentUser, fbUser }) {
  const [avatar, setAvatar] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [bio, setBio] = useState('');
  const navigate = useNavigate();
  const toast = useToast();

  const handleSubmit = () => {
    updateBio(currentUser, bio || '', displayName || '', setCurrentUser);
    setTimeout(() => {
      navigate('/profile');
    }, 1000);
  };

  useEffect(() => {
    setDisplayName(currentUser?.displayName);
    setBio(currentUser?.bio);
    setDisplayName(currentUser?.displayName);
  }, [currentUser]);

  const handleSetProfilePic = e => {
    const file = e.target.files[0];
    //setProfilePic(file);

    if (file) {
      //setAvatar(URL.createObjectURL(file));

      //save to firebase storage and url in user collection
      updateProfilePic(currentUser, file, setCurrentUser);

      toast({
        title: 'Profile Pic Saved!',
        status: 'success',
        duration: 6000,
        isClosable: false,
        position: 'top',
      });
    }
  };

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
      id="profile"
    >
      <Stack
        minW={'60vw'}
        spacing={4}
        w={'full'}
        maxW={'md'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}
      >
        <Heading lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl' }}>
          Edit Profile
        </Heading>
        {/* {!fbUser?.providerData[0]?.providerId?.includes('oogle') ? (
          <FormControl id="profilePic">
            <Stack direction={['row', 'row']} spacing={6} align="center">
              <div>
                <FormLabel>Profile Pic</FormLabel>
                <Button>
                  <Input
                    style={{
                      border: 'none',
                      paddingTop: '7.5px',
                      backgroundColor: 'transparent',
                    }}
                    type="file"
                    onChange={e => handleSetProfilePic(e)}
                  />
                </Button>
              </div>

              <Center>
                <Avatar
                  className="avatar"
                  size="xl"
                  src={currentUser?.photoURL}
                ></Avatar>
              </Center>
            </Stack>
          </FormControl>
        ) : (
          <Avatar size="xl" src={fbUser?.photoURL}></Avatar>
        )} */}
        <FormControl id="profilePic">
          <Stack direction={['row', 'row']} spacing={6} align="center">
            <div>
              <FormLabel>Profile Pic</FormLabel>
              <Button>
                <Input
                  style={{
                    border: 'none',
                    paddingTop: '7.5px',
                    backgroundColor: 'transparent',
                  }}
                  type="file"
                  onChange={e => handleSetProfilePic(e)}
                />
              </Button>
            </div>

            <Center>
              <Avatar
                className="avatar"
                size="xl"
                src={currentUser?.photoURL}
              ></Avatar>
            </Center>
          </Stack>
        </FormControl>
        <FormControl id="userName" isDisabled>
          <FormLabel>Email</FormLabel>
          <Input
            value={currentUser?.email}
            onChange={e => setDisplayName(e.target.value)}
            placeholder="displayName"
            _placeholder={{ color: 'gray.500' }}
            type="text"
            className={currentUser?.email.length > 0 ? 'filled' : ''}
          />
        </FormControl>
        <FormControl id="displayName" isRequired>
          <FormLabel>displayName (will show in your profile)</FormLabel>
          <Input
            value={displayName}
            onChange={e => setDisplayName(e.target.value)}
            placeholder="displayName"
            _placeholder={{ color: 'gray.500' }}
            type="text"
            className={displayName?.length > 0 ? 'filled' : ''}
          />
        </FormControl>
        <FormControl id="bio">
          <FormLabel>Bio</FormLabel>
          <ReactQuill theme="snow" value={bio} onChange={setBio} />
        </FormControl>
        <Stack spacing={6} direction={['column', 'row']}>
          <Button
            bg={'red.400'}
            color={'white'}
            w="full"
            _hover={{
              bg: 'red.500',
            }}
            onClick={() => navigate('/profile')}
          >
            Cancel
          </Button>
          <Button
            bg={'blue.400'}
            color={'white'}
            w="full"
            _hover={{
              bg: 'blue.500',
            }}
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Flex>
  );
}
