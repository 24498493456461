import React from 'react';
import LargeQuote from '../../Layouts/LargeQuote/LargeQuote';
import Hero from '../../Layouts/Hero/Hero';
import HeroVideo from '../../Layouts/HeroVideo/HeroVideo';
import AsSeenIn from '../../Layouts/AsSeenIn/AsSeenIn';
import HeroImage from '../../Layouts/HeroImage/HeroImage';
import BecomeCertified from '../BecomeCertified/BecomeCertified';
import MembershipTypes from '../../Layouts/MembershipTypes/MembershipTypes';
import LandingHazan from './LandingHazan';

function Landing() {
  return (
    <div id="landing">
      <Hero
        backgroundImage={
          '/landing/GettyImages-1279892500-scaled-e1625856527499.jpg'
        }
      />
      <LandingHazan />
      <MembershipTypes />
      {/* <HeroImage /> */}
      <div style={{ overflow: 'hidden' }}>
        <HeroVideo />
      </div>
      <LargeQuote
        quote="Dr. Hazan is a firm believer that disease can only be understood through precision medicine by focusing on the individual and the changes within. Much like each person has unique fingerprints, no two people have the same microbiome. She also believes in the importance of assessing disease by looking at family. Her overall goal is to understand the microbes that allow us to function, from those we are born with to those that will decompose us when we die. She hopes that her quest for individual microbiome understanding leads to an awareness we are simply a diverse collection of microbes, working together to shape the world."
        authorTitle="Founder & CEO, Progenabiome and the Biome Squad"
        authorName="Sabine Hazan"
        avatar="/squad/Sabine.jpg"
      />

      <AsSeenIn />
    </div>
  );
}

export default Landing;
