import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Stat,
  StatHelpText,
  StatNumber,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { addDocument, getDocuments } from '../../api/api';
import ReactQuill from 'react-quill';
import { serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { getAge } from '../../Helpers/UserHelpers';

function LabsAdd({
  currentUser,
  setCurrentUser,
  isOpen,
  setIsOpen,
  lab,
  patient,
  adminSelectedUserId,
  callback,
  isResending,
}) {
  const navigate = useNavigate();
  const toast = useToast();
  const [needsParent, setNeedsParent] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [tempId, setTempId] = useState(null);
  const [email, setEmail] = useState('');
  const [parentsEmail, setParentsEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobile, setMobile] = useState('');
  const [note, setNote] = useState('');
  const [dob, setDob] = useState(null);
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  const [address, setAddress] = useState('');
  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [floraNumber, setFloraNumber] = useState('');

  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (patient) {
      setSelectedPatientId(patient.id);
      setSelectedPatient(patient);
      setAddress(patient.address);
    } else {
      getDocuments(
        adminSelectedUserId || currentUser?.id,
        'patients',
        setPatients,
        patient
      );
    }
  }, [patient]);

  useEffect(() => {
    if (
      getAge(new Date(selectedPatient?.dob)) > 11 &&
      getAge(new Date(selectedPatient?.dob)) < 18
    ) {
      setParentsEmail(selectedPatient?.parentsEmail);
      setNeedsParent(true);
    } else {
      setParentsEmail('');
      setNeedsParent(false);
    }
  }, [selectedPatient]);

  useEffect(() => {
    const p = patients.find(p => p.id === selectedPatientId);
    if (p) {
      setTempId(uuidv4());
      setSelectedPatient(p);
      setAddress(p?.address || '');
      setEmail(p?.email || '');
      setFirstName(p?.firstName || '');
      setLastName(p?.lastName || '');
      setMobile(p?.mobile || '');
      setDob(p?.dob || null);
      setSelectedPatientId(p?.id);
    }
  }, [selectedPatientId]);

  useEffect(() => {
    setEmail(lab?.email || '');
    setFirstName(lab?.firstName || '');
    setLastName(lab?.lastName || '');
    setMobile(lab?.mobile || '');
    setNote(lab?.note || '');
    setDob(lab?.dob || null);
    setDate(lab?.date || new Date().toISOString().substring(0, 10));
    setAddress(lab?.address || '');
    setSelectedPatientId(lab?.patientId || '');
    setSelectedPatient(lab?.patient || null);
    setFloraNumber(lab?.floraNumber || '');
  }, [lab]);

  useEffect(() => {
    if (selectedPatientId?.length > 0 && date?.length > 0) {
      setIsEnabled(true);
    }
  }, [selectedPatientId, date]);

  const handleFormSubmit = async () => {
    const labObject = {
      id: lab?.id || tempId,
      date,
      timestamp: lab?.timestamp || serverTimestamp(),
      address,
      note,
      status: floraNumber?.length > 0 ? 'completed' : 'ordered',
      patientId: selectedPatient.id,
      patient: selectedPatient,
      floraNumber,
    };

    if (
      currentUser?.type === 'admin' &&
      labObject?.status === 'ordered' &&
      floraNumber?.length > 0
    ) {
      callback();
    }
    //submit google form to trigger email
    if (!lab) {
      document.querySelector('#submitconsentform').click();
      toast({
        title: `Consent Email sent to ${lab?.patient?.email || email}`,
        description: `Disclosure forms were included in the email.`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } else {
      if (isResending) document.querySelector('#submitconsentform').click();
      toast({
        title: `Successfully saved changes.`,
        description: ``,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    }

    const newCurrentUser = await addDocument(
      currentUser,
      'labs',
      labObject,
      adminSelectedUserId
    );
    setCurrentUser(newCurrentUser);
  };

  useEffect(() => {
    if (isResending) {
      handleFormSubmit();
    }
  }, [isResending]);

  return (
    <div id="labs-add">
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{lab ? 'Edit' : 'Order'} Lab</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <Alert status="warning" fontWeight={800}>
              <AlertIcon />
              Please to complete your profile.
            </Alert> */}
            {patients.length === 0 && (
              <Flex direction="column" gap={4} align="center">
                <Heading
                  fontWeight={600}
                  fontSize={{ base: '3xl', sm: '4xl', md: '5xl' }}
                  lineHeight={'110%'}
                >
                  Hi {currentUser?.displayName},
                </Heading>
                <Text color={'gray.500'} maxW={'3xl'}>
                  to order a lab, you must first add a patient.
                </Text>
                <Stack spacing={6} mt={4} direction={'row'}>
                  <Button
                    size={'lg'}
                    px={6}
                    colorScheme={'blue'}
                    onClick={() => navigate('/patients')}
                  >
                    Add New Patient
                  </Button>
                </Stack>
              </Flex>
            )}
            {patients.length > 0 && (
              <>
                <form onSubmit={() => handleFormSubmit}>
                  <Stack spacing={4}>
                    <Flex gap={4}>
                      {!patient && (
                        <FormControl isRequired>
                          <FormLabel>Patient</FormLabel>
                          <Select
                            placeholder="Select Patient"
                            value={selectedPatientId}
                            onChange={e => {
                              setSelectedPatientId(e.target.value);
                            }}
                          >
                            {patients.map(c => (
                              <option value={c.id}>
                                {c.firstName} {c.lastName} (
                                {c.dob && new Date(c.dob).toLocaleDateString()})
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      )}

                      <FormControl isRequired>
                        {date?.length > 5 && <FormLabel>Order Date</FormLabel>}
                        <Input
                          placeholder="Order Date"
                          size="md"
                          type="date"
                          value={date}
                          onChange={e => setDate(e.target.value)}
                        />
                      </FormControl>
                    </Flex>
                    {selectedPatient && (
                      <FormControl>
                        <Stat>
                          <StatNumber>
                            {selectedPatient.firstName}{' '}
                            {selectedPatient.lastName} (
                            {selectedPatient.dob &&
                              new Date(
                                selectedPatient.dob
                              ).toLocaleDateString()}
                            , {getAge(new Date(selectedPatient.dob))} yrs old)
                          </StatNumber>
                          <StatHelpText></StatHelpText>
                          <StatHelpText>{selectedPatient.address}</StatHelpText>
                          <StatHelpText>
                            {selectedPatient.email} | {selectedPatient.mobile}
                          </StatHelpText>
                        </Stat>
                      </FormControl>
                    )}
                    {needsParent && (
                      <FormControl isRequired>
                        <FormLabel>
                          Parent/Guardian's Email Address (both parent and
                          patient will receive a link to the NDA that they will
                          have to sign)
                        </FormLabel>
                        <Input
                          placeholder="Parent/Guardian Email Address"
                          value={parentsEmail}
                          onChange={e => setParentsEmail(e.target.value)}
                          bg={'gray.100'}
                          border={0}
                          color={'gray.500'}
                          _placeholder={{
                            color: 'gray.500',
                          }}
                          className={'filled'}
                        />
                      </FormControl>
                    )}

                    <FormControl>
                      <FormLabel>Home/Shipping Address</FormLabel>
                      <Input
                        placeholder="Shipping Address"
                        value={address}
                        onChange={e => setAddress(e.target.value)}
                        bg={'gray.100'}
                        border={0}
                        color={'gray.500'}
                        _placeholder={{
                          color: 'gray.500',
                        }}
                        className={address?.length > 0 ? 'filled' : ''}
                      />
                    </FormControl>
                    {currentUser?.type === 'admin' && (
                      <FormControl>
                        <FormLabel>Flora Number</FormLabel>
                        <Input
                          placeholder="Flora Number"
                          value={floraNumber}
                          onChange={e => setFloraNumber(e.target.value)}
                          bg={'gray.100'}
                          border={0}
                          color={'gray.500'}
                          _placeholder={{
                            color: 'gray.500',
                          }}
                          className={floraNumber?.length > 0 ? 'filled' : ''}
                        />
                      </FormControl>
                    )}

                    <FormControl>
                      <FormLabel>Notes</FormLabel>
                      <ReactQuill
                        theme="snow"
                        value={note}
                        onChange={setNote}
                      />
                    </FormControl>
                  </Stack>
                  <Button
                    isDisabled={!isEnabled}
                    fontFamily={'heading'}
                    mt={4}
                    w={'full'}
                    bgGradient="linear(to-r, red.400,pink.400)"
                    color={'white'}
                    _hover={{
                      bgGradient: 'linear(to-r, red.400,pink.400)',
                      boxShadow: 'xl',
                    }}
                    onClick={() => handleFormSubmit()}
                  >
                    Submit
                  </Button>
                </form>
                <br />
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <iframe
        title="secret-frame-one"
        name="secret-frame-one"
        width="0"
        height="0"
        border="0"
        style={{ display: 'none' }}
      ></iframe>
      <form
        className="form"
        action="https://docs.google.com/forms/d/e/1FAIpQLSd0siGl6pf4z1J7kEdxk6mzAqZo0YTd_pULjJMNBa9gk38B6Q/formResponse"
        id="ss-form1"
        method="POST"
        target="secret-frame-one"
        style={{ display: 'none' }}
      >
        <div className="form-group">
          <label htmlFor="dateValue">Date</label>
          <input name="entry.1726393305" readOnly type="text" value={date} />
        </div>
        <div className="form-group">
          <label htmlFor="labId">LabId</label>
          <input
            name="entry.1252597670"
            readOnly
            type="text"
            value={lab?.id || tempId}
          />
        </div>
        <div className="form-group">
          <label htmlFor="patientId">PatientId</label>
          <input
            name="entry.2107253374"
            readOnly
            type="text"
            value={selectedPatientId}
          />
        </div>
        <div className="form-group">
          <label htmlFor="patientEmail">PatientEmail</label>
          <input name="entry.235882463" readOnly type="email" value={email} />
        </div>
        <div className="form-group">
          <label htmlFor="patientFirstName">PatientFirstName</label>
          <input
            name="entry.1702512101"
            readOnly
            type="text"
            value={firstName}
          />
        </div>
        <div className="form-group">
          <label htmlFor="doctorName">DoctorName</label>
          <input
            name="entry.850389264"
            readOnly
            type="text"
            value={
              adminSelectedUserId
                ? `${currentUser?.displayName} (Biomesquad Admin) on behalf of your doctor`
                : currentUser?.displayName
            }
          />
        </div>
        <div className="form-group">
          <label htmlFor="type">Type</label>
          <input
            name="entry.351738770"
            readOnly
            type="text"
            value={lab ? 'edit' : 'new'}
          />
        </div>
        <div className="form-group">
          <label htmlFor="type">Age</label>
          <input
            name="entry.874767956"
            readOnly
            type="text"
            value={getAge(new Date(selectedPatient?.dob || lab?.patient?.dob))}
          />
        </div>
        <div className="form-group">
          <label htmlFor="parent">Parent Email</label>
          <input
            name="entry.1220017171"
            readOnly
            type="text"
            value={parentsEmail}
          />
        </div>
        <input
          id="submitconsentform"
          type="submit"
          value="Submit Consent Form"
        />
      </form>
    </div>
  );
}

export default LabsAdd;
