import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  Heading,
  Stack,
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

function LandingHazan({ actions }) {
  return (
    <div id="landing-hazan">
      <Box bg={'blue.900'} position={'relative'}>
        <Container
          style={{ padding: '8em 1em 10em' }}
          className="container-hazan"
          maxW={'4xl'}
          zIndex={10}
          position={'relative'}
          textAlign={'center'}
        >
          <Heading
            lineHeight={1.1}
            fontWeight={800}
            color="white"
            fontSize={{ base: '1xl', sm: '3xl', md: '5xl' }}
          >
            <Flex gap={4} align="center">
              Learn more about
              <Heading
                fontSize="1em"
                mt=".25em"
                color="white"
                fontFamily="Contrail One"
              >
                THE BIOME SQUAD
                <span className="trademark">™</span>
              </Heading>
            </Flex>
            {!actions && 'from its founder Dr. Sabine Hazan'}
          </Heading>
          <p
            style={{ color: 'rgba(255,255,255,.75)', margin: '1em auto .5em' }}
          >
            Dr. Hazan is a leading gastroenterologist and the CEO of{' '}
            <a
              href="https://progenabiome.com/"
              target="_blank"
              rel="noreferrer"
              style={{ fontWeight: 'bold', textDecoration: 'underline' }}
            >
              Progenabiome
            </a>
            . She has over 30 years of experience in understanding the
            microbiome and its health implications - and is at the forefront of
            medical research in this area.
          </p>
          <iframe
            style={{ marginTop: '2em' }}
            width="100%"
            height="400px"
            src="https://www.youtube.com/embed/2rNSQXoVm5k?si=lsApw20CTwIZ7r-n"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          {actions && (
            <Stack direction={'row'} mt={6}>
              <ButtonGroup>
                {actions && (
                  <div>
                    {actions.map((a, index) => (
                      <Link key={`actions-${index}`} to={a.link}>
                        <Button
                          color={'white'}
                          fontWeight={800}
                          size="lg"
                          mt={2}
                          mr={2}
                          colorScheme="blue"
                        >
                          {a.title}
                        </Button>
                      </Link>
                    ))}
                  </div>
                )}
                {!actions && (
                  <Link to="/sign-up">
                    <Button
                      /* rounded={'full'} */
                      color={'white'}
                      fontWeight={800}
                      size="lg"
                      mt={2}
                      colorScheme="blue"
                    >
                      Sign Up For Free
                    </Button>
                  </Link>
                )}
              </ButtonGroup>
            </Stack>
          )}
        </Container>
      </Box>
    </div>
  );
}

export default LandingHazan;
