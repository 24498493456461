import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { addDocument } from '../../api/api';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { carriers } from '../../data/carriers';
import { serverTimestamp } from 'firebase/firestore';
import { getAge } from '../../Helpers/UserHelpers';

function PatientsAdd({
  currentUser,
  setCurrentUser,
  isOpen,
  setIsOpen,
  patient,
  adminSelectedUserId,
}) {
  const [needsParent, setNeedsParent] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [email, setEmail] = useState('');
  const [parentsEmail, setParentsEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobile, setMobile] = useState('');
  const [note, setNote] = useState('');
  const [dob, setDob] = useState(null);
  const [date, setDate] = useState(
    patient?.date || new Date().toISOString().substring(0, 10)
  );
  const [address, setAddress] = useState('');
  const [mobileCarrier, setMobileCarrier] = useState('');
  const [mobileWithCarrier, setMobileWithCarrier] = useState('');
  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setEmail(patient?.email || '');
    setEmail(patient?.parentsEmail || '');
    setFirstName(patient?.firstName || '');
    setLastName(patient?.lastName || '');
    setMobile(patient?.mobile || '');
    setNote(patient?.note || '');
    setDob(patient?.dob || null);
    setDate(patient?.date || new Date().toISOString().substring(0, 10));
    setAddress(patient?.address || '');
    setMobileCarrier(patient?.mobileCarrier || '');
  }, [patient]);

  useEffect(() => {
    const patientAge = getAge(new Date(dob));
    if (patientAge > 11 && patientAge < 18) {
      setNeedsParent(true);
    } else {
      setNeedsParent(false);
    }
  }, [dob]);

  useEffect(() => {
    if (
      email.length > 0 &&
      firstName.length > 0 &&
      lastName.length > 0 &&
      address.length > 0
    ) {
      setIsEnabled(true);
    }
  }, [email, firstName, lastName, address]);

  const handleFormSubmit = async () => {
    const patientObject = {
      id: patient?.id || uuidv4(),
      date,
      timestamp: serverTimestamp(),
      email,
      parentsEmail,
      firstName,
      lastName,
      dob,
      address,
      mobile,
      note,
      mobileCarrier,
      mobileWithCarrier:
        mobile + carriers.find(c => c.name === mobileCarrier).extension,
    };
    const newCurrentUser = await addDocument(
      currentUser,
      'patients',
      patientObject,
      adminSelectedUserId
    );
    setCurrentUser(newCurrentUser);
  };
  return (
    <div id="labs-add">
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnEsc
        closeOnOverlayClick
        size="full"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{patient ? 'Edit' : 'Add'} Patient</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={() => handleFormSubmit}>
              <Stack spacing={4}>
                <Flex gap={4}>
                  <FormControl isRequired>
                    <FormLabel>First Name</FormLabel>

                    <Input
                      placeholder="First Name..."
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                      bg={'gray.100'}
                      border={0}
                      color={'gray.500'}
                      _placeholder={{
                        color: 'gray.500',
                      }}
                      className={firstName.length > 0 ? 'filled' : ''}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                      placeholder="Last Name..."
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                      bg={'gray.100'}
                      border={0}
                      color={'gray.500'}
                      _placeholder={{
                        color: 'gray.500',
                      }}
                      className={lastName.length > 0 ? 'filled' : ''}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>
                      Patient Date of Birth {dob && `(Age: ${getAge(dob)})`}
                    </FormLabel>

                    <Input
                      placeholder="Date of Birth"
                      size="md"
                      type="date"
                      value={dob}
                      onChange={e => setDob(e.target.value)}
                    />
                  </FormControl>
                </Flex>
                {needsParent && (
                  <FormControl isRequired>
                    <FormLabel>Parents Email</FormLabel>
                    <Input
                      placeholder="Parents Email..."
                      value={parentsEmail}
                      onChange={e => setParentsEmail(e.target.value)}
                      bg={'gray.100'}
                      border={0}
                      color={'gray.500'}
                      _placeholder={{
                        color: 'gray.500',
                      }}
                      className={parentsEmail.length > 0 ? 'filled' : ''}
                    />
                  </FormControl>
                )}
                <Flex gap={4}>
                  <FormControl isRequired>
                    <FormLabel>Email</FormLabel>
                    <Input
                      placeholder="Email..."
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      bg={'gray.100'}
                      border={0}
                      color={'gray.500'}
                      _placeholder={{
                        color: 'gray.500',
                      }}
                      className={email.length > 0 ? 'filled' : ''}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>
                      Mobile Number (Include country code if outside US)
                    </FormLabel>

                    <Input
                      placeholder="Mobile Number "
                      value={mobile}
                      onChange={e => setMobile(e.target.value)}
                      bg={'gray.100'}
                      border={0}
                      color={'gray.500'}
                      _placeholder={{
                        color: 'gray.500',
                      }}
                      className={mobile.length > 0 ? 'filled' : ''}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Mobile Carrier</FormLabel>

                    <Select
                      placeholder="Select option"
                      value={mobileCarrier}
                      onChange={e => {
                        setMobileCarrier(e.target.value);
                      }}
                    >
                      {carriers.map(c => (
                        <option value={c.name}>{c.name}</option>
                      ))}
                    </Select>
                  </FormControl>
                </Flex>
                <FormControl isRequired>
                  <FormLabel>Home/Shipping Address</FormLabel>
                  <Input
                    placeholder="Shipping Address"
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                    bg={'gray.100'}
                    border={0}
                    color={'gray.500'}
                    _placeholder={{
                      color: 'gray.500',
                    }}
                    className={address.length > 0 ? 'filled' : ''}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Notes</FormLabel>
                  <ReactQuill theme="snow" value={note} onChange={setNote} />
                </FormControl>
              </Stack>
              <Button
                isDisabled={!isEnabled}
                fontFamily={'heading'}
                mt={4}
                w={'full'}
                bgGradient="linear(to-r, red.400,pink.400)"
                color={'white'}
                _hover={{
                  bgGradient: 'linear(to-r, red.400,pink.400)',
                  boxShadow: 'xl',
                }}
                onClick={() => handleFormSubmit()}
              >
                Save
              </Button>
            </form>
            <br />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default PatientsAdd;
