'use client';

import {
  Flex,
  Container,
  Heading,
  Stack,
  Text,
  Button,
  Icon,
  IconProps,
  Image,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { certifiedMembersData } from '../../data/certifiedmembers';

export default function LabsPlaceholder({ currentUser, callback }) {
  const navigate = useNavigate();
  return (
    <Container maxW={'5xl'}>
      <Stack
        textAlign={'center'}
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
          lineHeight={'110%'}
        >
          Order Labs for Your Patients
        </Heading>
        <Text color={'gray.500'} maxW={'3xl'}>
          Certified members who treat patients can order Progenabiome microbiota
          labs to gain insight on a patients wellbeing. Become a certified
          member today.
        </Text>
        <Stack spacing={6} direction={'row'}>
          {(currentUser?.membership === 'Certified' ||
            currentUser?.membership === 'Premium') &&
          currentUser?.type !==
            certifiedMembersData[certifiedMembersData.length - 7].value ? (
            <Button
              size={'lg'}
              px={6}
              colorScheme={'blue'}
              onClick={() => callback(true)}
            >
              Order Lab
            </Button>
          ) : (
            <Button
              size={'lg'}
              px={6}
              colorScheme={'blue'}
              onClick={() => navigate('/become-certified')}
            >
              Learn More
            </Button>
          )}
        </Stack>
        <Flex w={'full'}>
          <Image
            borderRadius="full"
            boxSize="60%"
            m="auto"
            src="/assets/istockphoto-1359513129-612x612.jpg"
            alt="Labs"
          />
        </Flex>
      </Stack>
    </Container>
  );
}
