import React, { useRef, useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Heading,
  Stack,
  Td,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { FaFlag, FaReply } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { deleteComment, editComment } from '../../api/api';

function Comment({ currentUser, setCurrentUser, data, discussionId }) {
  const [showCommentQuill, setShowCommentQuill] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [comment, setComment] = useState(data?.comment);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  const navigate = useNavigate();

  const handleEditComment = async () => {
    const commentObject = {
      ...data,
      comment,
    };
    const newCurrentUser = await editComment(
      currentUser,
      setCurrentUser,
      discussionId,
      commentObject
    );
    setCurrentUser(newCurrentUser);
  };

  const handleDeleteComment = async () => {
    const newCurrentUser = await deleteComment(
      currentUser,
      setCurrentUser,
      discussionId,
      data
    );
    setCurrentUser(newCurrentUser);
  };

  return (
    <Tr>
      <Td>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Comment
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={handleDeleteComment} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        <Heading size="md">{data?.title}</Heading>
        <div
          className="author"
          onClick={() => navigate(`/profile/${data?.authorId}`)}
        >
          <Avatar
            backgroundColor={'gray.400'}
            size="2xl"
            className="avatar"
            src={data?.authorPhotoURL}
          ></Avatar>
          {data?.author} on {data?.date}
        </div>
        <div
          className="preview"
          dangerouslySetInnerHTML={{ __html: data?.comment }}
        />
        {currentUser?.id === data?.authorId && (
          <div className="actions">
            <ButtonGroup mt={4}>
              <Button size="sm" gap={2} title="Comments">
                <FaReply color="gray.300" />
              </Button>
              <Button size="sm" title="Report">
                <FaFlag color="gray.300" />
              </Button>
              <Button
                size="sm"
                title="Delete"
                colorScheme="gray"
                onClick={() => onOpen()}
              >
                Delete
              </Button>
              <Button
                size="sm"
                title="Edit"
                colorScheme="blue"
                onClick={() => setShowCommentQuill(true)}
              >
                Edit
              </Button>
            </ButtonGroup>
          </div>
        )}

        {showCommentQuill && (
          <form>
            <Stack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Edit Comment</FormLabel>
                <ReactQuill
                  theme="snow"
                  value={comment}
                  onChange={setComment}
                />
              </FormControl>
            </Stack>
            <Button
              fontFamily={'heading'}
              mt={4}
              w={'full'}
              bgGradient="linear(to-r, red.400,pink.400)"
              color={'white'}
              _hover={{
                bgGradient: 'linear(to-r, red.400,pink.400)',
                boxShadow: 'xl',
              }}
              onClick={() => handleEditComment()}
            >
              Submit
            </Button>
          </form>
        )}
      </Td>
    </Tr>
  );
}

export default Comment;
