import React, { useEffect, useState } from 'react';
import {
  Stack,
  Input,
  Button,
  FormControl,
  FormLabel,
  Spinner,
  Select,
  Flex,
} from '@chakra-ui/react';
import { certifiedMembersConferenceType } from '../../data/certifiedmembers';
import { useNavigate } from 'react-router-dom';

export default function ConferenceRegister({ currentUser, setCurrentUser }) {
  const [isEnabled, setIsEnabled] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [licenseNumber, setLicenseNumber] = useState('');
  const [specialty, setSpecialty] = useState('');
  const [employer, setEmployer] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [selectedType, setSelectedType] = useState(
    certifiedMembersConferenceType[0].value
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (
      mobile.length > 5 &&
      firstName.length > 1 &&
      lastName.length > 1 &&
      email.length > 3 &&
      mobile.length > 5
    ) {
      setIsEnabled(true);
      return;
    } else {
      setIsEnabled(false);
    }
  }, [firstName, lastName, email, mobile]);

  const signUpUser = () => {
    setIsSending(true);
    setIsEnabled(false);
    document.querySelector('#submitForm').click(e => e.preventDefault());
    setTimeout(() => {
      setIsSending(false);
      setIsEnabled(false);
      setDisplayName('');
      setMobile('');
      setSpecialty('');
      setEmployer('');
      setAddress('');
      setCity('');
      setState('');
      setZip('');
      setSelectedType(certifiedMembersConferenceType[0].value);
      setLicenseNumber('');
      navigate('/conference/success');
      window.scrollTo(0, 0);
    }, 500);
  };

  return (
    <>
      <form>
        <Stack spacing={4}>
          <FormControl isRequired>
            {/* <FormLabel>I am a</FormLabel> */}
            <Select
              placeholder="Select Type"
              value={selectedType}
              onChange={e => {
                setSelectedType(e.target.value);
              }}
            >
              {certifiedMembersConferenceType.map(c => (
                <option value={c.value} key={c.id}>
                  {c.value}
                </option>
              ))}
            </Select>
          </FormControl>
          <Flex gap={4}>
            <FormControl isRequired>
              {/* {firstName.length > 5 && <FormLabel>First Name</FormLabel>} */}
              <Input
                placeholder="First Name *"
                bg={'gray.100'}
                border={0}
                color={'gray.500'}
                _placeholder={{
                  color: 'gray.500',
                }}
                className={firstName?.length > 0 ? 'filled' : ''}
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              {/* {lastName.length > 5 && <FormLabel>Last Name</FormLabel>} */}
              <Input
                placeholder="Last Name *"
                bg={'gray.100'}
                border={0}
                color={'gray.500'}
                _placeholder={{
                  color: 'gray.500',
                }}
                className={lastName?.length > 0 ? 'filled' : ''}
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              />
            </FormControl>
          </Flex>
          <Flex gap={4}>
            <FormControl isRequired>
              {/* {email.length > 5 && <FormLabel>Email</FormLabel>} */}
              <Input
                placeholder="Email *"
                bg={'gray.100'}
                border={0}
                color={'gray.500'}
                _placeholder={{
                  color: 'gray.500',
                }}
                className={email?.length > 0 ? 'filled' : ''}
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              {/* {mobile.length > 5 && (
                <FormLabel>
                  Mobile/Cell (Include country code if outside US)
                </FormLabel>
              )} */}
              <Input
                placeholder="Mobile/Cell *"
                bg={'gray.100'}
                border={0}
                color={'gray.500'}
                _placeholder={{
                  color: 'gray.500',
                }}
                className={mobile?.length > 0 ? 'filled' : ''}
                value={mobile}
                onChange={e => setMobile(e.target.value)}
              />
            </FormControl>
          </Flex>
          <Flex gap={4}>
            <FormControl isRequired>
              {/* {specialty.length > 1 && (
                <FormLabel>Specialty (GI, neuro, internal, etc.)</FormLabel>
              )} */}
              <Input
                placeholder="Specialty (GI, neuro, internal, etc.)"
                bg={'gray.100'}
                border={0}
                color={'gray.500'}
                _placeholder={{
                  color: 'gray.500',
                }}
                className={specialty?.length > 0 ? 'filled' : ''}
                value={specialty}
                onChange={e => setSpecialty(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              {/* {employer.length > 1 && (
                <FormLabel>Employer ('private practice' is ok)</FormLabel>
              )} */}
              <Input
                placeholder="Employer ('Private Practice' is ok)"
                bg={'gray.100'}
                border={0}
                color={'gray.500'}
                _placeholder={{
                  color: 'gray.500',
                }}
                className={employer?.length > 0 ? 'filled' : ''}
                value={employer}
                onChange={e => setEmployer(e.target.value)}
              />
            </FormControl>
          </Flex>
          {selectedType !==
            certifiedMembersConferenceType[
              certifiedMembersConferenceType.length - 1
            ].value && (
            <FormControl>
              {/* {licenseNumber.length > 1 && (
                <FormLabel>DEA, NPI, or License Number</FormLabel>
              )} */}
              <Input
                placeholder="DEA, NPI, or License Number"
                bg={'gray.100'}
                border={0}
                color={'gray.500'}
                _placeholder={{
                  color: 'gray.500',
                }}
                value={licenseNumber}
                className={licenseNumber?.length > 0 ? 'filled' : ''}
                onChange={e => setLicenseNumber(e.target.value)}
              />
            </FormControl>
          )}
          DEA and NPI number or License Number,
          <FormControl isRequired>
            {/* <FormLabel>Address</FormLabel> */}
            <Input
              placeholder="Street Address"
              bg={'gray.100'}
              border={0}
              color={'gray.500'}
              _placeholder={{
                color: 'gray.500',
              }}
              className={address?.length > 0 ? 'filled' : ''}
              value={address}
              onChange={e => setAddress(e.target.value)}
            />
          </FormControl>
          <Flex gap={4}>
            <FormControl isRequired>
              {/* <FormLabel>City</FormLabel> */}
              <Input
                placeholder="City"
                bg={'gray.100'}
                border={0}
                color={'gray.500'}
                _placeholder={{
                  color: 'gray.500',
                }}
                className={city?.length > 0 ? 'filled' : ''}
                value={city}
                onChange={e => setCity(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              {/* <FormLabel>State</FormLabel> */}
              <Input
                placeholder="State"
                bg={'gray.100'}
                border={0}
                color={'gray.500'}
                _placeholder={{
                  color: 'gray.500',
                }}
                className={state?.length > 0 ? 'filled' : ''}
                value={state}
                onChange={e => setState(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              {/* <FormLabel>Zip</FormLabel> */}
              <Input
                placeholder="Zip"
                bg={'gray.100'}
                border={0}
                color={'gray.500'}
                _placeholder={{
                  color: 'gray.500',
                }}
                className={zip?.length > 0 ? 'filled' : ''}
                value={zip}
                onChange={e => setZip(e.target.value)}
              />
            </FormControl>
          </Flex>
        </Stack>
        <Flex gap={4}>
          <Button
            fontFamily={'heading'}
            mt={8}
            w={'full'}
            onClick={() => navigate('/conference')}
          >
            Back
          </Button>
          <Button
            isDisabled={!isEnabled}
            fontFamily={'heading'}
            mt={8}
            w={'full'}
            colorScheme="blue"
            onClick={() => signUpUser()}
          >
            {isSending ? (
              <>
                <Spinner
                  color="white.500"
                  size="sm"
                  style={{ marginRight: '10px' }}
                />
                Saving
              </>
            ) : (
              'Submit Registration'
            )}
          </Button>
        </Flex>
      </form>

      <iframe
        title="secret-frame-one"
        name="secret-frame-one"
        width="0"
        height="0"
        border="0"
        style={{ display: 'none' }}
      ></iframe>
      <form
        className="form"
        action="https://docs.google.com/forms/d/e/1FAIpQLSdZr2H2lfvp8CRVVPzOzcCpVQwOEYeFqkQ2GBy00YymKTijFg/formResponse"
        id="ss-form1"
        method="POST"
        target="secret-frame-one"
        style={{ display: 'none' }}
      >
        <div className="form-group">
          <input
            name="entry.2039558026"
            readOnly
            value={`${firstName} ${lastName}`}
          />
          <input name="entry.490897900" readOnly value={firstName} />
          <input name="entry.1681210257" readOnly value={lastName} />
          <input name="entry.1696734604" readOnly type="email" value={email} />
          <input name="entry.148407947" readOnly value={mobile} />
          <input name="entry.1800551927" readOnly value={specialty} />
          <input name="entry.1818380422" readOnly value={employer} />
          <input name="entry.145475417" readOnly value={licenseNumber} />
          <input name="entry.122358251" readOnly value={address} />
          <input name="entry.382844889" readOnly value={city} />
          <input name="entry.1502726728" readOnly value={state} />
          <input name="entry.827554057" readOnly value={zip} />
        </div>

        <input id="submitForm" type="submit" value="Submit Subscribe" />
      </form>
    </>
  );
}
