import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Stack,
  Heading,
  Text,
  Container,
  Input,
  Button,
  SimpleGrid,
  Avatar,
  AvatarGroup,
  useBreakpointValue,
  Icon,
  FormControl,
  Center,
  AvatarBadge,
  IconButton,
} from '@chakra-ui/react';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { certifiedMembersData } from '../../data/certifiedmembers';

const Blur = props => {
  return (
    <Icon
      width={useBreakpointValue({ base: '100%', md: '40vw', lg: '30vw' })}
      zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="71" cy="61" r="111" fill="#F56565" />
      <circle cx="244" cy="106" r="139" fill="#ED64A6" />
      <circle cy="291" r="139" fill="#ED64A6" />
      <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936" />
      <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B" />
      <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78" />
      <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" />
    </Icon>
  );
};

export default function Register() {
  const [avatar, setAvatar] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [cv, setCV] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [mobile, setMobile] = useState('');
  const [specialty, setSpecialty] = useState('');
  const [employer, setEmployer] = useState('');

  useEffect(() => {
    if (profilePic) {
      console.log(URL.createObjectURL(profilePic));
      setAvatar(URL.createObjectURL(profilePic));
    }
  }, [profilePic]);

  const handleFormSubmit = () => {
    const formData = new FormData();
    formData.append('name', `${firstName}-${lastName}`);
    formData.append('file', profilePic);

    /* axios
      .post(UPLOAD_URL, formData)
      .then((res) => {
        alert("File Upload success");
      })
      .catch((err) => alert("File Upload Error")); */
  };

  const isEnabled = email.length > 0 && password.length > 0;

  return (
    <Box id="register" position={'relative'}>
      <Blur
        position={'absolute'}
        top={-10}
        left="-15vw"
        width="100vw"
        height="100vh"
        style={{ filter: 'blur(70px)' }}
        zIndex={-1}
      />
      <Container
        as={SimpleGrid}
        maxW={'7xl'}
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack spacing={{ base: 10, md: 20 }}>
          <Heading
            zIndex={2}
            lineHeight={1.1}
            fontSize={{ base: '1xl', sm: '2xl', md: '3xl', lg: '4xl' }}
            color="white"
          >
            {/* <Text
              as={'span'}
              bgGradient="linear(to-r, pink.900,purple.900)"
              bgClip="text"
            >
              Biome Squad™
            </Text>{' '} */}
            <Text
              as={'span'}
              bgGradient="linear(to-r, pink.900,purple.900)"
              bgClip="text"
              fontSize={{ base: '2xl', sm: '3xl', md: '4xl', lg: '5xl' }}
            >
              For shits sake.
            </Text>
            <br />
            We are on a mission to prove that a healthy gut with the right
            microbes is central to human health.
            {/* <Text
              as={'span'}
              bgGradient="linear(to-r, red.400,pink.400)"
              bgClip="text"
            >
              &
            </Text>{' '} */}
          </Heading>
          <Stack direction={'row'} spacing={4} align={'center'}>
            <AvatarGroup>
              {certifiedMembersData.slice(0, 5).map(avatar => (
                <Avatar
                  key={avatar.name}
                  name={avatar.name}
                  src={avatar.url}
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  size={useBreakpointValue({ base: 'md', md: 'lg' })}
                  position={'relative'}
                  zIndex={2}
                  _before={{
                    content: '""',
                    width: 'full',
                    height: 'full',
                    rounded: 'full',
                    transform: 'scale(1.125)',
                    bgGradient: 'linear(to-bl, red.400,pink.400)',
                    position: 'absolute',
                    zIndex: -1,
                    top: 0,
                    left: 0,
                  }}
                />
              ))}
            </AvatarGroup>
            <Text fontFamily={'heading'} fontSize={{ base: '4xl', md: '6xl' }}>
              +
            </Text>
            <Flex
              align={'center'}
              justify={'center'}
              fontFamily={'heading'}
              fontSize={{ base: 'sm', md: 'lg' }}
              bg={'gray.800'}
              color={'white'}
              rounded={'full'}
              minWidth={useBreakpointValue({ base: '44px', md: '60px' })}
              minHeight={useBreakpointValue({ base: '44px', md: '60px' })}
              position={'relative'}
              _before={{
                content: '""',
                width: 'full',
                height: 'full',
                rounded: 'full',
                transform: 'scale(1.125)',
                bgGradient: 'linear(to-bl, orange.400,yellow.400)',
                position: 'absolute',
                zIndex: -1,
                top: 0,
                left: 0,
              }}
            >
              YOU
            </Flex>
          </Stack>
        </Stack>
        <Stack
          bg={'gray.50'}
          rounded={'xl'}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: 'lg' }}
        >
          <Stack spacing={4}>
            <Heading
              color={'gray.800'}
              lineHeight={1.1}
              display="flex"
              fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
            >
              Become Certified!
            </Heading>
            <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
              Biome Squad<span className="trademark">™</span> a group of
              physicians and scientists who have come together to look a the
              trillions of bacteria that encompass our bodies with a vision to
              see the truth about the microbiome.
            </Text>
          </Stack>
          <Box as={'form'} mt={10}>
            <form onSubmit={() => handleFormSubmit}>
              <FormControl id="profilePic">
                <Stack direction={['column', 'column']} spacing={6}>
                  <Center>
                    <Avatar className="avatar" size="xl" src={avatar}>
                      {profilePic && (
                        <AvatarBadge
                          as={IconButton}
                          size="sm"
                          rounded="full"
                          top="-10px"
                          colorScheme="red"
                          aria-label="remove Image"
                          icon={<SmallCloseIcon />}
                          onClick={() => {
                            setAvatar(null);
                            setProfilePic(null);
                          }}
                        />
                      )}
                    </Avatar>
                  </Center>
                  <Center w="full">
                    <Input
                      type="file"
                      onChange={e => setProfilePic(e.target.files[0])}
                    />
                  </Center>
                </Stack>
              </FormControl>
              <br />
              <Stack spacing={4}>
                <FormControl isRequired>
                  <Input
                    placeholder="Email"
                    bg={'gray.100'}
                    border={0}
                    color={'gray.500'}
                    _placeholder={{
                      color: 'gray.500',
                    }}
                  />
                </FormControl>
                <FormControl isRequired>
                  <Input
                    placeholder="Create Password"
                    bg={'gray.100'}
                    border={0}
                    color={'gray.500'}
                    _placeholder={{
                      color: 'gray.500',
                    }}
                  />
                </FormControl>
                <FormControl isRequired>
                  <Input
                    placeholder="First Name"
                    bg={'gray.100'}
                    border={0}
                    color={'gray.500'}
                    _placeholder={{
                      color: 'gray.500',
                    }}
                  />
                </FormControl>
                <FormControl isRequired>
                  <Input
                    placeholder="Last Name"
                    bg={'gray.100'}
                    border={0}
                    color={'gray.500'}
                    _placeholder={{
                      color: 'gray.500',
                    }}
                  />
                </FormControl>
                <FormControl isRequired>
                  <Input
                    placeholder="Mobile Number (Include country code if outside US)"
                    bg={'gray.100'}
                    border={0}
                    color={'gray.500'}
                    _placeholder={{
                      color: 'gray.500',
                    }}
                  />
                </FormControl>
                <FormControl>
                  <Input
                    placeholder="Specialty  (i.e. GI, neuro, internal medicine, etc.)"
                    bg={'gray.100'}
                    border={0}
                    color={'gray.500'}
                    _placeholder={{
                      color: 'gray.500',
                    }}
                  />
                </FormControl>
                <FormControl>
                  <Input
                    placeholder="Employer ('private practice' is acceptable)"
                    bg={'gray.100'}
                    border={0}
                    color={'gray.500'}
                    _placeholder={{
                      color: 'gray.500',
                    }}
                  />
                </FormControl>
                <FormControl>
                  <Input
                    placeholder="Linkedin Profile URL"
                    bg={'gray.100'}
                    border={0}
                    color={'gray.500'}
                    _placeholder={{
                      color: 'gray.500',
                    }}
                  />
                </FormControl>
              </Stack>
              <Button
                isDisabled={!isEnabled}
                fontFamily={'heading'}
                mt={8}
                w={'full'}
                bgGradient="linear(to-r, red.400,pink.400)"
                color={'white'}
                _hover={{
                  bgGradient: 'linear(to-r, red.400,pink.400)',
                  boxShadow: 'xl',
                }}
              >
                Submit
              </Button>
            </form>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
