import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deleteDocument, getDocument } from '../../api/api';
import LabsAdd from './LabsAdd';
import { getAge } from '../../Helpers/UserHelpers';

function LabsDetail({ currentUser, setCurrentUser }) {
  const toast = useToast();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lab, setLab] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isResending, setIsResending] = useState(false);
  const cancelRef = useRef();

  const copyConsentFormLink = () => {
    let cLink;
    const patientAge = getAge(new Date(lab?.patient?.dob));
    if (patientAge > 0)
      cLink =
        'https://eform.pandadoc.com/?eform=f44ee451-0e82-4227-bc0c-dd1ba3460e88';
    if (patientAge > 6)
      cLink =
        'https://eform.pandadoc.com/?eform=12340aeb-df9e-4019-939f-340ba3cd2456';
    if (patientAge > 11)
      cLink =
        'https://eform.pandadoc.com/?eform=3a0e08de-7522-4ef4-9bad-6e248376fd97';
    if (patientAge > 17)
      cLink =
        'https://eform.pandadoc.com/?eform=da357015-4461-493b-98c2-d38ed144bbb9';
    navigator.clipboard.writeText(cLink);
  };

  const copyGuardianConsentFormLink = () => {
    let cLink =
      'https://eform.pandadoc.com/?eform=da357015-4461-493b-98c2-d38ed144bbb9';
    navigator.clipboard.writeText(cLink);
  };

  const handleDelete = () => {
    deleteDocument(currentUser?.id, 'labs', id, '/labs', navigate);
  };

  const handleResend = () => {
    setIsResending(true);
    setTimeout(() => {
      setIsResending(false);
      toast({
        title: `Email sent to ${lab?.patient?.email}`,
        description: `Disclosure forms were included in the email.`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    }, 3000);
  };

  useEffect(() => {
    getDocument(currentUser?.id, 'labs', id, setLab);
  }, [id]);

  return (
    <div id="labs" className="page-padding labs-detail">
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Lab
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <LabsAdd
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        isOpen={isModalOpen || isResending}
        setIsOpen={setIsModalOpen}
        lab={lab}
        isResending={isResending}
      />
      <Heading
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        fontSize={{
          base: '4xl',
          md: '5xl',
        }}
      >
        Lab
        <ButtonGroup>
          <Button colorScheme="gray" onClick={() => onOpen()}>
            Delete
          </Button>
          <Button colorScheme="blue" onClick={() => setIsModalOpen(true)}>
            Edit
          </Button>
          <Button
            colorScheme="blue"
            isLoading={isResending}
            loadingText="Sending Forms"
            onClick={() => handleResend()}
          >
            Resend Patient Forms
          </Button>
        </ButtonGroup>
      </Heading>
      <TableContainer>
        <Table variant="simple">
          {lab && (
            <Tbody>
              <Tr>
                <Td>Actions</Td>
                <Td className="id">
                  <Flex gap={2}>
                    <Button
                      colorScheme="gray"
                      size="sm"
                      onClick={() => copyConsentFormLink()}
                    >
                      Copy Consent Link
                    </Button>
                    <Button
                      colorScheme="gray"
                      size="sm"
                      onClick={() => copyGuardianConsentFormLink()}
                    >
                      Copy Guardian Consent Link
                    </Button>
                  </Flex>
                </Td>
              </Tr>
              <Tr>
                <Td>Id</Td>
                <Td className="id">{lab?.id}</Td>
              </Tr>
              <Tr>
                <Td>Status</Td>
                <Td className="status">{lab?.status}</Td>
              </Tr>
              <Tr>
                <Td>Date</Td>
                <Td className="date">
                  {new Date(lab?.date).toLocaleDateString()}
                </Td>
              </Tr>
              <Tr>
                <Td>Flora Number</Td>
                <Td className="date">
                  {lab?.floraNumber ||
                    'Not assigned yet (when lab is ready, it will receive a Flora Number)'}
                </Td>
              </Tr>

              <Tr>
                <Td>Patient Name</Td>
                <Td className="firstName">
                  <Link to={`/patient/${lab?.patientId}`}>
                    <Button
                      colorScheme="blue"
                      variant="ghost"
                      style={{ marginLeft: -15 }}
                    >
                      {lab?.patient?.firstName} {lab?.patient?.lastName}
                    </Button>
                  </Link>
                </Td>
              </Tr>
              <Tr>
                <Td>Date of Birth</Td>
                <Td className="dob">
                  {new Date(lab?.patient?.dob).toLocaleDateString()}
                </Td>
              </Tr>
              <Tr>
                <Td>Age</Td>
                <Td className="dob">{getAge(new Date(lab?.patient?.dob))}</Td>
              </Tr>
              <Tr>
                <Td>Contact Info</Td>
                <Td className="email">
                  <a
                    style={{ marginLeft: -15 }}
                    href={`mailto:${lab?.patient?.email}`}
                  >
                    <Button colorScheme="blue" variant="ghost">
                      {lab?.patient?.email}
                    </Button>
                  </a>
                  <a href={`tel:${lab?.mobileWithCarrier}`}>
                    <Button colorScheme="blue" variant="ghost">
                      {lab?.patient?.mobile}
                    </Button>
                  </a>
                </Td>
              </Tr>
              <Tr>
                <Td>Address</Td>
                <Td className="address">{lab?.patient?.address}</Td>
              </Tr>

              <Tr>
                <Td>Note</Td>
                <Td className="note">
                  <div dangerouslySetInnerHTML={{ __html: lab?.note }} />
                </Td>
              </Tr>
            </Tbody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}

export default LabsDetail;
