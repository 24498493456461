import {
  Container,
  Stack,
  Heading,
  Text,
  Button,
  Avatar,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { BiArrowFromLeft, BiEditAlt } from 'react-icons/bi';
import { useEffect, useState } from 'react';
import { getRootDocument } from '../../api/api';

export default function Profile({ currentUser, setCurrentUser }) {
  const [userProfile, setUserProfile] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const handleGetUserProfile = async () => {
    const newCurrentUser = await getRootDocument(
      currentUser,
      'users',
      id,
      setUserProfile
    );
  };

  useEffect(() => {
    if (id) {
      handleGetUserProfile();
    } else {
      setUserProfile(currentUser);
    }
  }, [id]);

  if (userProfile) {
    return (
      <Container maxW={'7xl'}>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
          direction={{ base: 'column', md: 'row' }}
        >
          <Stack flex={1} spacing={{ base: 5, md: 5 }} align="center">
            <Avatar
              backgroundColor={'gray.400'}
              size="2xl"
              src={userProfile?.photoURL}
            ></Avatar>
            <Heading
              lineHeight={1.1}
              fontWeight={800}
              fontSize={{ base: 'md', sm: 'md', lg: 'lg' }}
            >
              <Text as={'span'} color={'black.400'}>
                {userProfile?.displayName
                  ? userProfile?.displayName
                  : userProfile?.email}
              </Text>
            </Heading>
            <Stack
              spacing={{ base: 4, sm: 6 }}
              mt={1}
              direction={{ base: 'column', sm: 'row' }}
            >
              <Button
                onClick={() => navigate('/profile/edit')}
                size={'lg'}
                px={6}
                colorScheme={'gray'}
                leftIcon={<BiEditAlt h={4} w={4} color={'white.400'} />}
              >
                Edit Profile
              </Button>
              <Button
                size={'lg'}
                px={6}
                colorScheme="red"
                bg={'red.400'}
                _hover={{ bg: 'red.500' }}
                onClick={() => navigate('/logout')}
                leftIcon={<BiArrowFromLeft h={4} w={4} color={'white.400'} />}
              >
                Logout
              </Button>
            </Stack>
            {userProfile.bio ? (
              <div dangerouslySetInnerHTML={{ __html: userProfile?.bio }} />
            ) : (
              <Text color={'gray.400'} align="center">
                You've yet to write your bio...
              </Text>
            )}
          </Stack>
        </Stack>
      </Container>
    );
  }
}
