import React from 'react';
import LargeQuote from '../../Layouts/LargeQuote/LargeQuote';
import Hero from '../../Layouts/Hero/Hero';
import HeroVideo from '../../Layouts/HeroVideo/HeroVideo';
import AsSeenIn from '../../Layouts/AsSeenIn/AsSeenIn';
import HeroImage from '../../Layouts/HeroImage/HeroImage';
import BecomeCertified from '../BecomeCertified/BecomeCertified';
import MembershipTypes from '../../Layouts/MembershipTypes/MembershipTypes';
import LandingHazan from './LandingHazan';
import './Conference.scss';
import {
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { getAuth, signOut } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';

function ConferenceSuccess({ setCurrentUser }) {
  const auth = getAuth();
  const navigate = useNavigate();

  const signOutUser = async () => {
    signOut(auth);
    localStorage.removeItem('user');
    setCurrentUser(null);
    setTimeout(() => {
      navigate('/conference');
      window.location.reload();
    }, 10000);
  };

  signOutUser();

  return (
    <div className="conference">
      <div id="hero">
        <Flex
          w={'full'}
          h={{ base: '70vh', md: '100vh' }}
          //backgroundImage={`url(${backgroundImage})`}
          backgroundSize={'cover'}
          backgroundPosition={'center center'}
        >
          <video
            playsInline
            autoPlay
            muted
            loop
            poster="/landing/GettyImages-1279892500-scaled-e1625856527499.jpg"
          >
            <source
              src="/landing/mixkit-petri-blood-cells-3951-medium.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>

          <VStack
            w={'full'}
            justify={'center'}
            px={useBreakpointValue({ base: 4, md: 8 })}
            bgGradient={'linear(to-b, pink.700, transparent)'}
          >
            <Stack maxW={'4xl'} align={'flex-start'} spacing={6}>
              <Heading fontSize="3.5em" color="white" fontFamily="Contrail One">
                BIOME SQUAD
                <span className="trademark">™</span>
              </Heading>

              <Heading
                lineHeight={1.1}
                display="flex"
                color={'white'}
                fontWeight={800}
                fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
              >
                Successfully Signed Up!
              </Heading>
              <Text
                color={'white'}
                fontWeight={600}
                lineHeight={1.2}
                fontSize={useBreakpointValue({ base: '1xl', md: '2xl' })}
              >
                Thank you and welcome to the squad. You'll recieve an email to
                confirm your membership.
              </Text>
              <Link to="/conference">
                <Button
                  color={'white'}
                  fontWeight={800}
                  size="lg"
                  mt={2}
                  mr={2}
                  colorScheme="blue"
                >
                  Back to Sign Up
                </Button>
              </Link>
            </Stack>
          </VStack>
        </Flex>
      </div>
    </div>
  );
}

export default ConferenceSuccess;
