import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';
import { MdPlayArrow } from 'react-icons/md';

import { Link } from 'react-router-dom';
import { meetingData } from '../../data/meetings';
import { videos } from '../../data/videos';
import VideoPlayer from './VideoPlayer';
import { useState } from 'react';

const VideoCard = ({
  heading,
  author,
  description,
  videoUrl,
  currentUser,
  free,
  autoPlayFalse,
  isPaid,
}) => {
  const [showVideo, setShowVideo] = useState(false);
  if (isPaid && currentUser.membership === 'Premium') {
    return (
      <>
        <Modal
          onClose={() => setShowVideo(false)}
          size="full"
          isOpen={showVideo}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {heading}
              <span style={{ fontSize: '16px', fontWeight: 400 }}>
                {' '}
                {author}
              </span>
              <div style={{ fontSize: '16px', fontWeight: 400 }}>
                {description}
              </div>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <video
                title="Video player"
                frameborder="0"
                allow={
                  'accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                }
                allowfullscreen
                controls
                width="100%"
                height="100%"
              >
                <source src={videoUrl} type="video/mp4" />
              </video>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Box
          maxW={{ base: 'full', md: '275px' }}
          w={'full'}
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          p={5}
          onClick={() => setShowVideo(true)}
          style={{ cursor: 'pointer' }}
        >
          <Stack align={'start'} spacing={2}>
            <div
              style={{
                backgroundColor: '#000',
                color: 'white',
                fontWeight: 700,
                height: 129,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '6px',
              }}
            >
              <Icon as={MdPlayArrow} boxSize="3em" />
            </div>
            <Box mt={2}>
              <Heading size="md">{heading}</Heading>
              <Text mt={1} fontSize={'sm'}>
                {description}
              </Text>
            </Box>
          </Stack>
        </Box>
      </>
    );
  }

  if (isPaid && currentUser.membership !== 'Premium') {
    return (
      <Box
        maxW={{ base: 'full', md: '275px' }}
        w={'full'}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={5}
      >
        <Stack align={'start'} spacing={2}>
          <div
            style={{
              backgroundColor: '#000',
              color: 'white',
              fontWeight: 700,
              height: 129,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '6px',
            }}
          >
            Coming soon to Premium
          </div>
          <Box mt={2}>
            <Heading size="md">{heading}</Heading>
            <Text mt={1} fontSize={'sm'}>
              {description}
            </Text>
          </Box>
        </Stack>
      </Box>
    );
  }

  if (!isPaid) {
    if (currentUser || free)
      return (
        <Box
          maxW={{ base: 'full', md: '275px' }}
          w={'full'}
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          p={5}
        >
          <Stack align={'start'} spacing={2}>
            <iframe
              width="100%"
              height="100%"
              src={videoUrl}
              title="YouTube video player"
              frameborder="0"
              allow={
                autoPlayFalse
                  ? 'accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  : 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              }
              allowfullscreen
            ></iframe>
            <Box mt={2}>
              <Heading size="md">{heading}</Heading>
              <Text mt={1} fontSize={'sm'}>
                {description}
              </Text>
            </Box>
            {/* <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
          Learn more
        </Button> */}
          </Stack>
        </Box>
      );
    if (!currentUser && !free)
      return (
        <Link to="/sign-in">
          <Box
            maxW={{ base: 'full', md: '275px' }}
            w={'full'}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            p={5}
          >
            <Stack align={'start'} spacing={2}>
              <div
                style={{
                  backgroundColor: '#000',
                  color: 'white',
                  fontWeight: 700,
                  height: 129,
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '6px',
                }}
              >
                Sign In To View Video
              </div>
              <Box mt={2}>
                <Heading size="md">{heading}</Heading>
                <Text mt={1} fontSize={'sm'}>
                  {description}
                </Text>
              </Box>
            </Stack>
          </Box>
        </Link>
      );
  }
};

export default function MicrobiomeMeeting({ currentUser }) {
  return (
    <Box p={{ base: 0, md: 12 }}>
      <Stack
        pt={{ base: 12 }}
        spacing={4}
        as={Container}
        maxW={'3xl'}
        textAlign={'center'}
      >
        <Heading fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'bold'}>
          Malibu Microbiome Meetings
        </Heading>
        <Text color={'gray.600'} fontSize={{ base: 'sm', sm: 'lg' }}>
          Physicians, government, and industry share the latest in microbiome
          research, discoveries, and news. Last meeting was March 18, 2023.
        </Text>
        <Link to="/become-certified">
          {currentUser?.membership !== 'Certified' &&
            currentUser?.membership !== 'Premium' && (
              <Button
                color={'white'}
                fontWeight={800}
                size="lg"
                colorScheme="blue"
              >
                Become A Certified Member
              </Button>
            )}
        </Link>
      </Stack>

      <Container maxW={'5xl'} mt={12}>
        <Flex flexWrap="wrap" gridGap={6} justify="center">
          {meetingData.map((d, index) => (
            <VideoCard
              free={index === 0}
              heading={d.heading}
              description={d.description}
              videoUrl={d.videoUrl}
              currentUser={currentUser}
              isPaid={false}
            />
          ))}
        </Flex>
      </Container>
      {currentUser && currentUser?.membership !== 'Premium' && (
        <>
          <Stack
            pt={{ base: 12 }}
            spacing={4}
            as={Container}
            maxW={'3xl'}
            textAlign={'center'}
          >
            <Container maxW={'5xl'} mt={12}>
              <Heading
                fontSize={{ base: '2xl', sm: '4xl' }}
                fontWeight={'bold'}
              >
                Premium Content
              </Heading>
              <Text color={'gray.600'} fontSize={{ base: 'sm', sm: 'lg' }}>
                Upgrade to premium to access these video lectures.
              </Text>
            </Container>
          </Stack>
          <Container maxW={'5xl'} mt={12}>
            <Flex flexWrap="wrap" gridGap={6} justify="center">
              {videos
                .filter(t => t.type === 'paid')
                .map(d => (
                  <VideoCard
                    heading={d.heading}
                    author={d.speaker}
                    description={d.description}
                    videoUrl={d.videoUrl}
                    currentUser={currentUser}
                    autoPlayFalse={true}
                    isPaid={true}
                  />
                ))}
            </Flex>
          </Container>
        </>
      )}
      {currentUser && currentUser?.membership === 'Premium' && (
        <>
          <Stack
            pt={{ base: 12 }}
            spacing={4}
            as={Container}
            maxW={'3xl'}
            textAlign={'center'}
          >
            <Container maxW={'5xl'} mt={12}>
              <Heading
                fontSize={{ base: '2xl', sm: '4xl' }}
                fontWeight={'bold'}
              >
                Premium Content
              </Heading>
              <Text color={'gray.600'} fontSize={{ base: 'sm', sm: 'lg' }}>
                {/* Upgrade to premium to access these video lectures. */}
                Your Premium Memberships gives you access to our video lecture
                series.
              </Text>
            </Container>
          </Stack>
          <Container maxW={'5xl'} mt={12}>
            <Flex flexWrap="wrap" gridGap={6} justify="center">
              {videos
                .filter(t => t.type === 'paid')
                .map(d => (
                  <VideoCard
                    heading={d.heading}
                    author={d.speaker}
                    videoUrl={d.videoUrl}
                    description={d.description}
                    currentUser={currentUser}
                    autoPlayFalse={true}
                    isPaid={true}
                  />
                ))}
            </Flex>
          </Container>
        </>
      )}
    </Box>
  );
}
