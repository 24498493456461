import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Card,
  Container,
  Divider,
  Flex,
  Heading,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Stack,
  StackDivider,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import BecomeCertified from '../BecomeCertified/BecomeCertified';
import { useNavigate } from 'react-router-dom';
import './Onboarding.scss';

const options = [
  { id: 1, desc: '1 lorem ipsum' },
  { id: 2, desc: 'Lorem, ipsum dolor.' },
  { id: 3, desc: 'Monthly Updates' },
];

const Feature = ({ text, icon, iconBg }) => {
  return (
    <Stack textAlign={'center'}>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

const Onboarding = ({ currentUser }) => {
  const navigate = useNavigate();
  return (
    <Stack>
      <Alert status="success" fontWeight={800}>
        <AlertIcon />
        You have successfully created a Biome Squad Account!
      </Alert>

      <div id="onboarding">
        <Heading fontFamily="Contrail One">
          Join the BIOME SQUAD<span className="trademark">™</span> Certified
          Program
        </Heading>
        <Text color={'gray.500'} fontSize={'lg'}>
          The Biome Squad<span className="trademark">™</span> Certified Program
          is a group of physicians and scientists who have come together to look
          at the trillions of bacteria that encompass our bodies with a vision
          to understand the truth about the microbiome.
        </Text>
        <Text
          fontWeight={800}
          textTransform={'uppercase'}
          fontSize=".75em"
          letterSpacing={2}
          opacity={0.5}
          marginTop="1.25em"
        >
          Consider becoming a Certified Member if:
        </Text>
        <Stack
          spacing={4}
          divider={
            <StackDivider
              borderColor={useColorModeValue('gray.100', 'gray.700')}
            />
          }
        >
          <Feature
            //icon={<Icon as={FcAutomotive} color={'yellow.500'} w={5} h={5} />}
            iconBg={useColorModeValue('orange.100', 'orange.900')}
            text={
              'You are a doctor, practictioner of medicine, scientist, or researcher'
            }
          />

          <Feature
            //icon={<Icon as={FcAutomotive} color={'purple.500'} w={5} h={5} />}
            iconBg={useColorModeValue('purple.100', 'purple.900')}
            text={
              'You would like to offer genetic sequencing services to your patients'
            }
          />

          <Feature
            //icon={<Icon as={FcAutomotive} color={'green.500'} w={5} h={5} />}
            iconBg={useColorModeValue('green.100', 'green.900')}
            text={
              'You may be interested in fecal microbiota transplant (FMT) for your patients'
            }
          />
          <Feature
            //icon={<Icon as={FcAutomotive} color={'green.500'} w={5} h={5} />}
            iconBg={useColorModeValue('pink.100', 'pink.900')}
            text={
              'You want to collaborate on studies investigating the gut flora in disease'
            }
          />

          <ButtonGroup justifyContent="space-between" margin={10}>
            <Button
              size={'lg'}
              fontWeight={800}
              px={6}
              colorScheme={'gray'}
              onClick={() => navigate('/meetings')}
            >
              Skip
            </Button>
            <Button
              size={'lg'}
              fontWeight={800}
              px={6}
              colorScheme={'blue'}
              onClick={() => navigate('/become-certified')}
            >
              Get Certified
            </Button>
          </ButtonGroup>
        </Stack>
      </div>
    </Stack>
  );
};

export default Onboarding;
