import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { fireStore } from '../../api/fire';

function PdfDetail() {
  const { id } = useParams();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfTitle, setPdfTitle] = useState(null);

  useEffect(() => {
    const fetchPdfUrl = async () => {
      try {
        const pdfDocRef = doc(fireStore, 'studies', id);
        const pdfDocSnap = await getDoc(pdfDocRef);

        if (pdfDocSnap.exists()) {
          const pdfsData = pdfDocSnap.data().pdfs;
          const pdfData = pdfsData.find(pdf => pdf.id === id);
          if (pdfData) {
            setPdfUrl(pdfData.url);
            setPdfTitle(pdfData.title);
          } else {
            console.log('PDF not found in document!');
            // Handle case where PDF with the given ID is not found in the document
          }
        } else {
          console.log('No such document!');
          // Handle case where the document is not found
        }
      } catch (error) {
        console.error('Error fetching PDF URL:', error);
        // Handle the error (e.g., display an error message)
      }
    };

    fetchPdfUrl();
  }, [id]);

  if (!pdfUrl) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {/* Use the embed tag to display the PDF */}
      <embed
        title={pdfTitle}
        src={pdfUrl}
        width="100%"
        height="2100px"
        type="application/pdf"
      />
    </div>
  );
}

export default PdfDetail;
